import Api from "../../libs/Api";

class Subscription {
  getSubscriptions = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/subscriptions", {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => reject(errors));
    });
  };

  makeFirstPayment = (subscription_id, company_id) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/companies/" + company_id + "/subscriptions/first-payment", {subscription_id: subscription_id})
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {

            const pattern = /The resource with the token "(\w+)" could not be found/;
            const match = errors.response.data.message.match(pattern);
            if (match) {
              alert("There seems to be an issue with your account. Please contact us for assistance.");
            }

            reject(errors)
          }
        );
    });
  };

  getFirstPayment = (company_id) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/companies/" + company_id + "/subscriptions/first-payment")
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => reject(errors));
    });
  };

  checkPaymentStatus = (company_id, payment_id) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/companies/" + company_id + "/subscriptions/payment-status/" + payment_id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => reject(errors));
    });
  };

  getCompanySubscription = (company_id) => {
    return new Promise((resolve, reject) => {
      Api.get("api/companies/" + company_id + "/subscription")
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error);
      });
    });
  }

  getCompanyActiveSubscription = () => {
    return new Promise((resolve, reject) => {
      Api.get("api/subscriptions/active-subscriptions")
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        reject(error);
      });
    });
  }


  createSubscription = (paymentId) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/subscriptions", {id: paymentId})
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        reject(error);
      });
    });
  }


  updateCompanySubscription = (subscriptionTypeId) => {
    return new Promise((resolve, reject) => {
      Api.put("/api/subscriptions", {subscriptionTypeId: subscriptionTypeId.id})
        .then(response => {
          resolve(response.data);
          window.location.reload();
        }).catch(error => {
        reject(error);
      });
    });
  }
  companySubscriptionPayments = (company_id) => {
    return new Promise((resolve, reject) => {
      Api.get("api/companies/" + company_id + "/subscription/payments")
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error);
      });
    });
  }
};

const subscription = new Subscription();

export default subscription;