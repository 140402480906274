import {faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Container, Form, InputGroup, Row,} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import BgImage from "../../assets/img/illustrations/signin.svg";
import Auth from "../libs/Auth";

function ResetPassword() {
  document.body.classList.add("bg-gradient-primary");
  const {token} = useParams();
  const {t} = useTranslation();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === passwordConfirm) {
      Auth.reset(email, password, passwordConfirm, token)
        .then((response) => {
          setMessage(response.message);
          if (response.success) {
            localStorage.removeItem("reset_email");
            setTimeout(() => {
              window.location.href = '/';
            }, 3500);
          }
        })
        .catch((errors) => {
          if (errors?.response?.data?.errors) {
            for (const [key, value] of Object.entries(
              errors.response.data.errors
            )) {
              setMessage(`${t(key)}: ${t(value)}`);
            }
          } else {
            console.log("errors", errors);
          }
        });
    } else {
      setMessage(t("Passwords don't match"));
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {message && (
            <div className="row">
              <p className="col-md-6 offset-md-3 bg-white p-2 text-center rounded-2">
                {message}
              </p>
            </div>
          )}
          <Row
            className="justify-content-center form-bg-image"
            style={{backgroundImage: `url(${BgImage})`}}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">{t("Reset Password")}</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>{t("Enter new password")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        type="password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>{t("Repeat new password")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        required
                        type="password"
                      />
                    </InputGroup>
                  </Form.Group>
                  {message === "Your password has been reset!" ? (
                    <Link to="/">
                      <button className="btn btn-primary btn-user btn-block p-1 py-2 next-button">
                        {t("Go to login page")}
                      </button>
                    </Link>
                  ) : (
                    <Button variant="primary" type="submit" className="w-100">
                      {t("Reset Password")}
                    </Button>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default ResetPassword;
