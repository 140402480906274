import {
  GET_CONTRACT_BY_ID_FAILED,
  GET_CONTRACT_BY_ID_START,
  GET_CONTRACT_BY_ID_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  contract: {},
  success: false,
  isLoading: false,
  error: "",
};

const contracts = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT_BY_ID_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_CONTRACT_BY_ID_SUCCESS:
      return {
        ...initialState,
        contract: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_CONTRACT_BY_ID_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default contracts;
