import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, Formik} from "formik";
import React, {useState} from "react";
import {Breadcrumb, Button, Card, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {resellerCompanyProfileSchema, resellerUserProfileSchema,} from "../validation";
import {selectActiveCompany, selectUser} from "../store/reducers/userCompany";

export default function ResellerProfile() {
  const {t} = useTranslation();
  const resellerData = useSelector(selectUser) || {};
  const resellerCompanyData = useSelector(selectActiveCompany) || {};

  const [firstName, setFirstName] = useState(resellerData.first_name);
  const [lastName, setLastName] = useState(resellerData.last_name);
  const [prefix, setPrefix] = useState(resellerData.prefix);
  const [email, setEmail] = useState(resellerData.email);
  const [companyName, setCompanyName] = useState(resellerCompanyData.name);
  const [companyStreet, setCompanyStreet] = useState(
    resellerCompanyData.street
  );
  const [companyHouseNumber, setCompanyHouseNumber] = useState(
    resellerCompanyData.house_number
  );
  const [companyHouseNumberSuffix, setCompanyHouseNumberSuffix] = useState(
    resellerCompanyData.house_number_suffix
  );
  const [city, setCity] = useState(resellerCompanyData.city);
  const [companyPostcode, setCompanyPostcode] = useState(
    resellerCompanyData.postcode
  );
  const [companyEmail, setCompanyEmail] = useState(resellerCompanyData.email);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(
    resellerCompanyData.phone_number
  );
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState(
    resellerCompanyData.website_url
  );

  return (
    <div className="overflow-hidden">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 ">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Profile")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Profile")}</h4>
          <p className="mb-0">{t("Details of your profile")}</p>
        </div>
      </div>
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          email: email,
        }}
        onSubmit={""}
        validationSchema={resellerUserProfileSchema}
      >
        {({setFieldValue, errors, touched, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Card>
              <Card.Header className="p-3">
                <h5>{t("User details")}</h5>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <Form.Group className="mb-3 col-lg-6">
                    <Form.Label>{t("First Name")}</Form.Label>
                    <Field
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFieldValue("firstName", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.firstName && touched.firstName ? (
                      <span className="error-message">
                        {t(errors.firstName)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6">
                    <Form.Label>{t("Prefix")}</Form.Label>
                    <Form.Control
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="mb-3 col-lg-6">
                    <Form.Label>{t("last name")}</Form.Label>
                    <Field
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setFieldValue("lastName", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.lastName && touched.lastName ? (
                      <span className="error-message">
                        {t(errors.lastName)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3 col-lg-6">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Field
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setFieldValue("email", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.email && touched.email ? (
                      <span className="error-message">{t(errors.email)}</span>
                    ) : null}
                  </Form.Group>
                </div>
              </Card.Body>
              <Card.Footer>
                <Button variant="outline-primary">
                  {t("Save User Details")}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{
          companyName: companyName,
          companyStreet: companyStreet,
          companyHouseNumber: companyHouseNumber,
          city: city,
          companyEmail: companyEmail
        }}
        onSubmit={""}
        validationSchema={resellerCompanyProfileSchema}
      >
        {({setFieldValue, errors, touched, handleSubmit, isSubmitting}) => (
          <Form onSubmit={handleSubmit}>
            <Card className="my-5">
              <Card.Header className="p-3">
                <h5>{t("Company details")}</h5>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <Form.Group className="mb-3 col-md-6">
                    <Form.Label>{t("Company Name")}</Form.Label>
                    <Field
                      name="companyName"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setFieldValue("companyName", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.companyName && touched.companyName ? (
                      <span className="error-message">
                        {t(errors.companyName)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label> {t("COC number")}</Form.Label>
                    <Form.Control
                      value={resellerCompanyData.coc_number}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label> {t("VAT Number")}</Form.Label>
                    <Form.Control
                      value={resellerCompanyData.vat_number}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("Street")}</Form.Label>
                    <Field
                      name="companyStreet"
                      value={companyStreet}
                      onChange={(e) => {
                        setCompanyStreet(e.target.value);
                        setFieldValue("companyStreet", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.companyStreet && touched.companyStreet ? (
                      <span className="error-message">
                        {t(errors.companyStreet)}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("House Number")}</Form.Label>
                    <Field
                      name="companyHouseNumber"
                      value={companyHouseNumber}
                      onChange={(e) => {
                        setCompanyHouseNumber(e.target.value);
                        setFieldValue("companyHouseNumber", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.companyHouseNumber && touched.companyHouseNumber ? (
                      <span className="error-message">
                        {t(errors.companyHouseNumber)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("House number Suffix")}</Form.Label>
                    <Form.Control
                      value={companyHouseNumberSuffix}
                      onChange={(e) =>
                        setCompanyHouseNumberSuffix(e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("City")}</Form.Label>
                    <Field
                      name="city"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setFieldValue("city", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.city && touched.city ? (
                      <span className="error-message">{t(errors.city)}</span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("Country")}</Form.Label>
                    <select
                      className="form-control user-select"
                      value={resellerCompanyData.country}
                    >
                      <option value={t("Netherlands")}>
                        {t("Netherlands")}
                      </option>
                    </select>
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      value={companyPostcode}
                      onChange={(e) => setCompanyPostcode(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Field
                      name="companyEmail"
                      value={companyEmail}
                      onChange={(e) => {
                        setCompanyEmail(e.target.value);
                        setFieldValue("companyEmail", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.companyEmail && touched.companyEmail ? (
                      <span className="error-message">
                        {t(errors.companyEmail)}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("Phone Number")}</Form.Label>
                    <Form.Control
                      value={companyPhoneNumber}
                      onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label>{t("Website")}</Form.Label>
                    <Form.Control
                      value={companyWebsiteUrl}
                      onChange={(e) => setCompanyWebsiteUrl(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Card.Body>

              <Card.Footer className="p-3">
                <Form.Group>
                  <Button variant="outline-primary">
                    {t("Save Company Details")}
                  </Button>
                </Form.Group>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}
