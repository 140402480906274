import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, Formik} from "formik";
import React, {useState} from "react";
import {Breadcrumb, Button, Card, Form, InputGroup, Row,} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import AlertDismissible from "../../components/old/AlertDismissible";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {updateUserSchema, userSchema} from "../../validation";
import ResellerAction from "../libs/ResellerAction";

export default function StoreUser() {
  const {t} = useTranslation();
  const {id} = useParams();
  const user = useSelector((state) => state.reseller?.reseller?.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [serverError, setServerError] = useState("");
  const [message, setMessage] = useState({});

  const storeResellerUser = (values) => {
    // console.log('Hello')
    if (id) {
      try {
        dispatch(startLoading());
        ResellerAction.updateUser(id, values)
          .then((res) => {
            console.log(res);
            if (res.status) {
              dispatch(stopLoading());

              setMessage({
                show: true,
                message: "User updated successfully",
                variant: "success",
              });
            } else if (!res.status === 200) {
              dispatch(stopLoading()); // hide loading
              setMessage({
                show: true,
                message: "Something went wrong. Try again later",
                variant: "warning",
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoading());
            console.log(error);
          });
      } catch (error) {
        dispatch(stopLoading());
        console.log(error);
      }
    } else {
      dispatch(startLoading());
      ResellerAction.storeUser(values)
        .then((res) => {
          dispatch(stopLoading());
          localStorage.setItem("userId", res.user.id);
          history.push("/reseller/create-company");
        })
        .catch((error) => {
          dispatch(stopLoading());
          setServerError(error);
        });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/reseller/user">
              {t("Users")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {id ? t("Update user") : t("Create user")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <h4 className="my-1 text-center">
        {id ? t("Update user") : t("Create new user")}
      </h4>
      {message.show ? (
        <AlertDismissible
          textMessage={message.message}
          variant={message.variant}
        />
      ) : (
        ""
      )}
      <Card className="pt-4 col-6 d-flex flex-column align-items-center m-auto mb-5">
        <Formik
          enableReinitialize
          initialValues={{
            email: id ? user.email : "",
            first_name: id ? user.first_name : "",
            last_name: id ? user.last_name : "",
            prefix: "",
            password: "",
            password_confirmation: "",
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              setSubmitting(false);
              storeResellerUser(values);
            }, 1000);
          }}
          validationSchema={id ? updateUserSchema : userSchema}
        >
          {({
              setFieldValue,
              errors,
              touched,
              handleSubmit,
              values,
              isSubmitting,
            }) => (
            <Form className="mt-4 px-2 col-10" onSubmit={handleSubmit}>
              <Row>
                <Form.Group id="first_Name" className="mb-4 col-6">
                  <Form.Label>{t("First Name")}</Form.Label>
                  <InputGroup>
                    <Field
                      name="first_name"
                      value={values.first_name}
                      onChange={(e) =>
                        setFieldValue("first_name", e.target.value)
                      }
                      type="text"
                      className="form-control"
                    />
                  </InputGroup>
                  {errors.first_name && touched.first_name ? (
                    <span className="error-message">
                      {t(errors.first_name)}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group id="prefix" className="mb-4 col-6">
                  <Form.Label>{t("Prefix")}</Form.Label>
                  <InputGroup>
                    <Field
                      name="prefix"
                      value={values.prefix}
                      onChange={(e) => setFieldValue("prefix", e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group id="last_Name" className="mb-4 col-6">
                  <Form.Label>{t("Last Name")}</Form.Label>
                  <InputGroup>
                    <Field
                      className="form-control"
                      name="last_name"
                      value={values.last_name}
                      onChange={(e) =>
                        setFieldValue("last_name", e.target.value)
                      }
                      type="text"
                    />
                  </InputGroup>
                  {errors.last_name && touched.last_name ? (
                    <span className="error-message">{t(errors.last_name)}</span>
                  ) : null}
                </Form.Group>

                <Form.Group id="email" className="mb-4 col-6">
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <Field
                      value={values.email}
                      name="email"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      type="email"
                      placeholder="example@user.com"
                      className="form-control"
                    />
                  </InputGroup>
                  {errors.email && touched.email ? (
                    <span className="error-message">{errors.email[0]}</span>
                  ) : null}
                  {serverError && serverError.email && touched.email ? (
                    <span className="error-message">
                      {serverError.email[0]}
                    </span>
                  ) : null}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-4 col-6">
                  <Form.Label>{t("Password")}</Form.Label>
                  <InputGroup>
                    <Field
                      name="password"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                      type="password"
                      className="form-control"
                    />
                  </InputGroup>
                  {errors.password && touched.password ? (
                    <span className="error-message">{t(errors.password)}</span>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-4 col-6">
                  <Form.Label>{t("Confirm Password")}</Form.Label>
                  <InputGroup>
                    <Field
                      name="password_confirmation"
                      value={values.password_confirmation}
                      onChange={(e) =>
                        setFieldValue("password_confirmation", e.target.value)
                      }
                      type="password"
                      className="form-control"
                    />
                  </InputGroup>
                  {errors.password_confirmation &&
                  touched.password_confirmation ? (
                    <span className="error-message">
                      {t(errors.password_confirmation)}
                    </span>
                  ) : null}
                </Form.Group>
              </Row>
              <div className="form-group justify-content-between row mb-sm-3 mx-0">
                <Button
                  variant="primary"
                  className=" col-12 mt-2 mb-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {id ? t("Update user") : t("Next")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
}
