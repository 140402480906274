import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card, Form} from "@themesberg/react-bootstrap";
import Auth from "../../auth/libs/Auth";
import Company from "../../companies/libs/Company";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import {Formik} from "formik";
import {companyProfileSchema, userProfileSchema} from "../../validation";
import CustomSpinner from "../../components/CustomSpinner";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/reducers/userCompany";

function Profile() {
  const {t} = useTranslation();
  const [companyCountry, setCompanyCountry] = useState("");
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [showUserMessage, setShowUserMessage] = useState(false);
  const [showCompanyMessage, setShowCompanyMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const data = useSelector(selectUser);

  useEffect(() => {
    if (Object.keys(data).length > 0) setLoading(false)
  }, [data]);

  const updateUserData = (values) => {
    Auth.updateUserData(values).then((res) => {
      setShowUserMessage(true);
      if (res.success === true) {
        setMessage({show: true, message: res.message, variant: "success"});
      } else if (res.success === false) {
        setMessage({show: true, message: res.message, variant: "warning"});
      }

      if (res.errors) {
        let array = [];
        for (const key in res.errors) {
          array.push({
            show: true,
            message: res.errors[key][0],
            variant: "danger",
          });
        }
        setErrors(array);
      }
    });
  };

  const updateCompanyData = (values) => {
    Company.updateCompanyData(values).then((res) => {
      setShowCompanyMessage(true);
      if (res.success === true) {
        setMessage({show: true, message: res.message, variant: "success"});
      } else if (res.success === false) {
        setMessage({show: true, message: res.message, variant: "warning"});
      }

      if (res.errors) {
        let array = [];
        for (const key in res.errors) {
          array.push({
            show: true,
            message: res.errors[key][0],
            variant: "danger",
          });
        }
        setErrors(array);
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 h-80 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Profile")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Profile")}</h4>
          <p className="mb-0">{t("Details of your profile")}</p>
        </div>
      </div>
      {loading ? (
        <CustomSpinner/>
      ) : (
        <>
          <Card>
            {showUserMessage && message.show ? (
              <AlertDismissible
                textMessage={message.message}
                variant={message.variant}
              />
            ) : (
              ""
            )}
            {showUserMessage &&
              errors &&
              errors.map((message, index) => {
                return (
                  <AlertDismissible
                    key={index}
                    textMessage={message.message}
                    variant="danger"
                  />
                );
              })}
            <Card.Header className="p-3">
              <h5>{t("User details")}</h5>
            </Card.Header>
            <Formik
              enableReinitialize
              initialValues={{
                id: data?.user?.id,
                first_name: data?.user?.first_name,
                last_name: data?.user?.last_name,
                prefix: data?.user?.prefix,
                email: data?.user?.email,
              }}
              validationSchema={userProfileSchema}
              onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                  setSubmitting(false);
                  updateUserData(values);
                }, 1000);
              }}
            >
              {({setFieldValue, errors, touched, handleSubmit, isSubmitting, values}) => (
                <Form onSubmit={handleSubmit}>
                  <Card.Body>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-4 h-80 h-80">
                          <Form.Label>{t("First Name")}</Form.Label>s
                          <Form.Control
                            name="first_name"
                            value={values.first_name}
                            onChange={(e) => setFieldValue("first_name", e.target.value)}
                          />
                          {errors.first_name && touched.first_name ? (
                            <span className="error-message">
                              {t(errors.first_name)}
                            </span>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-4 h-80">
                          <Form.Label>{t("Prefix")}</Form.Label>
                          <Form.Control
                            name="prefix"
                            value={values.prefix}
                            onChange={(e) => setFieldValue("prefix", e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-4 h-80">
                          <Form.Label>{t("Last Name")}</Form.Label>
                          <Form.Control
                            name="last_name"
                            value={values.last_name}
                            onChange={(e) => setFieldValue("last_name", e.target.value)}
                          />
                          {errors.last_name && touched.last_name ? (
                            <span className="error-message">
                              {t(errors.last_name)}
                            </span>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-4 h-80">
                          <Form.Label>{t("Email")}</Form.Label>
                          <Form.Control
                            name="email"
                            value={values.email}
                            onChange={(e) => setFieldValue("email", e.target.value)}
                          />
                          {errors.email && touched.email ? (
                            <span className="error-message">
                              {t(errors.email)}
                            </span>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer className="p-3">
                    <Form.Group>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t("Save User Details")}
                      </Button>
                    </Form.Group>
                  </Card.Footer>
                </Form>
              )}
            </Formik>
          </Card>

          <Card className="my-5">
            {showCompanyMessage && message.show ? (
              <AlertDismissible
                type="message"
                textMessage={message.message}
                variant={message.variant}
              />
            ) : (
              ""
            )}
            {showCompanyMessage &&
              errors &&
              errors.map((message, index) => {
                return (
                  <AlertDismissible
                    key={index}
                    textMessage={message.message}
                    variant="danger"
                  />
                );
              })}
            <Card.Header className="p-3">
              <h5>{t("Company details")}</h5>
            </Card.Header>

            <Formik
              enableReinitialize
              initialValues={{
                id: data?.company?.id,
                name: data?.company?.name,
                coc_number: data?.company?.coc_number,
                vat_number: data?.company?.vat_number,
                email: data?.company?.email,
                street: data?.company?.street,
                house_number: data?.company?.house_number,
                house_number_suffix: data?.company?.house_number_suffix,
                postcode: data?.company?.postcode,
                city: data?.company?.city,
                country: data?.company?.country,
                phone_number: data?.company?.phone_number,
                website_url: data?.company?.website_url,
              }}
              validationSchema={companyProfileSchema}
              onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                  setSubmitting(false);
                  updateCompanyData(values);
                }, 1000);
              }}
            >
              {({setFieldValue, errors, touched, handleSubmit, isSubmitting, values}) => (
                <Form onSubmit={handleSubmit}>
                  <Card.Body>
                    <div className="row">
                      <Form.Group className="col-md-6 mb-4 h-80">
                        <Form.Label>{t("Company Name")}</Form.Label>
                        <Form.Control
                          name="name"
                          value={values.name}
                          onChange={(e) => setFieldValue("name", e.target.value)}
                        />
                        {errors.name && touched.name ? (
                          <span className="error-message">
                            {t(errors.name)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("coc number")}</Form.Label>
                        <Form.Control value={values.coc_number} readOnly/>
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-3">
                        <Form.Label> {t("VAT Number")}</Form.Label>
                        <Form.Control value={values.vat_number} readOnly/>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 mb-4 h-80">
                        <Form.Label>{t("Street")}</Form.Label>
                        <Form.Control
                          name="street"
                          value={values.street}
                          onChange={(e) => setFieldValue("street", e.target.value)}
                        />
                        {errors.street && touched.street ? (
                          <span className="error-message">
                            {t(errors.street)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("House Number")}</Form.Label>
                        <Form.Control
                          name="house_number"
                          value={values.house_number}
                          onChange={(e) => setFieldValue("house_number", e.target.value)}
                        />
                        {errors.house_number && touched.house_number ? (
                          <span className="error-message">
                            {t(errors.house_number)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("House number Suffix")}</Form.Label>
                        <Form.Control
                          name="house_number_suffix"
                          value={values.house_number_suffix}
                          onChange={(e) => setFieldValue("house_number_suffix", e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 mb-4 h-80">
                        <Form.Label>{t("City")}</Form.Label>
                        <Form.Control
                          name="city"
                          value={values.city}
                          onChange={(e) => setFieldValue("city", e.target.value)}
                        />
                        {errors.city && touched.city ? (
                          <span className="error-message">
                            {t(errors.city)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("Country")}</Form.Label>
                        <select
                          className="form-control user-select"
                          onChange={(e) => setCompanyCountry(e.target.value)}
                          value={companyCountry || ""}
                          required
                        >
                          <option value={t("Netherlands")}>
                            {t("Netherlands")}
                          </option>
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                          name="postcode"
                          value={values.postcode}
                          onChange={(e) => setFieldValue("postcode", e.target.value)}
                        />
                        {errors.postcode && touched.postcode ? (
                          <span className="error-message">
                            {t(errors.postcode)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 mb-4 h-80">
                        <Form.Label>{t("Email")}</Form.Label>
                        <Form.Control
                          name="email"
                          value={values.email}
                          onChange={(e) => setFieldValue("email", e.target.value)}
                        />
                        {errors.email && touched.email ? (
                          <span className="error-message">
                            {t(errors.email)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("Phone number")}</Form.Label>
                        <Form.Control
                          name="phone_number"
                          value={values.phone_number}
                          onChange={(e) => setFieldValue("phone_number", e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="col-md-3 mb-4 h-80">
                        <Form.Label>{t("Website")}</Form.Label>
                        <Form.Control
                          name="website_url"
                          value={values.website_url}
                          onChange={(e) => setFieldValue("website_url", e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Card.Body>
                  <Card.Footer className="p-3">
                    <Form.Group>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t("Save Company Details")}
                      </Button>
                    </Form.Group>
                  </Card.Footer>
                </Form>
              )}
            </Formik>
          </Card>
        </>
      )}
    </>
  );
}

export default Profile;
