import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import Template from "./TemplateService";
import {config} from "../../../libs/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

function ViewTemplate({
                        show,
                        handleClose,
                        TemplateId,
                      }) {
  const {t} = useTranslation();

  const [MarginTop, setMarginTop] = useState(null);
  const [MarginBottom, setMarginBottom] = useState(null);
  const [Name, setName] = useState("");
  const [BackgroundImage, setBackgroundImage] = useState("");
  const [showLogo, setshowLogo] = useState(true);
  const [CloseText, setCloseText] = useState("");
  const [PrimaryColor, setPrimaryColor] = useState("");
  const [SecondaryColor, setSecondaryColor] = useState("");
  const [isDefault, setIsDefault] = useState("");

  useEffect(() => {
    if (TemplateId) {
      Template.getTemplate(TemplateId).then((data) => {
        if (data && data.template) {
          setName(data.template.name);
          setMarginTop(data.template.margin_top);
          setMarginBottom(data.template.margin_bottom);
          setshowLogo(data.template.show_logo === 1 ? true : false);
          setPrimaryColor(data.template.theme_color_primary);
          setIsDefault(data.template.is_default === 1 ? true : false);
          setSecondaryColor(data.template.theme_color_secondary);
          setCloseText(data.template.closing_text);
          setBackgroundImage(data.template.background_image);
        }
      });
    } else {
      ClearForm();
    }
  }, [TemplateId]);

  const ClearForm = () => {
    setName("");
    setMarginTop(null);
    setMarginBottom(null);
    setBackgroundImage("");
    setIsDefault("");
    setshowLogo("");
    setCloseText("");
    setPrimaryColor("");
    setSecondaryColor("");
  };


  return (
    <div>
      <Modal show={show} onHide={() => handleClose()} size="lg">
        <Form>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">{t("View Template")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              {BackgroundImage && (
                <div className="col-md-6">
                  <img src={`${config.factuursnel_url}${BackgroundImage}`} alt=""/>
                </div>
              )}
              <div className={BackgroundImage ? "col-md-6" : "col-md-12"}>
                <h3 className="text-center">{Name}</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-center">{t("Margin Top")}</div>
                    <div className="d-flex justify-content-center">
                      <Form.Control
                        type="number"
                        className="w-50 text-center"
                        value={MarginTop}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center">{t("Margin Bottom")}</div>
                    <div className="d-flex justify-content-center">
                      <Form.Control
                        type="number"
                        className="w-50 text-center"
                        value={MarginBottom}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="text-center">{t("Primary Color")}</div>
                    <div className="d-flex justify-content-center">
                      <div
                        className="w-50 h-50"
                        style={{background: PrimaryColor}}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="text-center">{t("Secondary Color")}</div>
                    <div className="d-flex justify-content-center">
                      <div
                        className="w-50 h-50"
                        style={{background: SecondaryColor}}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="text-center">{t("Show Logo")}</div>
                    <div className="d-flex justify-content-center">
                      <FontAwesomeIcon icon={showLogo ? faTimesCircle : faCheckCircle}/>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="text-center">{t("Default Template")}</div>
                    <div className="d-flex justify-content-center">
                      <FontAwesomeIcon icon={isDefault ? faTimesCircle : faCheckCircle}/>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="text-center">{t("Close Text")}</div>
                    <div className="d-flex justify-content-center">
                      {CloseText}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleClose()}>
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default ViewTemplate;
