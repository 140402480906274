import {Alert} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";
import {messageDismissed} from "../store/reducers/message";

const Message = ({index, message, variant}) => {
  const dispatch = useDispatch();

  const hide = () => {
    dispatch(messageDismissed(index));
  }

  return <Alert variant={variant} onClose={hide} dismissible>
    <p className="m-0">{message}</p>
  </Alert>
}

export default Message;