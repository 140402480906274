import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  activeUser: null,
  activeCompany: null,
  companies: [],
  user: null,
  success: false,
  isLoading: false,
  error: "",
};

export const userCompany = createSlice({
  name: 'userCompany',
  initialState,
  reducers: {
    activeUserFetched: (state, action) => {
      state.activeUser = action.payload
    },
    activeCompanyFetched: (state, action) => {
      state.activeCompany = action.payload
    },
    userFetchingStarted: (state) => {
      state.isLoading = true;
    },
    userFetched: (state, action) => {
      const {companies} = action.payload

      state.isLoading = false;
      state.success = true;
      state.user = action.payload;
      state.companies = companies;

      if (!state.activeCompany || companies.filter(company => company.id === state.activeCompany?.id)?.length === 0) {
        state.activeCompany = companies?.[0] || null;
      }
    },
    userFetchingFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  },
})

export const selectUser = (state) => state.userCompany.user
export const selectUserId = (state) => state.userCompany.user?.id
export const selectUserMandate = (state) => state.userCompany.user?.mandate
export const selectResellerCompanyName = (state) => state.userCompany.user?.reseller?.companies[0]?.name
export const selectResellerCompanyLogo = (state) => state.userCompany.user?.reseller?.companies[0]?.company_setting?.logo_url
export const selectUserType = (state) => state.userCompany.user?.userType
export const selectUserCompanies = state => state.userCompany.user?.companies || {}
export const selectUserSubscription = (state) => state.userCompany.user?.subscription
export const selectActiveCompany = (state) => state.userCompany.activeCompany
export const selectActiveCompanyName = (state) => state.userCompany?.activeCompany?.name
export const selectActiveCompanyId = (state) => state.userCompany?.activeCompany?.id

export const {
  activeUserFetched,
  activeCompanyFetched,
  userFetched,
  userFetchingFailed,
  userFetchingStarted
} = userCompany.actions

export default userCompany.reducer