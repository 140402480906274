import Api from "../../libs/Api";
import Auth from "../../auth/libs/Auth";

class Company {

  updateCompanyData = (companyData) => {
    return new Promise((resolve, reject) => {
      Api.put("/api/companies/" + companyData.id, companyData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(() => reject(null));
    });
  }

  getCompanyProduct = (productId) => {
    let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.get("/api/company/" + companyId + "/products/" + productId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  createCompanyProduct = (productData) => {
    let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.post("/api/company/" + companyId + "/products", productData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  updateCompanyProduct = (productData) => {
    let companyId = Auth.getActiveCompanyId();
    let productId = productData.id;

    return new Promise((resolve, reject) => {
      Api.put(
        "/api/company/" + companyId + "/products/" + productId,
        productData
      )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteCompanyProduct = (productId) => {
    let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.delete("/api/company/" + companyId + "/products/" + productId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  // customer routes

  getCompanyCustomers = () => {
    //let companyId = Auth.getActiveCompanyId();
    return new Promise((resolve, reject) => {
      Api.get("/api/customers/all")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getCompanyPaginatedCustomers = (page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("api/customers", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  createCompanyCustomer = (customerData) => {
    //let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.post("/api/customers", customerData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  updateCompanyCustomer = (customerData) => {
    //let companyId = Auth.getActiveCompanyId();
    let customerId = customerData.id;

    return new Promise((resolve, reject) => {
      Api.put("/api/customers/" + customerId, customerData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getCompanyCustomer = (customerId) => {

    return new Promise((resolve, reject) => {
      Api.get("/api/customers/" + customerId)
        .then((response) => {
          if (response.data) {
            console.log(response.data, "response.data");
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteCompanyCustomer = (customerId) => {
    //let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.delete("/api/customers/" + customerId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  // Setting administration routes

  createCompanyYukiSetting = (configData) => {
    let companyId = Auth.getActiveCompanyId();


    return new Promise((resolve, reject) => {
      Api.post("/api/company/" + companyId + "/yukiConfig", configData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getCompanyYukiSetting = () => {
    let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.get("/api/company/" + companyId + "/yukiConfig")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getCompanySetting = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/company-settings")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  updateCompanySetting = (settingsData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/company-settings", settingsData, {'_method': 'PUT'}, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  // logo upload

  uploadLogo = (logo) => {
    let companyId = Auth.getActiveCompanyId();

    const formData = new FormData();
    formData.append('image', logo);

    return new Promise((resolve, reject) => {
      Api.post("/api/upload-logo/" + companyId, formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  }


  getOfferSetting = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/offer-settings")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  updateOfferSetting = (settingsData) => {
    return new Promise((resolve, reject) => {
      Api.put("/api/offer-settings", settingsData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }


// Validate the VAT number

  validateVATNum = (vatNum) => {

    return new Promise((resolve, reject) => {
      Api.post("api/company-vat", vatNum)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

}

export default new Company();
