import Api from "../../libs/Api";

class DashboardLib {
  requestInvoiceChartData = (request_year) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices-chart-data", {"year": request_year})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }
}

export default new DashboardLib();
