import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumb, Form} from "@themesberg/react-bootstrap";
import {Formik} from "formik";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PriceCard from "../../components/PriceCard";
import StatusCard from "../../components/StatusCard";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {invoiceInfoSchema} from "../../validation";
import Invoice from "../libs/Invoice";
import InvoiceDetails from "./InvoiceDetails";
import InvoiceForm from "./InvoiceForm";
import InvoiceLayout from "./InvoiceLayout";
import InvoiceReminder from "./InvoiceReminder";
import "../loader.scss";
import {messageShown} from "../../store/reducers/message";
import Messages from "../../components/Messages";
import {selectActiveCompany} from "../../store/reducers/userCompany";
import {useBooleanFlagValue} from "@openfeature/react-sdk";
import Preloader from "../../admin/layout/components/Preloader";

function CreateInvoice() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showInvoiceLayout, setShowInvoiceLayout] = useState(false);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [firstReminder, setFirstReminder] = useState(null);
  const [secondReminder, setSecondReminder] = useState(null);
  const [defaultSetting, setDefaultSetting] = useState(true);
  const [disableReminderButton, setDisableReminderButton] = useState(false);
  // const [publishInvoiceChecked, setPublishInvoiceChecked] = useState(true);
  // const [isChecked, setIsChecked] = useState(true);
  // const [template, setTemplate] = useState(null);
  // const [sendInvoiceEmail, setSendInvoiceEmail] = useState("");

  const activeCompany = useSelector(selectActiveCompany);
  const remindersEnabled = useBooleanFlagValue('invoice-reminders', false);

  const handleCloseInvoiceLayout = () => setShowInvoiceLayout(false);
  const handleShowInvoiceLayout = () => setShowInvoiceLayout(true);

  const storeInvoice = ({
                          customer_id,
                          contact_person_id,
                          description,
                          invoice_date,
                          template_id,
                          invoice_lines,
                          email_to,
                          status
                        }) => {
    const newInvoice = {
      customer_id,
      contact_person_id,
      invoice_description: description,
      template_id,
      invoice_date: moment
        .tz(invoice_date, "Europe/Amsterdam")
        .format("YYYY-MM-DD"),
      invoice_lines: invoice_lines.map((invoiceLine) => ({
        product_id: invoiceLine?.product?.id,
        description: invoiceLine?.product?.label,
        ...invoiceLine
      })),
      status,
      first_reminder: defaultSetting ? null : firstReminder,
      second_reminder: defaultSetting ? null : secondReminder,
      company_id: activeCompany?.id,
      email_to,
    };

    dispatch(startLoading()); // show loading
    Invoice.storeInvoice(newInvoice)
      .then((res) => {
        dispatch(stopLoading()); // hide loading
        window.location.href = "/invoices";
      })
      .catch((error) => {
        if (error.message) {
          dispatch(messageShown({message: error.message, variant: "danger"}))
        }
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    if (firstReminder) {
      setDisableReminderButton(false);
    } else {
      setSecondReminder(null);
      setDisableReminderButton(true);
    }
  }, [firstReminder]);

  const handleDefaultSetting = () => {
    if (defaultSetting) {
      setDefaultSetting(false);
    } else {
      setDefaultSetting(true);
    }
  };

  useEffect(() => {
    Invoice.getInvoiceSetting()
      .then((res) => {
        if (res.invoiceSetting) {
          setInvoicePrefix(res.invoiceSetting.invoice_prefix);
          setInvoiceNumber(
            res.invoiceSetting.next_invoice_number.padStart(5, "0")
          );
        }
        if (res.invoiceSetting.first_reminder === 1) {
          setFirstReminder(true);
        }

        if (res.invoiceSetting.second_reminder === 1) {
          setSecondReminder(true);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      {!activeCompany?.id && <Preloader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/invoices">{t("invoices")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Create")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Create new invoice")}</h4>
          <p className="mb-0">{t("Create an invoice for your customer")}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Messages/>
        </div>
      </div>

      <Formik
        // validate={(values) => {onValuesChanged(values)}}
        initialValues={{
          customer_id: "",
          contact_person_id: "",
          invoice_date: new Date(),
          template_id: "",
          description: "",
          email_to: "",
          status: "open",
          send_email: true,
          invoice_lines: [
            {
              product: null,
              quantity: 1,
              price: 0,
              vat_percentage: 0,
              amountExclVat_field: null,
            },
          ],
        }}
        validationSchema={invoiceInfoSchema}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            setSubmitting(false);
            storeInvoice(values);
          }, 1000);
        }}
      >
        {({
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            values,
            isSubmitting
          }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <InvoiceDetails/>
            <InvoiceForm/>

            <PriceCard onClick={() => handleShowInvoiceLayout()}/>

            {remindersEnabled && (
              <InvoiceReminder
                setFirstReminder={setFirstReminder}
                defaultSetting={defaultSetting}
                disableReminderButton={disableReminderButton}
                firstReminder={firstReminder}
                handleDefaultSetting={handleDefaultSetting}
                secondReminder={secondReminder}
                setSecondReminder={setSecondReminder}
              />)}

            <StatusCard
              text={t("Publish invoice")}
            />

            <InvoiceLayout
              show={showInvoiceLayout}
              handleClose={handleCloseInvoiceLayout}
              invoiceData={{
                invoice_date: values.invoice_date,
                invoice_number: invoicePrefix + invoiceNumber,
              }}
              invoiceLines={values.invoice_lines}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateInvoice;
