import Auth from "../../auth/libs/Auth";
import Api from "../../libs/Api";
import {productFetchingFailed, productFetchingStarted, productsFetched} from "../reducers/products";

export const getProducts = () => {
  const companyId = Auth.getActiveCompanyId();

  return async (dispatch) => {
    dispatch(productFetchingStarted());

    try {
      const response = await Api.get(
        "/api/companies/" + companyId + "/products/all"
      );

      dispatch(productsFetched(response.data?.products));
    } catch (e) {
      console.log(e.response);
      dispatch(productFetchingFailed(e?.response?.data?.message));
    }
  };
};
