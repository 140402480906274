import Api from "../../libs/Api";

class Invoice {
  getInvoices = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices/all")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getPaginatedInvoices = (page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("api/invoices", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          //reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getInvoice = (invoiceId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices/" + invoiceId)
        .then((response) => {
          console.log(response.data)
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  downloadInvoice = (invoiceId, filePath) => {
    Api.get(`/api/invoices/${invoiceId}/download`, {}, {responseType: 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        //console.log(filePath.split("/")[1])
        link.setAttribute('download', filePath.split("/")[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  }

  getInvoiceLines = (invoiceId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices/" + invoiceId + "/invoicelines")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getReminders = (invoiceId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices/" + invoiceId + "/reminders")
        .then((response) => {
          console.log(response.data)
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  checkReminderStatus = (invoiceId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoices/" + invoiceId + "/first-reminder")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  sendReminders = (invoiceId, reminderData) => {
    return new Promise((resolve, reject) => {
      Api.post(`/api/invoices/${invoiceId}/send-reminder`, reminderData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(({response}) => reject(response?.data));
    });
  }

  storeInvoice = (invoiceData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/invoices", invoiceData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          reject(response.data);
        })
        .catch(({response}) => reject(response.data));
    });
  }

  updateInvoice = (invoiceData) => {
    const invoiceId = invoiceData.id;
    return new Promise((resolve, reject) => {
      Api.put("/api/invoices/" + invoiceId, invoiceData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getInvoiceSetting = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/invoice-settings")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  updateInvoiceSetting = (settingsData) => {
    return new Promise((resolve, reject) => {
      Api.put("/api/invoice-settings", settingsData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteInvoice = (invoiceId) => {
    return new Promise((resolve, reject) => {
      Api.delete("/api/invoices/" + invoiceId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  }

  // Update Invoice Payment Infomations
  updateInvoicePaymentInfo = (invoiceData) => {
    const invoiceId = invoiceData.id;
    return new Promise((resolve, reject) => {
      Api.put("/api/invoices/" + invoiceId + "/update-payment", invoiceData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  // Search Invoice and customers
  searchInvoiceAndCustomers = (searchInput) => {
    if (!searchInput) {
      return null;
    }
    return new Promise((resolve, reject) => {
      Api.get("/api/search", {searchInput})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  calculateTotalPrices = (invoiceLines) => {
    if (!invoiceLines || invoiceLines?.length === 0) {
      return {
        totalPriceExclVat: 0,
        totalVat: 0,
        totalInclVat: 0,
      };
    }

    return invoiceLines?.reduce(
      (accumulator, currentValue) => {
        const amountExclVat =
          currentValue.amountExclVat_field !== undefined
            ? currentValue.amountExclVat_field || currentValue.finalPrice || 0
            : currentValue.amountExclVat || currentValue.finalPrice || 0;

        const vatPercentage = currentValue.vat_percentage || 0;

        accumulator.totalPriceExclVat += parseFloat(amountExclVat);
        accumulator.totalVat += (parseFloat(amountExclVat) / 100) * vatPercentage;
        accumulator.totalInclVat =
          accumulator.totalPriceExclVat + accumulator.totalVat;

        return accumulator;
      },
      {
        totalPriceExclVat: 0,
        totalVat: 0,
        totalInclVat: 0,
      }
    );
  }
}

export default new Invoice();
