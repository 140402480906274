import React, {useEffect, useState} from "react";
import {Card, Table, Breadcrumb} from "@themesberg/react-bootstrap";
import {useParams} from "react-router-dom";
import moment from "moment-timezone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import StatusBullet from "../../invoices/components/utils/StatusBullet";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getContractById} from "../../store/actions/contracts";
import {numberFormatter} from "../../libs/numberFormatter";

function ContractDetails() {
  const {id} = useParams();
  const {t} = useTranslation();
  const [contractInvoiceLines, setContractInvoiceLines] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [vatPrice, setVatPrice] = useState(0);
  const [totalAmountExclVat, setTotalAmountExclVat] = useState(0);
  const [invoices, setInvoices] = useState([]);

  let history = useHistory();
  const dispatch = useDispatch();
  const contract = useSelector((state) => state.contracts.contract.contract) || {};

  const fetchData = async (contractId) => {
    await dispatch(getContractById(contractId));
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  // This useEffect watches for changes in the contract state
  // and updates the local state when it changes
  useEffect(() => {
    setContractInvoiceLines(contract.contract_invoice_lines);
    setInvoices(contract.invoices);
  }, [contract]);

  useEffect(() => {
    if (contractInvoiceLines) {
      let vatPrice = 0;
      let totalAmount = 0;

      contractInvoiceLines.forEach((invoiceLine) => {
        let amount = invoiceLine.price * invoiceLine.quantity;
        let tempVatPrice = (invoiceLine.vat_percentage / 100) * amount;
        vatPrice += tempVatPrice;
        totalAmount += amount;
      });

      setVatPrice(vatPrice);
      setTotalAmount(totalAmount + vatPrice);
      setTotalAmountExclVat(totalAmount);
    }
  }, [contractInvoiceLines]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/contracts">
              {t("contracts")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Contract details")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Contract details")}</h4>
          <p>{t("Contract details with invoice lines")}</p>
        </div>
      </div>

      <Card style={{width: "100%"}}>
        <Card.Header className="p-2">
          <h5>{t("Contract information")}</h5>
        </Card.Header>
        <Card.Body>
          {contract && (
            <Table bordered hover responsive="sm">
              <thead>
              <tr>
                <th>{t("Customer Name")}</th>
                <td>{contract.customer?.company_name}</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Email</th>
                <td>{contract.email}</td>
              </tr>
              <tr>
                <th>{t("Start Date")}</th>
                <td>
                  {moment
                    .tz(contract.start_date, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>{t("End Date")}</th>
                <td>
                  {contract.end_date
                    ? moment
                      .tz(contract.end_date, "Europe/Amsterdam")
                      .format("DD-MM-YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <th>{t("First Invoice Date")}</th>
                <td>
                  {moment
                    .tz(contract.first_invoice_date, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>{t("Frequency")}</th>
                <td>{contract.frequency}</td>
              </tr>
              <tr>
                <th>{t("status")}</th>
                <td>{contract.status}</td>
              </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-5" style={{width: "100%"}}>
        <Card.Header className="p-2">
          <h5>{t("Contract Invoice Lines")}</h5>
        </Card.Header>
        <Card.Body>
          <Table striped hover responsive size="sm" borderless={true}>
            <thead>
            <tr>
              <th>{t("Description")}</th>
              <th>{t("Quantity")}</th>
              <th>{t("price")}</th>
              <th>{t("VAT Percentage")}</th>
              <th>{t("price excl. VAT")}</th>
            </tr>
            </thead>
            <tbody>
            {contractInvoiceLines &&
              contractInvoiceLines.map((invoiceLine, index) => {
                return (
                  <tr key={index}>
                    <td>{invoiceLine.description}</td>
                    <td>{invoiceLine.quantity}</td>
                    <td>{numberFormatter.format(invoiceLine.price)}</td>
                    <td>{invoiceLine.vat_percentage}%</td>
                    <td>
                      {numberFormatter.format(
                        (invoiceLine.quantity * invoiceLine.price)
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className="row my-2 justify-content-end">
        <Card className="col-md-4 col-12">
          <div className="d-flex py-3">
            <div className="col-6">
              <h6>{t("Total excl. VAT")}</h6>
              <h6>{t("VAT price")}</h6>
              <hr/>
              <h6>
                <strong>{t("Total incl. VAT")}</strong>
              </h6>
            </div>
            <div className="col-6">
              <h6>{numberFormatter.format(totalAmountExclVat)}</h6>
              <h6>
                {vatPrice
                  ? numberFormatter.format(vatPrice)
                  : numberFormatter.format(0)}
              </h6>
              <hr/>
              <h6>
                <strong>
                  {totalAmount
                    ? numberFormatter.format(totalAmount)
                    : numberFormatter.format(0)}
                </strong>
              </h6>
            </div>
          </div>
        </Card>
      </div>

      <Card className="my-5" style={{width: "100%"}}>
        <Card.Header className="p-2">
          <h5>{t("Contract Invoices")}</h5>
        </Card.Header>
        <Card.Body>
          <Table striped hover responsive size="sm" borderless={true}>
            <thead>
            <tr>
              <th>#</th>
              <th>{t("Invoice date")}</th>
              <th>{t("Invoice Number")}</th>
              <th>{t("Customer Name")}</th>
              <th>{t("Customer Address")}</th>
              <th>{t("Status")}</th>
            </tr>
            </thead>
            <tbody>
            {invoices &&
              invoices.map((invoice, index) => {
                console.log('invoice', invoice)
                const suffix = invoice.customer_house_number_suffix
                  ? " " + invoice.customer_house_number_suffix
                  : "";
                const status = invoice.status
                  .split(" ")
                  .map(
                    (w) => w[0].toUpperCase() + w.substring(1).toLowerCase()
                  )
                  .join(" ");
                return (
                  <tr
                    key={index}
                    style={{cursor: "pointer"}}
                    onClick={() =>
                      history.push(`/view-invoice/${invoice.id}`)
                    }
                  >
                    <td>{index + 1}</td>
                    <td>
                      {moment
                        .tz(contract.first_invoice_date, "Europe/Amsterdam")
                        .format("DD-MM-YYYY")}
                    </td>
                    <td>{invoice.invoice_number}</td>
                    <td>{invoice.customer_name}</td>
                    <td>
                      {invoice.customer_street +
                        " " +
                        invoice.customer_house_number +
                        suffix +
                        ", " +
                        invoice.customer_city +
                        " " +
                        invoice.customer_country}
                    </td>
                    <td>
                      <StatusBullet status={status}/>
                      &nbsp;&nbsp;
                      {status === "Published"
                        ? "Open"
                        : status.replace(/_/g, " ")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default ContractDetails;
