import {faTimes, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field} from "formik";
import React, {useEffect, useState} from "react";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {numberFormatter} from "../../libs/numberFormatter";
import {formatCurrency} from "../../components/Currency";
import {useSelector} from "react-redux";
import {selectProducts} from "../../store/reducers/products";
import AutoCompleteProductField from "../../components/inputs/AutoCompleteProductField";

export default function OfferLine({
                                    deleteOfferLine,
                                    rowId,
                                    touched,
                                    errors,
                                    values,
                                    setFieldValue,
                                    showDeleteButton,
                                  }) {
  const {t} = useTranslation();

  const [disableInputs, setDisableInputs] = useState(false);
  const [productSelectorValue, setProductSelectorValue] = useState(null);
  const products = useSelector(selectProducts);

  const handleProductSelect = (selectedOption) => {
    if (!selectedOption) {
      setFieldValue(`offer_line.${rowId}.description`, null);
      setFieldValue(`offer_line.${rowId}.product_id`, null);

      return;
    }

    setFieldValue(`offer_line.${rowId}.description`, selectedOption.label);
    if (!isNaN(selectedOption.value)) {
      setFieldValue(`offer_line.${rowId}.product_id`, selectedOption.value);
    }
  };

  useEffect(() => {
    let intPrice;
    if (values.price) {
      let stringPrice = values.price.toString();
      intPrice = parseInt(stringPrice.replace(/€|,/g, ""));
    }

    if (intPrice) {
      // eslint-disable-next-line
      let totalAmountExclVat = 0;
      totalAmountExclVat = intPrice * values.quantity;
    }

    const selectedProduct = products.filter(
      (product) => product.id === values.product_id
    )?.[0];
    if (selectedProduct) {
      setFieldValue(`offer_line.${rowId}.price`, selectedProduct?.price);
      let totalAmountExclVat = (selectedProduct?.price * values.quantity);
      setFieldValue(`offer_line.${rowId}.amountExclVat`, totalAmountExclVat);
    } else {
      let priceWithoutProductId = (values.price * values.quantity);
      setFieldValue(`offer_line.${rowId}.amountExclVat`, priceWithoutProductId);
    }

    //eslint-disable-next-line
  }, [rowId, values?.price, values?.quantity, values?.product_id]);

  useEffect(() => {
    const selectedProduct = products.filter(
      (product) => product.id === values.product_id
    )?.[0];

    if (!selectedProduct) {
      setDisableInputs(false);

      return;
    }

    let price = selectedProduct.price ? selectedProduct.price / 100 : "";
    let finalPrice = formatCurrency(price && price.toString());

    if (values.quantity === "0") {
      setFieldValue(`offer_line.${rowId}.quantity`, 1);
    }
    setFieldValue(`offer_line.${rowId}.finalPrice`, finalPrice);
    setFieldValue(`offer_line.${rowId}.vat_percentage`, selectedProduct.vat);
    setDisableInputs(true);

    //eslint-disable-next-line
  }, [values?.product_id, values?.quantity]);

  useEffect(() => {
    const selectedProduct = products.filter(
      (product) => product.id === values.product_id
    )?.[0];

    if (!selectedProduct) {
      setProductSelectorValue(
        values.description !== "" ? {label: values.description} : null
      );
      return;
    }

    setProductSelectorValue({
      value: selectedProduct.id,
      label: selectedProduct.name,
    });

    // eslint-disable-next-line
  }, [values?.product_id, values?.description]);

  return (
    <>
      <tr>
        <th>
          <Form.Group style={{width: "200px"}}>
            <AutoCompleteProductField
              name="product_id"
              products={products}
              value={productSelectorValue}
              setValue={handleProductSelect}
            />
            {errors &&
              errors.offer_line &&
              errors.offer_line[rowId] &&
              errors?.offer_line[rowId]?.product_id &&
              touched &&
              touched.offer_line &&
              touched.offer_line[rowId] &&
              touched.offer_line[rowId].product_id && (
                <div className="error-message">
                  {t(errors?.offer_line[rowId]?.product_id)}
                </div>
              )}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <Field
              type="number"
              className="text-center form-control "
              name={`offer_line.${rowId}.quantity`}
              placeholder="quantity"
            />
            {errors &&
              errors.offer_line &&
              errors.offer_line[rowId] &&
              errors?.offer_line[rowId]?.quantity &&
              touched &&
              touched.offer_line &&
              touched.offer_line[rowId] &&
              touched.offer_line[rowId].quantity && (
                <div className="error-message">
                  {errors?.offer_line[rowId]?.quantity}
                </div>
              )}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center flex-column">
                <Field
                  type="number"
                  className="form-control form-control-user text-center"
                  placeholder="price"
                  disabled={disableInputs}
                  name="price"
                  onChange={(e) => {
                    setFieldValue(`offer_line.${rowId}.price`, parseInt(e.target.value));
                    setFieldValue(`offer_line.${rowId}.finalPrice`, e.target.value);
                  }}
                  value={values.finalPrice || 0.00}
                />

                {touched &&
                  touched.offer_line &&
                  touched.offer_line[rowId] &&
                  touched.offer_line[rowId].price &&
                  errors &&
                  errors.offer_line &&
                  errors.offer_line[rowId] &&
                  errors.offer_line[rowId].price && (
                    <div className="error-message">
                      {errors.offer_line[rowId].price}
                    </div>
                  )}
              </div>

              {disableInputs && (
                <OverlayTrigger
                  placement="right"
                  delay={{show: 250, hide: 400}}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {t(
                        "This offer line was pre-filled using an existing product. The product is still attached to this offer line. This will affect the product statistics. Clicking the cross will detach the product"
                      )}
                    </Tooltip>
                  )}
                >
                <span onClick={() => setDisableInputs(false)} className="p-1">
                  <FontAwesomeIcon icon={faWindowClose}/>
                </span>
                </OverlayTrigger>
              )}
            </div>
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center flex-column">
                <Field
                  as="select"
                  className="form-control"
                  disabled={disableInputs}
                  name={`offer_line.${rowId}.vat_percentage`}
                >
                  <option value="" disabled>
                    {t("Select vat percentage")}
                  </option>
                  <option value="0">{t("VAT 0%")}</option>
                  <option value="6">{t("VAT low 6%")}</option>
                  <option value="9">{t("VAT low 9%")}</option>
                  <option value="21">{t("VAT high 21%")}</option>
                  <option value="1">{t("VAT shifted 0%")}</option>
                  <option value="2">{t("VAT exempt 0%")}</option>
                </Field>

                {errors &&
                  errors.offer_line &&
                  errors.offer_line[rowId] &&
                  errors.offer_line[rowId].vat_percentage &&
                  touched &&
                  touched.offer_line &&
                  touched.offer_line[rowId] &&
                  touched.offer_line[rowId].vat_percentage && (
                    <div className="error-message">
                      {errors.offer_line[rowId].vat_percentage}
                    </div>
                  )}
              </div>
              {disableInputs && (
                <OverlayTrigger
                  placement="right"
                  delay={{show: 250, hide: 400}}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {t(
                        "This offer line was pre-filled using an existing product. The product is still attached to this offer line. This will affect the product statistics. Clicking the cross will detach the product"
                      )}
                    </Tooltip>
                  )}
                >
                <span onClick={() => setDisableInputs(false)} className="p-1">
                  <FontAwesomeIcon icon={faWindowClose}/>
                </span>
                </OverlayTrigger>
              )}
            </div>
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <Form.Control
              type="text"
              className="text-center"
              name="amountExclVat"
              readOnly
              value={numberFormatter.format(values.amountExclVat) || ""}
              placeholder={t("amountExclVat")}
            />
          </Form.Group>
        </th>
        <th>
          {showDeleteButton && (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => deleteOfferLine(rowId)}
            >
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          )}
        </th>
      </tr>
    </>
  );
}
