import React from "react";
import {Button, Form, FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Field, useFormikContext} from "formik";

export default function StatusCard({text}) {
  const {t} = useTranslation();

  const {setFieldValue, isSubmitting, values} = useFormikContext();

  const handlePublish = (e) => {
    setFieldValue('status', e.target.checked ? "open" : "draft");
    setFieldValue('send_email', true);
  }

  return (
    <div className="d-flex flex-column px-4">
      <div className="d-flex flex-column my-3">
        <Form.Group className="col-md-8 invoice-status-wrapper">
          <Form.Check type="checkbox" className="my-3">
            <Field
              as={FormCheck.Input}
              id="defaultCheck"
              className="me-2"
              value={values.status === 'open'}
              checked={values.status === 'open'}
              onChange={handlePublish}
            />
            <FormCheck.Label htmlFor="defaultCheck" className="mb-0">
              {text}
            </FormCheck.Label>
          </Form.Check>
          {values.status === 'open' && (
            <Form.Check
              type="checkbox"
              className="my-3 d-flex align-items-center"
            >
              <Field
                as={FormCheck.Input}
                name="send_email"
                id="defaultCheck2"
                type="checkbox"
                className="me-2"
              />
              <FormCheck.Label htmlFor="defaultCheck2" className="mb-0">
                {t("Send it by e-mail")}
              </FormCheck.Label>
              <div className="d-flex flex-column mx-5 col-md-6">
                {values.send_email && (
                  <Form.Group>
                    <Field
                      as={Form.Control}
                      name="email_to"
                      type="email"
                      placeholder="example@example.com"
                    />
                  </Form.Group>
                )}
              </div>
            </Form.Check>
          )}
        </Form.Group>
      </div>
      {values.status === "open" ? (
        <div className="row my-2">
          {values.email_to ? (
            <div className="col-md-4 my-4">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {t("Publish and send")}
              </Button>
            </div>
          ) : (
            <div className="col-md-4 my-4">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {t("Publish")}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="col-md-4 my-4">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {t("Save as draft")}
          </Button>
        </div>
      )}
    </div>
  );
}
