import {Card, Form, FormCheck} from "@themesberg/react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import Toggle from "../../settings/components/ToggleSwitch/switch";

export default function InvoiceReminder({
                                          handleDefaultSetting,
                                          defaultSetting,
                                          firstReminder,
                                          setFirstReminder,
                                          secondReminder,
                                          disableReminderButton,
                                          setSecondReminder
                                        }) {
  const {t} = useTranslation();

  return (
    <Card className="col-md-12 col-12">
      <Card.Header className="p-2">
        <h5>{t("Invoice Reminder")}</h5>
      </Card.Header>
      <Card.Body className="row">
        <div className="row  mt-2">
          <div className="col-12">
            <Form.Check type="checkbox">
              <FormCheck.Input
                id="defaultCheck5"
                className="me-2"
                checked={defaultSetting}
                onChange={handleDefaultSetting}
              />
              <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                {t("Use the default settings for invoice reminders for this invoice")}
              </FormCheck.Label>
            </Form.Check>
          </div>
        </div>
        {!defaultSetting && (
          <div className="row">
            <div className="col-4">
              <Form.Group className="d-flex pt-4">
                <div className="px-2">
                  <Toggle
                    checked={firstReminder}
                    handleChange={(checked) => setFirstReminder(checked)}
                  />
                </div>
                <Form.Label>{t("Reminder")}</Form.Label>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="d-flex pt-4">
                <div className="px-2">
                  <Toggle
                    checked={secondReminder}
                    disabled={disableReminderButton}
                    handleChange={(checked) => setSecondReminder(checked)}
                  />
                </div>
                <Form.Label>{t("Second Reminder")}</Form.Label>
              </Form.Group>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}