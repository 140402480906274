import React from 'react';

export default function StatusBullet({status}) {
  const bulletStyle = {width: 10, height: 10, borderRadius: "50%", display: 'inline-block'};
  let bgcolor = 'gray';
  if (status === 'draft') bgcolor = 'gray';
  if (status === 'published') bgcolor = 'gray';// published is show as open in status
  if (status === 'overdue') bgcolor = 'red';
  if (status === 'paid') bgcolor = 'green';
  if (status === 'partially_paid') bgcolor = 'purple';
  if (status === 'open') bgcolor = 'yellow';
  if (status === 'accepted') bgcolor = 'green';
  if (status === 'pending') bgcolor = 'red';
  return <span style={{...bulletStyle, backgroundColor: bgcolor}}></span>;
}
