import {combineReducers} from "@reduxjs/toolkit";
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userCompany from "./userCompany";
import loading from './loading';
import search from './search';
import customers from "./customers";
import templates from "./templates";
import reseller from "./reseller";
import offer from "./offer";
import products from "./products";
import contracts from "./contracts";
import settings from "./settings";
import message from "./message";
import subscriptions from "./subscriptions";
import invoice from "./invoice";

const userCompanyPersistConfig = {
  key: 'userCompany',
  storage,
  version: 1,
};

const loadingPersistConfig = {
  key: 'loading',
  storage,
  version: 1,
};

const searchPersistConfig = {
  key: 'search',
  storage,
  version: 1,
};

const customersPersistConfig = {
  key: 'customers',
  storage,
  version: 1,
};

const templatesPersistConfig = {
  key: 'templates',
  storage,
  version: 1,
};

const resellerPersistConfig = {
  key: 'reseller',
  storage,
  version: 1,
};

const offerPersistConfig = {
  key: 'offer',
  storage,
  version: 1,
};

const productsPersistConfig = {
  key: 'products',
  storage,
  version: 1,
};

const contractsPersistConfig = {
  key: 'contracts',
  storage,
  version: 1,
};

const settingsPersistConfig = {
  key: 'settings',
  storage,
  version: 1,
};

const messagePersistConfig = {
  key: 'message',
  storage,
  version: 1,
};

const subscriptionsPersistConfig = {
  key: 'subscriptions',
  storage,
  version: 1,
};

const invoicePersistConfig = {
  key: 'invoice',
  storage,
  version: 1,
};

// Combine reducers with their respective persist configurations
const rootReducer = combineReducers({
  userCompany: persistReducer(userCompanyPersistConfig, userCompany),
  loading: persistReducer(loadingPersistConfig, loading),
  search: persistReducer(searchPersistConfig, search),
  customers: persistReducer(customersPersistConfig, customers),
  templates: persistReducer(templatesPersistConfig, templates),
  reseller: persistReducer(resellerPersistConfig, reseller),
  offer: persistReducer(offerPersistConfig, offer),
  products: persistReducer(productsPersistConfig, products),
  contracts: persistReducer(contractsPersistConfig, contracts),
  settings: persistReducer(settingsPersistConfig, settings),
  message: persistReducer(messagePersistConfig, message),
  subscriptions: persistReducer(subscriptionsPersistConfig, subscriptions),
  invoice: persistReducer(invoicePersistConfig, invoice)
});

export default rootReducer;
