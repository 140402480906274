import axios from "axios";
import uuid from "uuid";
import Auth from "./../auth/libs/Auth";

import i18next from "i18next";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const clientId = process.env.REACT_APP_CLIENT_ID || 2;
const clientSecret =
  process.env.REACT_APP_CLIENT_SECRET ||
  "dtUo4Ml9GdOBo9EEhaD95q7BIRR0Nnwq5O4vgcyJ";

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return new Promise((resolve, reject) => {
      let baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
      const originalRequest = error.config ? error.config : {}
      const refreshToken = localStorage.getItem('refreshToken');
      const rememberMe = localStorage.getItem('persistence');
      if (error.response && error.response.status === 401) {
        if (refreshToken && rememberMe === "true") {
          alert('refreshToken && rememberMe === "true"');
          originalRequest._retry = true
          const response = fetch(baseUrl + "oauth/token", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              grant_type: "refresh_token",
              refresh_token: refreshToken,
              client_id: clientId,
              client_secret: clientSecret,
              scope: "*",
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (
                response.access_token !== undefined &&
                response.refresh_token !== undefined
              ) {
                localStorage.setItem("token", response.access_token);
                localStorage.setItem("refreshToken", response.refresh_token);
                resolve();
                window.location.reload();
              }
              return reject(response.errors || []);
            }).catch(error => {
              alert('shizzle');
            })
          return resolve(response)
        } else {
          // alert('no refresh token and so');
          Auth.clearData()
          // window.location.href = '/';
          return reject(error);
        }
      }
      return reject(error)
    })
  },
)

class Api {
  sessionId = '';

  constructor() {
    this.createSessionId();
  }

  get = (url, initalGetVars = {}, initialConfig = {}) => {
    let {getVars, config} = this.defaults(initalGetVars, initialConfig);
    return axios.get(this.addVarsToUrl(url, getVars), config).then(this.handleAuthError);
  };

  post = (url, data = {}, initalGetVars = {}, initialConfig = {}) => {
    let {getVars, config} = this.defaults(initalGetVars, initialConfig);
    return axios.post(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
  };

  put = (url, data = {}, initalGetVars = {}, initialConfig = {}) => {
    let {getVars, config} = this.defaults(initalGetVars, initialConfig);
    return axios.put(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
  };

  delete = (url, initalGetVars = {}, initialConfig = {}) => {
    let {getVars, config} = this.defaults(initalGetVars, initialConfig);
    return axios.delete(this.addVarsToUrl(url, getVars), config).then(this.handleAuthError);
  };

  defaults = (initalGetVars, config = {}) => {
    let getVars = initalGetVars;
    if (Auth.isLoggedIn()) {
      if (config.headers) {
        config.headers['Authorization'] = 'Bearer ' + Auth.getToken();
      } else {
        config.headers = {'Authorization': 'Bearer ' + Auth.getToken()};
      }
    }

    if (config.headers) {
      config.headers['Accept-Language'] = i18next.language;
    } else {
      config.headers = {'Accept-Language': i18next.language};
    }

    return {getVars, config};
  };

  handleAuthError = (response) => {
    let code = response.data[0] || false;
    if (code === 401) {
      // alert(i18next.t('Your login has expired. Please login again'));
      Auth.clearData();
      window.location.reload();
      throw new Error("ForgotPassword expired");
    }
    return response;
  };

  addVarsToUrl = (url, getVars) => url + '?' + Object.keys(getVars).map((key) => key + '=' + encodeURIComponent(getVars[key])).join('&');

  createSessionId = () => {
    let sessionId = localStorage.getItem('sessionId') || '';
    if (sessionId === '') {
      sessionId = uuid.v4();
      localStorage.setItem('sessionId', sessionId);
    }

    this.sessionId = sessionId;
  };
}

export default new Api();
