import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field} from "formik";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import {Button, Card, Form, InputGroup, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import ContactPersonModal from "../../customers/components/ContactPersonModal";
import CustomerModal from "../../customers/components/CustomerModal";
import Customer from "../../customers/lib/Customer";
import CreateTemplateModal from "../../settings/components/Template/CreateTemplate";
import {fetchCompanyCustomers} from "../../store/actions/customers";
import {fetchTemplates} from "../../store/actions/templates";

export default function OfferInfo({
                                    customerId,
                                    setCustomerId,
                                    setMessage,
                                    offerDate,
                                    setOfferDate,
                                    newDate,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    values,
                                    offerById,
                                    setTemplateId,
                                  }) {
  const [customers, setCustomers] = useState([]);
  const [contactPersons, setContactPersons] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [contactPersonsEmail, setContactPersonsEmail] = useState([]);
  const [Templatemodal, setTemplatemodal] = useState(false);
  const [showCustomerModal, setShowCustomer] = useState(false);
  const handleCloseCustomer = () => setShowCustomer(false);
  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  const handleCloseContactPerson = () => setShowContactPersonModal(false);

  const modules = {
    toolbar: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline", "strike"],
      [{color: []}, {background: []}],
      [{script: "sub"}, {script: "super"}],
      ["blockquote", "code-block"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}, {align: []}],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "offer_line",
    "direction",
    "align",
    "link",
    "formula",
    "image",
  ];

  const handleChangeNotes = (html) => {
    setFieldValue("offer_notes", html);
  };
  const handleChangePurchase = (html) => {
    setFieldValue("offer_purchase_conditions", html);
  };

  const todayDate = new Date();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const customerList =
    useSelector((state) => state.customers.customers.customers) || [];
  const templatesList =
    useSelector((state) => state.templates.templates) || [];

  useEffect(() => {
    dispatch(fetchTemplates());
    dispatch(fetchCompanyCustomers());
    // eslint-disable-next-line
  }, []);

  const formatTemplates = () => {
    setTemplates(
      templatesList?.map((template) => ({
        label: template.name,
        value: template.id,
      }))
    );
  };

  useEffect(() => {
    if (customerList.length > 0) {
      getCustomers();
    }

    if (templatesList.length > 0) {
      formatTemplates();
    }
    // eslint-disable-next-line
  }, [dispatch, customerList]);

  // get all customers for dropdown offer_line
  const getCustomers = () => {
    setCustomers(
      customerList?.map((customer) => ({
        label: customer.company_name,
        value: customer.id,
      }))
    );
  };

  useEffect(() => {
    if (customerId) handleContactPersonCreated(customerId);
    // eslint-disable-next-line
  }, [customerId]);

  const handleContactPersonCreated = (customerId) => {
    Customer.fetchCustomerContactPersons(customerId)
      .then((res) => {
        let array = [];
        let email = [];
        for (let i in res?.contact_persons) {
          const prefix = res.contact_persons[i].prefix
            ? res.contact_persons[i].prefix + " "
            : "";
          array.push({
            label:
              prefix +
              res.contact_persons[i].first_name +
              " " +
              res.contact_persons[i].last_name +
              " (" +
              res.contact_persons[i].email +
              ")",
            value: res.contact_persons[i].id,
          });
          email.push({
            email: res.contact_persons[i].email,
            value: res.contact_persons[i].id,
          });
        }
        setContactPersons(array);

        setContactPersonsEmail(email);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const contactEmail = contactPersonsEmail.filter((contactPersonEmail) => {
      return contactPersonEmail.value === values.contact_person_id;
    });

    if (contactEmail[0]?.email) {
      setFieldValue("email_to", contactEmail[0]?.email);
    }

    // eslint-disable-next-line
  }, [values]);

  return (
    <>
      <Card>
        <Card.Header className="p-2">
          <h5>{t("Offer Info")}</h5>
        </Card.Header>
        <Card.Body className="row">
          <Row>
            <Form.Group className="col-md-6 h-80">
              <Form.Label>{t("Select customer")}</Form.Label>

              <div className="d-flex flex-column">
                <InputGroup>
                  <Select
                    name="customer_id"
                    placeholder={t("select")}
                    className="form-control p-0"
                    onChange={(e) => {
                      setFieldValue("customer_id", e.value);
                      setCustomerId(e.value);
                    }}
                    options={customers}
                    value={customers?.filter(
                      (customer) => customer.value === values.customer_id
                    )}
                  />
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="d-inline"
                    onClick={() => setShowCustomer(true)}
                  >
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </InputGroup>
              </div>
              {touched.customer_id && errors.customer_id ? (
                <span className="error-message">{t(errors.customer_id)}</span>
              ) : null}
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label>{t("Select Contact Person")}</Form.Label>
              <InputGroup>
                <Select
                  name="contact_person_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(e) => setFieldValue("contact_person_id", e.value)}
                  value={
                    contactPersons?.filter(
                      (contactPerson) =>
                        values.contact_person_id === contactPerson.value
                    )?.[0] || null
                  }
                  isDisabled={!values.customer_id}
                  options={contactPersons}
                />
                {customerId && (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="d-inline"
                    onClick={() => setShowContactPersonModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                )}
              </InputGroup>
              {errors.contact_person_id && touched.contact_person_id ? (
                <div className="error-message">{errors.contact_person_id}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            {Object.keys(offerById)?.length > 0 ? null : (
              <Form.Group className="col-md-6 mt-3 d-flex flex-column">
                <Form.Label>{t("Offer date")}</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  placeholdertext="Choose a date"
                  calendarstartday={1}
                  selected={todayDate}
                  value={moment
                    .tz(todayDate, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                  disabled
                />
              </Form.Group>
            )}
            <Form.Group className="col-md-6 mt-3 d-flex flex-column">
              <Form.Label>{t("Offer expiration date")}</Form.Label>
              <DatePicker
                name="expiration_date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                calendarstartday={1}
                minDate={todayDate}
                selected={offerDate}
                placeholderText={newDate}
                onChange={(date) => setOfferDate(date)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-6 mt-3 h-80">
              <Form.Label>{t("Select Template")}</Form.Label>
              <InputGroup>
                <Select
                  name="template_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(e) => {
                    setFieldValue("template_id", e.value);
                    setTemplateId(e.value);
                  }}
                  value={
                    templates?.filter(
                      (template) => template.value === values.template_id
                    )?.[0] || null
                  }
                  options={templates}
                />
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="d-inline"
                  onClick={() => setTemplatemodal(true)}
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </InputGroup>
              {errors.template_id && touched.template_id ? (
                <span className="error-message">{t(errors.template_id)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-3 h-80">
              <Form.Label>{t("Title")}</Form.Label>
              <Field
                name="offer_title"
                className="form-control"
                placeholder={t("Title")}
                value={values.offer_title}
                onChange={(e) => setFieldValue("offer_title", e.target.value)}
              />
              {touched.offer_title && errors.offer_title ? (
                <span className="error-message">{t(errors.offer_title)}</span>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-6 mt-3">
              <Form.Label>{t("Notes")}</Form.Label>
              <Field name="offer_notes">
                {() => (
                  <ReactQuill
                    placeholder={t("Notes")}
                    name="offer_notes"
                    className="toolbar"
                    value={values.offer_notes}
                    onChange={handleChangeNotes}
                    modules={modules}
                    formats={formats}
                  />
                )}
              </Field>
              {errors.offer_notes && touched.offer_notes ? (
                <span className="error-message">{t(errors.offer_notes)}</span>
              ) : null}
            </Form.Group>

            <Form.Group className="col-md-6 mt-3">
              <Form.Label>{t("Purchase Conditions")}</Form.Label>
              <Field name="offer_purchase_conditions">
                {() => (
                  <ReactQuill
                    placeholder={t("Purchase Conditions")}
                    name="offer_purchase_conditions"
                    className="toolbar"
                    value={values.offer_purchase_conditions}
                    onChange={handleChangePurchase}
                    modules={modules}
                    formats={formats}
                  />
                )}
              </Field>
              {errors.offer_purchase_conditions &&
              touched.offer_purchase_conditions ? (
                <span className="error-message">
                  {t(errors.offer_purchase_conditions)}
                </span>
              ) : null}
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <CustomerModal
        show={showCustomerModal}
        handleClose={handleCloseCustomer}
        getCustomers={getCustomers}
        setModalMessage={setMessage}
        updateCustomer={customerId}
        reminders=" Use the default settings for offer reminders for this offer."
      />

      <ContactPersonModal
        show={showContactPersonModal}
        handleClose={handleCloseContactPerson}
        handleSave={() => handleContactPersonCreated(customerId)}
        // contactPersonDefaultValues={setContactPersons}
        customerId={customerId}
        setModalMessage={setMessage}
      />

      <CreateTemplateModal
        show={Templatemodal}
        templateId=""
        handleClose={() => setTemplatemodal(false)}
        setModalMessage={(data) => {
          setMessage(data);
        }}
        // setRefreshData={setRefresTemplate}
      />
    </>
  );
}
