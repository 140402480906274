export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const SEARCH = 'SEARCH';
export const FOCUS = 'FOCUS';
export const BLUR = 'BLUR';

export const GET_RESELLER_USER_BY_ID_START = "GET_RESELLER_USER_BY_ID_START";
export const GET_RESELLER_USER_BY_ID_SUCCESS = "GET_RESELLER_USER_BY_ID_SUCCESS";
export const GET_RESELLER_USER_BY_ID_FAILED = "GET_RESELLER_USER_BY_ID_FAILED";

export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";

export const GET_TEMPLATES_START = "GET_TEMPLATES_START";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED";

export const GET_VALID_MANDATE_START = "GET_VALID_MANDATE_START";
export const GET_VALID_MANDATE_SUCCESS = "GET_VALID_MANDATE_SUCCESS";
export const GET_VALID_MANDATE_FAILED = "GET_VALID_MANDATE_FAILED";

export const GET_OFFERS_START = "GET_OFFERS_START";
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
export const GET_OFFERS_FAILED = "GET_OFFERS_FAILED";

export const GET_OFFER_BY_ID_START = "GET_OFFER_BY_ID_START";
export const GET_OFFER_BY_ID_SUCCESS = "GET_OFFER_BY_ID_SUCCESS";
export const GET_OFFER_BY_ID_FAILED = "GET_OFFER_BY_ID_FAILED";

export const GET_CUSTOMER_BY_ID_START = "GET_CUSTOMER_BY_ID_START";
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS";
export const GET_CUSTOMER_BY_ID_FAILED = "GET_CUSTOMER_BY_ID_FAILED";

export const GET_CONTRACT_BY_ID_START = "GET_CONTRACT_BY_ID_START";
export const GET_CONTRACT_BY_ID_SUCCESS = "GET_CONTRACT_BY_ID_SUCCESS";
export const GET_CONTRACT_BY_ID_FAILED = "GET_CONTRACT_BY_ID_FAILED";

export const GET_SUBSCRIPTIONS_START = "GET_SUBSCRIPTIONS_START";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILED = "GET_SUBSCRIPTIONS_FAILED";

export const SET_INVOICE_CUSTOMER = "SET_INVOICE_CUSTOMER";
export const SET_INVOICE_CUSTOMER_ID = "SET_INVOICE_CUSTOMER_ID";
export const RESET_INVOICE = "RESET_INVOICE";