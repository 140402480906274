import Api from "../../libs/Api";
import {OpenFeature} from "@openfeature/react-sdk";

const clientId = process.env.REACT_APP_CLIENT_ID || 2;
const clientSecret =
  process.env.REACT_APP_CLIENT_SECRET ||
  "dtUo4Ml9GdOBo9EEhaD95q7BIRR0Nnwq5O4vgcyJ";

class Auth {
  register = (data) => {
    let userData = data;
    return new Promise((resolve, reject) => {
      Api.post("/api/user-company/register", userData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            if (response.data.user) {
              this.setActiveUserId(response.data.user.id);
              this.setActiveCompanyId(response.data.company.id);
            }
          }
        })
        .catch((error) =>
          reject((((error || {}).response || {}).data || {}).errors || [])
        );
    });
  };

  createFirstInvoice = (data) => {
    let userData = data;
    return new Promise((resolve, reject) => {
      Api.post("/api/firstInvoice", userData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  };

  confirm = (id, hash) => {
    let payload = {
      id: id,
      hash: hash,
    };

    return new Promise((resolve, reject) => {
      Api.post("/api/register/verify", payload)
        .then((response) => {
          if ((response.data || {}).status || "" === "success") {
            resolve();
          }
          reject(response.data.status || null);
        })
        .catch(() => reject(null));
    });
  };

  forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/forgot-password",
        {email: email})
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => reject(errors));
    });
  };

  reset = (email, password, passwordConfirmation, token) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/forgot-password/reset", {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        token: token,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) =>
          reject(error)
        );
    });
  };

  login = (email, password) => {
    return new Promise((resolve, reject) => {
      Api.post("/oauth/token", {
        grant_type: "password",
        client_id: clientId,
        client_secret: clientSecret,
        username: email,
        password: password,
        scope: "*",
      })
        .then((response) => {
          if (
            response.data.access_token !== undefined &&
            response.data.refresh_token !== undefined
          ) {
            this.setRefreshToken(response.data.refresh_token);
            this.setToken(response.data.access_token);
            resolve();
          }
          reject(response.data.errors || []);
        })
        .catch((error) =>
          reject((((error || {}).response || {}).data || {}).errors || [])
        );
    });
  };

  loggedInafterFirstPayment = (user_id) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/subscriptions/login",
        {user_id: user_id})
        .then((response) => {
          if (response.data.success) {
            if (response.data.refresh_token) {
              this.setRefreshToken(response.data.refresh_token);
            }
            this.setToken(response.data.access_token);
            resolve();
          }
        })
        .catch((errors) => reject(errors));
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/logout")
        .then((response) => {
          resolve(response.data);
          OpenFeature.setContext({});
          this.clearData();
        })
        .catch((error) => reject(error));
    });
  };

  getUserData = () => {
    return new Promise((resolve, reject) => {
      if (this.isLoggedIn()) {
        Api.get("/api/user", {})
          .then((response) => {
            if (response.data.user.id !== undefined) {
              resolve(response.data);
              this.setActiveUserId(response.data.user.id);
              this.setActiveCompanyId(response.data.company.id);
            }

            reject(response.data || null);
          })
          .catch(() => reject(null));
      } else {
        reject(null);
      }
    });
  };

  updateUserData = (userData) => {
    const userId = userData.id
    return new Promise((resolve, reject) => {
      Api.put("/api/user/" + userId, userData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(() => reject(null));
    });
  };

  getToken = () => localStorage.getItem("token");

  setToken = (token) => localStorage.setItem("token", token);

  getRefreshToken = () => localStorage.getItem("refreshToken");

  setRefreshToken = (refreshToken) =>
    localStorage.setItem("refreshToken", refreshToken);

  isLoggedIn = () =>
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined;

  clearData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  };

  setActiveUserId = (userId) => localStorage.setItem("active_user_id", userId);
  setActiveCompanyId = (companyId) => localStorage.setItem("active_company_id", companyId);

  clearActiveUserId = (userId) => localStorage.removeItem("active_user_id");
  clearActiveCompanyId = (companyId) => localStorage.removeItem("active_company_id");

  getActiveCompanyId = () => localStorage.getItem('active_company_id');
  getActiveUserId = () => localStorage.getItem('active_user_id');
};

export default new Auth();