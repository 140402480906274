import "./switch.css";
import React from "react";
import Toggle from 'react-toggle'

function Switch({checked, handleChange, disabled = false, name = '', label = ''}) {

  return (
    <>
      <Toggle
        checked={checked}
        aria-labelledby={name}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.checked)}/>
      {name && label && <span id={name}>{label}</span>}
    </>
  );
}

export default Switch;

