import {faEdit, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import {Breadcrumb, Card, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {getResellerUserById} from "../../store/actions/reseller";

export default function UserDetails() {
  const {id} = useParams();
  const {t} = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResellerUserById(id));
    // eslint-disable-next-line
  }, []);

  const user = useSelector((state) => state?.reseller?.reseller?.user);
  const companyData = useSelector((state) => state?.reseller?.reseller.user?.companies[0]);
  const subscriptionName = companyData?.company_subscription?.subscription_name;
  return (
    <div className="mt-2">
      <div className="d-block mb-4 mb-md-0">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
        >
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHome}/>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">{t("User")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("View")}</Breadcrumb.Item>
        </Breadcrumb>
        <h4>{t("User Details")}</h4>
      </div>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <h6>{t("User Details")}</h6>
          <FontAwesomeIcon
            icon={faEdit}
            className="me-2 cursor-pointer"
            onClick={() => history.push(`/reseller/update-user/${id}`)}
          />
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
            <tr>
              <th>{t("User Name")}</th>
              <td>
                {user?.first_name} {user?.first_name}
              </td>
            </tr>
            <tr>
              <th>{t("Email")}</th>
              <td>{user?.email}</td>
            </tr>
            <tr>
              <th>{t("Status")}</th>
              <td>{user?.status}</td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Header className="d-flex justify-content-between">
          <h6>{t("Company Details")}</h6>
          <FontAwesomeIcon
            icon={faEdit}
            className="me-2 cursor-pointer"
            onClick={() => history.push(`/reseller/update-company/${id}`)}
          />
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
            <tr>
              <th>{t("Company Name")}</th>
              <td>{companyData?.name}</td>
            </tr>
            <tr>
              <th>{t("Address")}</th>
              <td>
                {` ${companyData?.street ? companyData?.street : ""}
                    ${
                  companyData?.house_number ? companyData?.house_number : ""
                }
                  `}
                <br/>
                {`${companyData?.postcode ? companyData?.postcode : ""}
                    ${companyData?.city ? companyData?.city : ""}
                    ${companyData?.country ? companyData?.country : ""}`}
              </td>
            </tr>
            <tr>
              <th>{t("Email")}</th>
              <td>{companyData?.email}</td>
            </tr>
            <tr>
              <th>{t("Phone Number")}</th>
              <td>{companyData?.phone_number}</td>
            </tr>
            <tr>
              <th>{t("VAT Number")}</th>
              <td>{companyData?.vat_number}</td>
            </tr>
            <tr>
              <th>{t("coc number")}</th>
              <td>{companyData?.coc_number}</td>
            </tr>
            <tr>
              <th>{t("Website")}</th>
              <td>{companyData?.website_url}</td>
            </tr>
            <tr>
              <th>{t("Status")}</th>
              <td>{companyData?.status}</td>
            </tr>
            <tr>
              <th>{t("Current subscription")}</th>
              <td className="d-flex justify-content-between align-items-center">
                <p className="pt-2">{subscriptionName}</p>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="me-2 cursor-pointer"
                  onClick={() =>
                    history.push(`/reseller/change-subscription`)
                  }
                />
              </td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
