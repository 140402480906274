import Api from "../../libs/Api";

import {
  companySettingsFetched,
  companySettingsFetchingFailed,
  companySettingsFetchingStarted
} from "../reducers/settings";

export const getCompanySettings = () => {
  return async (dispatch) => {
    dispatch(companySettingsFetchingStarted());

    try {
      const response = await Api.get(`/api/company-settings`);
      dispatch(companySettingsFetched(response.data))
    } catch (e) {
      console.log(e.response);
      dispatch(companySettingsFetchingFailed(e?.response?.data?.message));
    }
  };
};
