import React, {useEffect, useState} from "react";
import {faCog, faUsers} from "@fortawesome/free-solid-svg-icons";
import FactuursnelLogo2 from "../assets/img/logo.png";
import {Link, useLocation} from "react-router-dom";
import Sidebar from "../admin/layout/components/Sidebar";
import Navbar from "../admin/layout/components/Navbar";
import Preloader from "../admin/layout/components/Preloader";
import CreateMandate from "./payment/CreateMandate";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import SearchResult from "../admin/components/SearchResults";
import {Accordion, Badge, Image, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectUser, selectUserMandate} from "../store/reducers/userCompany";


export default function ResellerLayout({children}) {
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");

  const user = useSelector(selectUser);
  const mandate = useSelector(selectUserMandate);
  const {t} = useTranslation();

  const location = useLocation();

  const {pathname} = location;

  useEffect(() => {
    setSearch("");
  }, [location]);
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const CollapsableNavItem = (props) => {
    const {eventKey, title, icon, children = null} = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon}/>
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? {href: link} : {as: Link, to: link};

    return (
      <Nav.Item className={navItemClassName}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon}/>
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text text-capitalize">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Preloader show={loaded ? false : true}/>
      <Sidebar>
        <NavItem title="FACTUURSNEL" image={FactuursnelLogo2} link="/"/>
        <NavItem title={t("Users")} link="/reseller/user" icon={faUsers}/>
        <CollapsableNavItem eventKey="settings" title={t("settings")} icon={faCog}>
          <NavItem title={t("Company settings")} link="/company-settings"/>
        </CollapsableNavItem>
      </Sidebar>


      <main className="content">
        <Navbar user={user} search={search} setSearch={setSearch}/>
        {!mandate && <CreateMandate/>}
        {search ? <SearchResult search={search}/> : children}
      </main>
    </>
  );
}
