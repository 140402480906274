import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import StatusBullet from "../invoices/components/utils/StatusBullet";
import React from "react";
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import OffersActions from "../offers/libs/Offers";
import {numberFormatter} from "../libs/numberFormatter";

export default function OfferTable({
                                     id,
                                     offer_date,
                                     status,
                                     setMessage,
                                     setPaginationData,
                                     uuid,
                                     setLoading,
                                     index,
                                     offer_number,
                                     offer_lines,
                                     customer_name,
                                   }) {
  const {t} = useTranslation();
  const getPaginatedOffers = (page = null) => {
    setLoading(true);
    OffersActions.getPaginatedOffers(page)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setPaginationData({
            links: res.links,
            prevPageUrl: res.prev_page_url,
            nextPageUrl: res.next_page_url,
            showingPerPage: res.to - res.from + 1,
            total: res.total,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteOffer = (offerId) => {
    OffersActions.deleteOffer(offerId)
      .then((res) => {
        if (res.succes === true) {
          setMessage({show: true, message: res.message, variant: "success"});
          getPaginatedOffers();
        } else if (res.succes === false) {
          setMessage({show: true, message: res.message, variant: "danger"});
        }
      })
      .catch((error) => console.log(error));
  };

  const offerStatus = status
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1))
    .join(" ")
    .toLowerCase();
  return (
    <tr>
      <td>
        <span className="fw-normal">{index + 1}</span>
      </td>
      <td>
        <span className="fw-normal">
          {format(parseISO(offer_date), "dd-MM-yyyy")}
        </span>
      </td>
      <td>
        <span className="fw-normal">{offer_number}</span>
      </td>
      <td>
        <span className="fw-normal">{customer_name}</span>
      </td>

      <td>
        <span className="fw-normal">
          <StatusBullet status={status}/>
          &nbsp;&nbsp;
          {offerStatus === "Published" ? "Open" : status.replace(/_/g, " ")}
        </span>
      </td>

      <td>
        <span className="fw-normal">{numberFormatter.format(offer_lines ? offer_lines[0]?.price : 0)}</span>
      </td>

      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={Button}
            split
            variant="link"
            drop="down"
            className="text-dark m-0 p-0"
            id="dropdown-split-basic"
          >
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-dropdown">
            {offerStatus !== "draft" && (
              <>
                <Dropdown.Item as={Link} to={`/view-offer/${id}`}>
                  <FontAwesomeIcon icon={faEye} className="me-2"/>
                  {t("View Offer")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/offer-details/${uuid}`}>
                  <FontAwesomeIcon icon={faEye} className="me-2"/>
                  {t("View Offer for Customer")}
                </Dropdown.Item>
              </>
            )}
            {offerStatus === "draft" && (
              <>
                <Dropdown.Item as={Link} to={`/edit-offer/${id}`}>
                  <FontAwesomeIcon icon={faEdit} className="me-2"/>
                  {t("edit")}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => {
                    if (window.confirm(t("delete offer"))) deleteOffer(id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2"/>
                  {t("remove")}
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}
