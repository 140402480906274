import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Table,
  Button,
  Card,
  Dropdown,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import Company from "../../companies/libs/Company";
import {faPlus, faTrashAlt, faEdit, faEllipsisH, faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import CustomerModal from "./CustomerModal";
import AlertDismissible from "../../components/old/AlertDismissible";
import CustomSpinner from "../../components/CustomSpinner";
import {OldPagination} from "../../components/old/OldPagination";
import {extractPaginationData} from "../../libs/pagination";
import {Pagination} from "../../components/Pagination";
import Messages from "../../components/Messages";
import {messageShown} from "../../store/reducers/message";
import {useDispatch} from "react-redux";

function Customers() {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [page, setPage] = useState(null);
  const [showCustomerModal, setShowCustomer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: "",
  });

  const handleSaveCustomer = () => {
    getPaginatedCustomers(page);
    setShowCustomer(false);
  }
  const handleCloseModal = () => setShowCustomer(false);

  const handleNewClicked = () => {
    setSelectedCustomer(null)
    handleShowModal()
  };

  const handleShowModal = (updateCustomer) => {
    if (updateCustomer) {
      setSelectedCustomer(updateCustomer);
    }
    setShowCustomer(true);
  };

  useEffect(() => {
    getPaginatedCustomers(page);
  }, [page]);

  const getPaginatedCustomers = (page = null) => {
    setLoading(true);
    Company.getCompanyPaginatedCustomers(page)
      .then((res) => {
        setLoading(false);
        if (res.customers) {
          setCustomers(res.customers.data);
          setPaginationData(extractPaginationData(res.customers));
        } else {
          dispatch(messageShown({message: res.message, variant: "warning"}));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteCustomer = (customerId) => {
    Company.deleteCompanyCustomer(customerId).then((res) => {
      if (res.success === true) {
        dispatch(messageShown({message: res.message, variant: "success"}));
        getPaginatedCustomers();
      } else {
        dispatch(messageShown({message: res.message, variant: "danger"}));
      }
    });
  };


  const TableRow = ({index, customer}) => {
    const suffix = customer.house_number_suffix
      ? " " + customer.house_number_suffix
      : "";
    return (
      <tr>
        <td>
          <span className="fw-normal">{index + 1}</span>
        </td>
        <td>
          <span className="fw-normal">{customer.company_name}</span>
        </td>
        <td>
          <span className="fw-normal">{customer.phone_number}</span>
        </td>
        <td>
          <span className="fw-normal">
            {customer.street +
              " " +
              customer.house_number +
              suffix +
              ", " +
              customer.city +
              " " +
              customer.country}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              drop="down"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown">
              <Dropdown.Item href={`/customer-details/${customer.id}`}>
                <FontAwesomeIcon icon={faEye} className="me-2"/> {t("View Details")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {
                handleShowModal(customer);
              }}>
                <FontAwesomeIcon icon={faEdit} className="me-2"/> {t("edit")}
              </Dropdown.Item>
              <Dropdown.Item className="text-danger"
                             onClick={(e) => {
                               if (window.confirm(t("delete customer")))
                                 deleteCustomer(customer.id);
                             }}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2"/> {t("remove")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
                      listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("customers")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("customers")}</h4>
          <p className="mb-0">{t("Showing customers of your company")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={handleNewClicked}>
              <FontAwesomeIcon icon={faPlus} className="me-2"/> {t("new")}</Button>
          </ButtonGroup>
        </div>
      </div>

      <Messages className="mb-2"/>

      {loading ? <CustomSpinner/> : <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0 pb-5">
          <Table hover className="user-table align-items-center">
            <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">{t("Company Name")}</th>
              <th className="border-bottom">{t("Phone Number")}</th>
              <th className="border-bottom">{t("Address")}</th>
              <th className="border-bottom">{t("Action")}</th>
            </tr>
            </thead>
            <tbody>
            {customers?.map((customer, index) => <TableRow key={`customer-${index}`} index={index}
                                                           customer={customer}/>)}
            </tbody>
          </Table>

          <Pagination page={page} setPage={setPage} {...paginationData} />

        </Card.Body>
      </Card>}

      <CustomerModal
        show={showCustomerModal}
        handleClose={handleCloseModal}
        customerData={selectedCustomer}
        handleSave={handleSaveCustomer}
      />
    </>
  );
}

export default Customers;
