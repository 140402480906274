import Api from "../../libs/Api";
import {
  GET_CONTRACT_BY_ID_FAILED,
  GET_CONTRACT_BY_ID_START,
  GET_CONTRACT_BY_ID_SUCCESS,
} from "./actionTypes";

export const getContractById = (contractId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CONTRACT_BY_ID_START,
    });

    try {
      const response = await Api.get(`/api/contracts/${contractId}`);

      dispatch({
        payload: response.data,
        type: GET_CONTRACT_BY_ID_SUCCESS,
      });
    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_CONTRACT_BY_ID_FAILED,
      });
    }
  };
};
