import Api from "../../libs/Api";
import {
  GET_OFFER_BY_ID_FAILED,
  GET_OFFER_BY_ID_START,
  GET_OFFER_BY_ID_SUCCESS,
  GET_OFFERS_FAILED,
  GET_OFFERS_START,
  GET_OFFERS_SUCCESS,
} from "./actionTypes";

export const getOffersData = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_OFFERS_START,
    });

    try {
      const response = await Api.get(`/api/offers`);
      dispatch({
        payload: response.data,
        type: GET_OFFERS_SUCCESS,
      });
    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_OFFERS_FAILED,
      });
    }
  };
};

export const getOfferById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_OFFER_BY_ID_START,
    });

    try {
      const response = await Api.get(`/api/offers/${id}`);
      dispatch({
        payload: response.data,
        type: GET_OFFER_BY_ID_SUCCESS,
      });
    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_OFFER_BY_ID_FAILED,
      });
    }
  };
};

