import React, {useEffect, useState} from "react";
import {Breadcrumb, Card, Form, Table} from "@themesberg/react-bootstrap";
import Invoice from "../libs/Invoice";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import {faBell, faHome} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import {Button, Col, Container, Row} from "react-bootstrap";
import {formatCurrency} from "../../utils/helpers";
import StatusBullet from "./utils/StatusBullet";
import ReminderModal from "./ReminderModal";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import {numberFormatter} from "../../libs/numberFormatter";
import {selectActiveCompany} from "../../store/reducers/userCompany";
import {useBooleanFlagValue} from "@openfeature/react-sdk";
import {partialPaymentSchema} from "../../validation";
import {ErrorMessage, Field, Formik} from "formik";
import PriceField from "../../components/inputs/PriceField";

function ViewInvoice() {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {t} = useTranslation();

  const [customer, setCustomer] = useState("");
  const [paymentData, setPaymentData] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState();
  const [customerSuffix, setCustomerSuffix] = useState("");
  const [status, setStatus] = useState("");
  const [file_path, setFile_path] = useState("");
  const [storableInvoiceLines, setStorableInvoiceLines] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [vatPrice, setVatPrice] = useState(0);
  const [totalAmountExclVat, setTotalAmountExclVat] = useState(0);
  const [conactPersonDetails, setConactPersonDetails] = useState("");
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceLinesData, setInvoiceLinesData] = useState([]);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [emailTo, setEmailTo] = useState("");
  const [isFirstReminderDisabled, setIsFirstReminderDisabled] = useState(false);
  const [reminders, setReminders] = useState(false);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [paidOption, setPaidOption] = useState("");
  const [partialAmtError, setPartialAmtError] = useState(null);
  const companySettings = useSelector(state => state.settings?.companySettings?.companySetting);
  const company = useSelector(selectActiveCompany);

  const partialPaymentsEnabled = useBooleanFlagValue('partial-payments', false);

  useEffect(() => {
    let vatPrice = 0;
    let totalAmountExclVat = 0;
    if (storableInvoiceLines && storableInvoiceLines.length > 0) {
      storableInvoiceLines.forEach((invoiceLine) => {
        let tempAmount = invoiceLine.quantity * invoiceLine.price;
        let tempVatPrice = (invoiceLine.vat_percentage / 100) * tempAmount;
        vatPrice += tempVatPrice;
        totalAmountExclVat += tempAmount;
      });

      setVatPrice(vatPrice);
      setTotalAmountExclVat(totalAmountExclVat);
      setTotalAmount(totalAmountExclVat + vatPrice);
      setInvoiceLinesData(storableInvoiceLines);
    }
  }, [storableInvoiceLines]);

  useEffect(() => {
    getInvoice();
    getReminders();
    getInvoiceSettings();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = ({paid_amount, paid_option}) => {
    const updatedData = {
      id: paymentData?.invoiceId,
      payment_status: paid_option,
      paid_amount: paid_amount,
    };

    console.log({updatedData})

    setPaymentData({...paymentData, total_paid: paid_amount});
    updateInvoicePayment(updatedData); // update payment api
  };

  const getInvoice = () => {
    dispatch(startLoading()); // show loading
    Invoice.getInvoice(id)
      .then((res) => {
        dispatch(stopLoading()); // hide loading
        setInvoiceLinesData(res.invoice.invoice_lines);
        updateCustomerDetails(res.invoice);
        if (res.invoice.invoice_date) {
          setInvoiceDate(res.invoice.invoice_date);
        }

        setConactPersonDetails({
          contact_person_name: res.invoice.contact_person_name || '',
          contact_person_email: res.invoice.contact_person_email || '',
          contact_person_phone_number: res.invoice.contact_person_phone_number || '',
        });

        setEmailTo(res.email_to);
        setStatus(res.invoice.status);
        setFile_path(res.invoice.file_path);
        let statusValue = res.invoice.status
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
        setPaymentData({
          expire_date: res.invoice.expiration_date,
          total: res.invoice.total_amount,
          total_paid: res.invoice.paid_amount,
          status: res.invoice.status,
          statusValue: statusValue,
          invoiceId: res.invoice.id,
        });
        if (
          res.invoice &&
          res.invoice.invoice_payments &&
          res.invoice.invoice_payments.length > 0
        ) {
          setTimeline(res.invoice.invoice_payments);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(stopLoading());
      });

    Invoice.getInvoiceLines(id)
      .then((res) => {
        setStorableInvoiceLines(res.invoiceLines);
      })
      .catch((error) => console.log(error));
  };

  const getReminders = () => {
    Invoice.getReminders(id)
      .then((res) => {
        setReminders(res);
      })
      .catch((error) => console.log(error));
  };

  const getInvoiceSettings = () => {
    Invoice.getInvoiceSetting()
      .then((res) => {
        if (res.invoiceSettings) {
          setInvoicePrefix(res.invoiceSettings.invoice_prefix);
          setInvoiceNumber(
            res.invoiceSettings.next_invoice_number.padStart(5, "0")
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const handleReminderModal = () => {
    Invoice.checkReminderStatus(id)
      .then((res) => {
        setIsFirstReminderDisabled(!!res);
      })
      .catch((error) => console.log(error));

    setShowReminderModal(!showReminderModal);
  };

  const handleClose = (isRefresh) => {
    if (isRefresh) {
      getReminders();
    }

    setShowReminderModal(!showReminderModal);
  };

  const updateCustomerDetails = (invoice) => {
    setCustomer({
      city: invoice.customer_city,
      country: invoice.customer_country,
      house_number: invoice.customer_house_number,
      house_number_suffix: invoice.customer_house_number_suffix,
      customer_id: invoice.customer_id,
      company_name: invoice.customer_name,
      phone_number: invoice.customer_phone_number,
      postcode: invoice.customer_postcode,
      street: invoice.customer_street,

    })
  };

  /**  request Api to update invoice payment info*/
  const updateInvoicePayment = (data) => {
    Invoice.updateInvoicePaymentInfo(data)
      .then((res) => {
        if (res.success === true) {
          setMessage({show: true, message: res.message, variant: "success"});
          if (res && res.invoice && res.invoice.status) {
            setStatus(res.invoice.status);
          }
          // getPaginatedInvoices();
          // window.location.href = "/invoices";
        } else if (res.success === false) {
          setMessage({show: true, message: res.message, variant: "warning"});
        }
        if (res.errors) {
          let array = [];
          for (const key in res.errors) {
            array.push({
              show: true,
              message: res.errors[key][0],
              variant: "danger",
            });
          }
          setErrors(array);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {message.show ? (
        <AlertDismissible
          textMessage={message.message}
          variant={message.variant}
        />
      ) : (
        ""
      )}
      <ReminderModal
        handleClose={(isRefresh) => handleClose(isRefresh)}
        invoiceId={id}
        key={id}
        show={showReminderModal}
        email={emailTo}
        isFirstReminderDisabled={isFirstReminderDisabled}
      />
      {errors &&
        errors.map((message, index) => {
          return (
            <AlertDismissible
              key={index}
              textMessage={message.message}
              variant="danger"
            />
          );
        })}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 w-100">
        <div className="d-block mb-4 mb-md-0 w-100">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/invoices">{t("invoices")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("view")}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center w-100">

            <div>
              <h4>{t("view invoice")}</h4>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() => Invoice.downloadInvoice(id, file_path)}
              >
                {t("Download")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Invoice Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
            <tr>
              <th>{t("Company")}</th>
              <td>{company.name}</td>
            </tr>

            <tr>
              <th>{t("Address")}</th>
              <td>
                {` ${company.street ? company.street : ""}
                    ${company.house_number ? company.house_number : ""}
                    ${company.house_number_suffix ? company.house_number_suffix : ""}`}
                <br/>
                {`${company.postcode ? company.postcode : ""}
                    ${company.city ? company.city : ""}
                    ${company.country ? company.country : ""}`}
              </td>
            </tr>

            <tr>
              <th>{t("Phone Number")}</th>
              <td>{company.phone_number}</td>
            </tr>
            <tr>
              <th>{t("invoice date")}</th>
              <td>
                {invoiceDate &&
                  moment
                    .tz(invoiceDate, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr>
              <th>{t("invoice number")}</th>
              <td>{invoicePrefix + invoiceNumber}</td>
            </tr>
            <tr>
              <th>{t("Bank Account")}</th>
              <td>{companySettings.bank_account}</td>
            </tr>
            <tr>
              <th>{t("Bank Account Holder")}</th>
              <td>{companySettings.bank_account_holder}</td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Customer Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
            <tr>
              <th>{t("Name")}</th>
              <td>
                <b>
                  <a href={`/customer-details/${customer.id}`}>
                    {customer.company_name}
                  </a>
                </b>
              </td>
            </tr>
            <tr>
              <th>{t("Address")}</th>
              <td>
                {`${customer.street || ""}
                    ${customer.house_number || ""}
                    ${customer.house_number_suffix || ""}
                    `}{" "}
                <br/>
                {`${customer.postcode || ""}
                    ${customer.city || ""}
                    ${customer.country || ""}`}
              </td>
            </tr>
            <tr>
              <th>{t("Phone Number")}</th>
              <td>{customer.phone_number || ""}</td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Contact Person Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {conactPersonDetails &&
            <Table bordered hover responsive="sm">
              <tbody>
              <tr>
                <th>{t("Name")}</th>
                <td>
                  <b>
                    {conactPersonDetails.contact_person_name}
                  </b>
                </td>
              </tr>
              <tr>
                <th>{t("Email")}</th>
                <td>
                  {conactPersonDetails.contact_person_email}
                </td>
              </tr>
              <tr>
                <th>{t("Phone Number")}</th>
                <td>{conactPersonDetails.contact_person_phone_number}</td>
              </tr>
              <tr>
                <th>{t("Sent by email")}</th>
                <td>
                  {emailTo ? `Yes (${emailTo})` : "No"}
                </td>
              </tr>
              </tbody>
            </Table>}
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-11">
              <h5>{t("Invoice Reminders")}</h5>
            </div>
            {paymentData &&
              (paymentData.status === "open" ||
                paymentData.status === "partially paid" ||
                paymentData.status === "overdue") && (
                <div className="col-md-1">
                  <Button variant="primary" onClick={handleReminderModal}>
                    <FontAwesomeIcon icon={faBell}/>
                  </Button>
                </div>
              )}
          </div>
        </Card.Header>
        <Card.Body>
          {reminders && reminders.length > 0 ? (
            reminders.map((reminder, index) => (
              <Table size="sm">
                <thead>
                <tr
                  style={{
                    fontSize: " 0.8em",
                    lineHeight: "0.8",
                    backgroundColor: "#78ad91",
                    color: "white",
                  }}
                >
                  <th className="text-center">{t("No")}</th>
                  <th className="text-center">{t("Email")}</th>
                  <th className="text-center">{t("Type")}</th>
                  <th className="text-center">{t("Date")}</th>
                  <th className="text-center">{t("Expiration Date")}</th>
                </tr>
                </thead>
                <tbody>
                <tr key={reminder.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{reminder.sent_to}</td>
                  <td className="text-center text-capitalize">
                    {reminder.reminder_type}
                  </td>
                  <td className="text-center">
                    {reminder.date &&
                      moment
                        .tz(reminder.date, "Europe/Amsterdam")
                        .format("DD-MM-YYYY")}
                  </td>
                  <td className="text-center">
                    {reminder.expiration_date &&
                      moment
                        .tz(reminder.expiration_date, "Europe/Amsterdam")
                        .format("DD-MM-YYYY")}
                  </td>
                </tr>
                </tbody>
              </Table>
            ))
          ) : (
            <div className="text-center">{t("No Reminders found")}</div>
          )}
        </Card.Body>
      </Card>
      {/* here */}
      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Invoice Records")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table size="sm">
            <thead>
            <tr
              style={{
                fontSize: " 0.8em",
                lineHeight: "0.8",
                backgroundColor: "#78ad91",
                color: "white",
              }}
            >
              <th className="text-center">SL#</th>
              <th className="text-center">{t("Description")}</th>
              <th className="text-center">{t("Quantity")}</th>
              <th className="text-center">{t("unit price")}</th>
              <th className="text-center">{t("Vat Percentage")}</th>
              <th className="text-center">{t("Total")}</th>
            </tr>
            </thead>
            <tbody>
            {invoiceLinesData &&
              invoiceLinesData.map((invoiceLine, index) => {
                return (
                  <tr key={invoiceLine.id}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{invoiceLine.description}</td>
                    <td className="text-center">{invoiceLine.quantity}</td>
                    <td className="text-center">
                      {numberFormatter.format(invoiceLine.price / 100)}
                    </td>
                    <td className="text-center">
                      {invoiceLine.vat_percentage === 0 || invoiceLine.vat_percentage === 1 || invoiceLine.vat_percentage === 2
                        ? `0%`
                        : `${invoiceLine.vat_percentage}%`}
                    </td>
                    <td className="text-center">
                      {numberFormatter.format(
                        (invoiceLine.quantity * invoiceLine.price / 100)
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Table bordered hover responsive="sm" className="mt-3">
            <tbody>
            <tr>
              <th>{t("Total excl. VAT")}</th>
              <td>{numberFormatter.format(totalAmountExclVat / 100)}</td>
            </tr>

            <tr>
              <th>{t("Total VAT")}</th>
              <td>{numberFormatter.format(vatPrice / 100)}</td>
            </tr>

            <tr>
              <th>{t("Total incl. VAT")}</th>
              <td> {numberFormatter.format(totalAmount / 100)}</td>
            </tr>
            <tr>
              <th>{t("invoice date")}</th>
              <td>
                {invoiceDate &&
                  moment
                    .tz(invoiceDate, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
              </td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* disabled timeline as it didn't really make sense */}
      {false && timeline && timeline.length > 0 && (
        <Card className="mt-5">
          <Card.Header>
            <div className="row">
              <div className="col-md-8">
                <h5>{t("Timeline")}</h5>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table size="sm">
              <thead>
              <tr
                style={{
                  fontSize: " 0.8em",
                  lineHeight: "0.8",
                  backgroundColor: "#78ad91",
                  color: "white",
                }}
              >
                <th className="text-center">SL#</th>
                <th className="text-center">{t("Amount")}</th>
                <th className="text-center">{t("Payment Date")}</th>
              </tr>
              </thead>
              <tbody>
              {timeline &&
                timeline.map((invoice, index) => {
                  return (
                    <tr key={invoice.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        {" "}
                        {numberFormatter.format(invoice.amount / 100)}
                      </td>
                      <td className="text-center">
                        {moment
                          .tz(invoice.payment_date, "Europe/Amsterdam")
                          .format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      {paymentData && (
        <Card className="mt-5">
          <Card.Body>
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <h6 className="fw-bolder">{t("Expiry Date")}</h6>
                  <p>{paymentData.expire_date}</p>
                </Col>
                <Col xs={12} md={6}>
                  <h6 className="fw-bolder">{t("Invoice Total")}</h6>
                  <p>
                    {formatCurrency(
                      paymentData.total / 100,
                      "nl-NL",
                      "currency",
                      "EUR"
                    )}
                  </p>
                </Col>
                {partialPaymentsEnabled && (
                  <Col xs={12} md={6}>
                    <h6 className="fw-bolder">{t("Already Paid")}</h6>
                    <p>
                      {formatCurrency(
                        paymentData.total_paid / 100,
                        "nl-NL",
                        "currency",
                        "EUR"
                      )}
                    </p>
                  </Col>)}
                <Col xs={12} md={6}>
                  <h6 className="fw-bolder">{t("Status")}</h6>
                  <p className="text-capitalize">
                    <StatusBullet status={status}/>
                    &nbsp;{t('invoice_status_' + status)}
                  </p>
                </Col>
              </Row>
              <Formik
                validationSchema={partialPaymentSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                initialValues={{
                  total_amount: totalAmount,
                  paid_amount: paymentData?.total_paid || 0,
                  paid_option: status
                }}
              >
                {({handleSubmit, values}) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <h5 className="fw-bolder">{t("Adjust Status")}</h5>
                      <Col>
                        <Form.Group controlId="validationCustom01" className="mb-3">
                          <Form.Label>{t("New Status")}</Form.Label>
                          <Field as="select" name="paid_option" className="form-control">
                            <option value={null} >{t("Select a Status")} </option>
                            <option value="open">{t("Open")}</option>
                            {partialPaymentsEnabled && <option value="partially_paid">{t("Partially Paid")}</option>}
                            <option value="paid">{t("Completely paid")}</option>
                          </Field>
                        </Form.Group>
                        {values.paid_option === "partially_paid" && (
                          <Form.Group className="mb-3">
                            <Form.Label>{t("Partially Paid Amount")} ({t('Max.')} {formatCurrency(
                              totalAmount / 100,
                              "nl-NL",
                              "currency",
                              "EUR"
                            )} {t('incl. VAT')})</Form.Label>
                            <PriceField name="paid_amount" />
                            <ErrorMessage className="text-warning" component="div" name={'paid_amount'}></ErrorMessage>
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-3">
                      <Button type="submit" variant="primary">
                        {t("Save Changes")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Container>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default ViewInvoice;
