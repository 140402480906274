import React, {useEffect} from "react";
import Subscription from '../libs/Subscription';
import SubscriptionDetail from './SubscriptionDetail';
import {Container} from "react-bootstrap";
import Auth from "../../auth/libs/Auth";
import {startLoading, stopLoading} from '../../store/actions/loading';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getSubscriptions} from "../../store/actions/subscriptions";
import {selectUser} from "../../store/reducers/userCompany";

function Subscriptions() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const companyId = Auth.getActiveCompanyId();
  const activeUser = useSelector(selectUser);
  const subscriptions = useSelector(state => state.subscriptions.subscriptions.subscriptions) || [];

  useEffect(() => {
    dispatch(getSubscriptions())
    // eslint-disable-next-line
  }, []);

  const handleClickOnSubscription = (subscriptionId) => {
    dispatch(startLoading());// show loading
    Subscription.makeFirstPayment(subscriptionId, companyId)
      .then((res) => {
        dispatch(stopLoading());
        window.location.href = res.checkout_url;
      }).catch(error => {
      console.log(error);
      dispatch(stopLoading());
    });
  }

  const logout = () => {
    if (activeUser !== null) {
      Auth.logout()
        .then(response => {
          window.location.href = "/";
        }).catch((error) => {
        if (error.response.status === 401) {
          Auth.clearData();
          window.location.href = "/";
        } else {
          console.log(error);
        }
      });
    } else {
      window.location.href = "/";
    }
  };

  document.body.classList.add("bg-gradient-primary");
  return (
    <Container className="full-height-container">
      <div className="col-md-12 card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-0">
          {(typeof activeUser === 'undefined' || !companyId) ? (
            <p
              className="pt-4 p-1 text-center">{t("You are logged in with an account that does not have an active subscription, choose a subscription or")}
              <button className="btn btn-primary btn-sm" onClick={(e) => logout()}>Logout</button>
              .</p>
          ) : (
            <div>
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <h4 className="p-5 pb-2 text-center">{t("Choose a Subscription Plan")}</h4>
                </div>
              </div>
              <div className="row">
                {subscriptions && subscriptions.map((subscription, index) => {
                  return <SubscriptionDetail key={index} subscription={subscription}
                                             handleSubscriptionClick={handleClickOnSubscription}/>
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Subscriptions;