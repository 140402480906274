import Api from "../../libs/Api";
import {GET_SUBSCRIPTIONS_FAILED, GET_SUBSCRIPTIONS_START, GET_SUBSCRIPTIONS_SUCCESS} from "./actionTypes";

export const getSubscriptions = () => {

  return async (dispatch) => {
    dispatch({
      type: GET_SUBSCRIPTIONS_START,
    });

    try {

      const response = await Api.get(`/api/subscriptions`);
      dispatch({
        payload: response.data,
        type: GET_SUBSCRIPTIONS_SUCCESS,
      });

    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_SUBSCRIPTIONS_FAILED,
      });
    }
  };
};
