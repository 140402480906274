import React from 'react';
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";
import {useTranslation} from 'react-i18next';

function ThankyouForRegistering() {
  document.body.classList.add('bg-gradient-primary');
  const {t} = useTranslation();

  return (
    <Container className="full-height-container ">
      <>
        <div className="col-md-12 card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row justify-content-center">

              <div className="col-lg-7">
                <div className="p-5">
                  <div className="d-flex justify-content-center thanksText">
                    <p className="h1 my-auto">{t("Thank you for registering")}!</p>
                  </div>
                  <div className="row mt-5 justify-content-center">
                    <div className="col-sm-5 mb-3 mb-sm-0 ">
                      <Link to="/first-invoice-wizard">
                        <button className="btn btn-primary btn-user btn-block p-1 py-2 next-button">
                          {t("Create first invoice")}
                        </button>
                      </Link>
                    </div>
                    <div className="col-sm-5 ml-auto ">
                      <Link to="/choose-subscription">
                        <button className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button">
                          {t("Skip this step")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Container>
  );
}

export default ThankyouForRegistering;
