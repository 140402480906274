import React from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {formatCurrency} from '../../../utils/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/** Configure Chart Setting */
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Turnover invoices bar chart',
      padding: {
        top: 10,
        bottom: 30
      },
      font: {
        weight: 'bold',
        size: 30
      }
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${formatCurrency(context.parsed.y / 100, 'nl-NL', 'currency', 'EUR')}`;
          }
          return label;
        }
      }
    }
  },
  scales: {
    y: {
      ticks: {
        // Include a euro sign in the ticks
        callback: function (value, index, ticks) {
          return formatCurrency(value / 100, 'nl-NL', 'currency', 'EUR');
        }
      },
      stacked: true
    },
    x: {
      stacked: true
    }
  }
};
export default function InvoiceChart({data}) {
  return <Bar options={options} data={data}/>;
}
