export const formatCurrency = (data) => {
  let finalData = "";
  if (data.length) {
    if (data.length > 0) {
      const Array = data.includes(".") ? data.split(".") : data.split(",");
      var data1 = Array[0];
      if (Array.length > 1) {
        var data2 = Array[1].slice(0, 2);
        if (data2.length === 1) {
          data2 = data2.concat("0");
        }
      }
      if (Array.length === 1 || Array[1] === "") {
        data2 = "00";
      }
    }
    finalData = "€".concat(data1).concat(",").concat(data2);
  }
  return finalData;
};
