import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Alert} from "@themesberg/react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Card} from "react-bootstrap";
import '../style.css';
import {schema} from "../../validation";

function RegistrationStep3({
                             firstName,
                             lastName,
                             email,
                             password,
                             prefix,
                             setFirstName,
                             setLastName,
                             setEmail,
                             setPassword,
                             setPrefix,
                             submitData,
                             statusMessage,
                             setStep
                           }) {
  const {t} = useTranslation();

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error] = useState(null);
  const [show, setShow] = useState(false);

  const {register, handleSubmit, setValue, formState: {errors, isSubmitting}} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = () => {
    submitData();
    // isSubmitting(true)
  }

  return (
    <>
      <div className="col-md-8 offset-md-2 card o-hidden border-0 shadow-lg">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">{t("User Details")}</h1>
                </div>
                {show && <div><Alert variant="danger" onClose={() => setShow(false)} dismissible>
                  <p className="m-0">{error}</p>
                </Alert>
                </div>}

                {statusMessage.length > 0 && (
                  <div><Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    {statusMessage.map(message => <p className="m-0">{message}</p>)}
                  </Alert>
                  </div>
                )}
                <form className="user" onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("First Name")}</label>
                      <input
                        {...register("first_name")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setFirstName(e.target.value)
                          setValue('first_name', e.target.value)
                        }}
                        value={firstName}
                        required
                      />
                      <p className="validation-error">{errors.first_name?.message}</p>
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("Prefix")}</label>
                      <input
                        {...register("prefix")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setPrefix(e.target.value);
                          setValue('prefix', e.target.value)
                        }}
                        value={prefix}
                      />
                      <p className="validation-error">{errors.prefix?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6">
                      <label>{t("Last Name")}</label>
                      <input
                        {...register("last_name")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setValue('last_name', e.target.value)
                        }}
                        value={lastName}
                        required
                      />
                      <p className="validation-error">{errors.last_name?.message}</p>
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>Email</label>
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setEmail(e.target.value)
                          setValue('email', e.target.value)
                        }}
                        value={email}
                        required
                      />
                      <p className="validation-error">{errors.email?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">

                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("Password")}</label>
                      <input
                        {...register("password")}
                        type="password"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setPassword(e.target.value)
                          setValue('password', e.target.value)
                        }}
                        value={password}
                        required
                      />
                      <p className="validation-error">{errors.password?.message}</p>
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("Confirm Password")}</label>
                      <input
                        {...register("confirm_password")}
                        type="password"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                          setValue('confirm_password', e.target.value)
                        }}
                        value={confirmPassword}
                        required
                      />
                      <p className="validation-error">{errors.confirm_password?.message}</p>
                    </div>
                  </div>
                  <div className="form-group justify-content-center row">
                    <div className="col-sm-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button" onClick={() => {
                        setStep(2);
                      }}
                      >
                        {t("Previous")}
                      </button>
                    </div>
                    <div className="col-sm-3 ml-auto mt-2 mt-md-0">
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block p-1 py-2 next-button"
                        disabled={isSubmitting}
                      >
                        {t("Next")}
                      </button>
                    </div>
                  </div>
                </form>
                <hr/>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    {t("Already have an account")}?
                    <Card.Link as={Link} to="/" className="fw-bold">
                      {t(" Login here")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationStep3;
