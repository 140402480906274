import {faEllipsisH, faEye, faHome, faPlus, faTrashAlt,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import CustomSpinner from "../../components/CustomSpinner";
import ResellerAction from "../libs/ResellerAction";
import {Breadcrumb, Button, ButtonGroup, Card, Dropdown, Table} from "@themesberg/react-bootstrap";
import {OldPagination} from "../../components/old/OldPagination";
import AlertDismissible from "../../components/old/AlertDismissible";

export default function ResellerUser({id}) {
  const {t} = useTranslation();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: ""
  });

  const deleteResellerUser = (userId) => {
    ResellerAction.deleteResellerUser(userId)

      .then((res) => {
        console.log(res, "res");
        // if (res.success === true) {
        //   setMessage({ show: true, message: res.message, variant: "success" });
        // // getPaginatedResellerUsers();
        // } else if (res.success === false) {
        //   setMessage({ show: true, message: res.message, variant: "danger" });
        // }

      })
      .catch((e) => console.log(e))
      .finally((e) => console.log(e));
  };

  useEffect(() => {
    getPaginatedResellerUsers();

    // eslint-disable-next-line
  }, []);

  const getPaginatedResellerUsers = (page = null) => {
    setLoading(true);
    ResellerAction.getPaginatedResellerUsers(page)
      .then(res => {
        setLoading(false);
        if (res.users) {
          setUsers(res.users.data);
          setPaginationData({
              links: res.users.links,
              prevPageUrl: res.users.prev_page_url,
              nextPageUrl: res.users.next_page_url,
              showingPerPage: res.users.to,
              total: res.users.total
            }
          );
        }
      }).catch((error) => {
      setLoading(false);
    })
      .catch((error) => console.log(error));
  }

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">{props.index + 1}</span>
        </td>
        <td>
          <span className="fw-normal">{props.first_name}</span>
        </td>
        <td>
          <span className="fw-normal">{props.last_name}</span>
        </td>
        <td>
          <span className="fw-normal">{props.email}</span>
        </td>
        <td>
          <span className="fw-normal">{props.status}</span>
        </td>
        <td>
          <span className="fw-normal">{props.companies[0]?.name}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              drop="down"
              className="text-dark m-0 p-0"
              id="dropdown-split-basic"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown">
              <>
                <Dropdown.Item onClick={() => history.push(`/reseller/user-details/${props.id}`)}>
                  <FontAwesomeIcon icon={faEye} className="me-2"/>
                  {t("View user")}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        t("Are you sure you want to delete this user?")
                      )
                    )
                      deleteResellerUser(props.id);
                    console.log(props.id, "props.id");
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2"/>
                  {t("remove")}
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <div className=" py-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div>
          <Breadcrumb
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Users")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Users")}</h4>
          <p>{t("Show your Users")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Link
              to="/reseller/create-user"
              className="btn btn-outline-primary btn-sm mx-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2 "/> {t("new")}
            </Link>
          </ButtonGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant}/> : ""}
        </div>
      </div>
      {loading ? (
        <CustomSpinner/>
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0 pb-5">
            <Table hover className="user-table align-items-center">
              <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">{t("First Name")}</th>
                <th className="border-bottom">{t("Last Name")}</th>
                <th className="border-bottom">{t("Email")}</th>
                <th className="border-bottom">{t("status")}</th>
                <th className="border-bottom">{t("Company")}</th>
              </tr>
              </thead>
              <tbody>
              {users &&
                users.map((user, index) => (
                  <TableRow key={index} index={index} {...user} />
                ))}
              </tbody>
            </Table>

            <OldPagination action={ResellerAction} items={users} paginationData={paginationData}
                           getPaginated={getPaginatedResellerUsers}/>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
