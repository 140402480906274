import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Row} from "@themesberg/react-bootstrap";
import {Link} from "react-router-dom";
import BgImage from "../../assets/img/illustrations/signin.svg";
import {useTranslation} from "react-i18next";
import AutoCompleteCompanyField from "../../components/inputs/AutoCompleteCompanyField";
import Form from "react-bootstrap/Form";

const RegistrationStep1 = ({setStep, setCompanyId, setName}) => {
  const {t} = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCompany) {
      setName(selectedCompany?.label);
      setStep(2);
    }
  };

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    setCompanyId(company.value)
  };

  return (
    <section>
      <p className="text-center">
        <Card.Link as={Link} to="/" className="text-gray-700">
          <FontAwesomeIcon icon={faAngleLeft} className="me-2"/> {t("Back to homepage")}
        </Card.Link>
      </p>
      <Row
        className="justify-content-center form-bg-image"
        style={{backgroundImage: `url(${BgImage})`}}
      >
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <div className="text-center text-md-center mb-4 mt-md-0">
              <h1 className="h4 text-gray-900 mb-4">{t("Company Name")}</h1>
            </div>
            <Form className="mt-4" onSubmit={handleSubmit}>
              <Form.Group id="company" className="mb-4">
                <Form.Label>{t("Your Company Name")}/CoC {t("number")}</Form.Label>
                <AutoCompleteCompanyField
                  // onAutocomplete={handleAutoComplete}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                />
              </Form.Group>
              <Button
                disabled={!selectedCompany}
                variant="primary"
                type="submit"
                className="w-100"
              >
                {t("Next")}
              </Button>
            </Form>
            <div className="mt-3 mb-4 text-center">
              <span className="fw-normal">{t("or")}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  {t("Already have an account")}?
                  <Card.Link as={Link} to="/" className="fw-bold">
                    {t(" Login here")}
                  </Card.Link>
                </span>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default RegistrationStep1;