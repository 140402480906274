import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Container, Form, InputGroup, Row, Spinner,} from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BgImage from "../../assets/img/illustrations/signin.svg";

import {FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Auth from "../libs/Auth";
import ErrorNotice from "./ErrorNotice";
import {useDispatch} from "react-redux";
import {fetchUser} from "../../store/actions/userCompany";

const Login = () => {
  const {t} = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let isPersistence = localStorage.getItem("persistence");
    setRememberMe(isPersistence === "true");
  }, []);

  document.body.classList.add("bg-gradient-primary");

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    Auth.login(email, password)
      .then(async () => {
        try {
          dispatch(fetchUser());
        } catch (error) {
          console.log(error);
        }
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setErrors([t("Login unsuccessful")]);
        setLoading(false);
      });
  };

  const handleRememberMe = (event) => {
    let isChecked = event.target.checked;
    setRememberMe(isChecked);
    localStorage.setItem("persistence", isChecked);
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {errors && <ErrorNotice errors={errors}/>}
          <Row
            className="justify-content-center form-bg-image"
            style={{backgroundImage: `url(${BgImage})`}}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Log in</h3>
                </div>
                <Form className="mt-4" onSubmit={login}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>{t("Your Email")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope}/>
                      </InputGroup.Text>
                      <Form.Control
                        disabled={loading}
                        autoFocus
                        onChange={changeEmail}
                        required
                        type="email"
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>{t("Your Password")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt}/>
                        </InputGroup.Text>
                        <Form.Control
                          disabled={loading}
                          onChange={changePassword}
                          required
                          type="password"
                          placeholder={t("Password")}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input
                          id="defaultCheck5"
                          className="me-2"
                          checked={rememberMe || false}
                          onChange={handleRememberMe}
                        />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          {t("Remember me")}
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link
                        as={Link}
                        to="/forgot-password"
                        className="small text-end"
                      >
                        {t("Forgot password")}?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    {loading ? (
                      <Spinner size="lg" animation="border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </Spinner>
                    ) : (
                      "Log in"
                    )}
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    {t("Not registered")}?
                    <Card.Link as={Link} to="/register" className="fw-bold">
                      {' ' + t("Create account")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Login;