import React, {useState} from "react";
import {Container} from "@themesberg/react-bootstrap";
import Auth from "../libs/Auth";
import RegistrationForm from "./RegistrationForm";
import {useHistory} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {startLoading, stopLoading} from '../../store/actions/loading';
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import {activeCompanyFetched, activeUserFetched} from "../../store/reducers/userCompany";

function Register() {
  document.body.classList.add('bg-gradient-primary');
  const {t} = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [coCNo, setCoCNo] = useState("");
  const [name, setName] = useState("");
  const [vat, setVat] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");
  const [email, setEmail] = useState("")
  const [street, setStreet] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [hNoSuffix, setHNoSuffix] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(t("Netherlands"));
  const [pNo, setPNo] = useState("");
  const [website, setWebsite] = useState("");
  const [prefix, setPrefix] = useState("");
  const [companyId, setCompanyId] = useState("");
  const dispatch = useDispatch();
  const [message, setMessage] = useState({});
  const [statusMessage, setStatusMessage] = useState([]);

  let history = useHistory();

  const submitData = () => {
    localStorage.setItem("company_name", name)
    localStorage.setItem("company_phone_number", pNo)
    localStorage.setItem("company_email", companyEmail)
    localStorage.setItem("company_website_url", website)
    localStorage.setItem("company_kvk_number", coCNo)
    localStorage.setItem("company_vat_number", vat)
    localStorage.setItem("company_street", street)
    localStorage.setItem("company_house_number", houseNo)
    localStorage.setItem("company_house_number_suffix", hNoSuffix)
    localStorage.setItem("company_country", country)
    localStorage.setItem("company_city", city)
    localStorage.setItem("company_postcode", postCode)

    const data = {
      "user": {
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "prefix": prefix,
        "password": password,
        "password_confirmation": password
      },

      "company": {
        "name": name,
        "coc_number": coCNo,
        "vat_number": vat,
        "street": street,
        "house_number": houseNo,
        "house_number_suffix": hNoSuffix,
        "postcode": postCode,
        "city": city,
        "country": country,
        "email": companyEmail,
        "website_url": website,
        "phone_number": pNo,
        "status": "pending"
      }
    }
    dispatch(startLoading());// show loading
    Auth.register(data)
      .then((res) => {
        if (res.success === true) {
          dispatch(stopLoading());// hide loading
          setMessage({show: true, message: res.message});
          dispatch(activeUserFetched(res.user));
          dispatch(activeCompanyFetched(res.company));
          history.push("/thank-you-register");
        }

        if (res.errors) {
          dispatch(stopLoading());// hide loading
          let array = [];
          for (const key in res.errors) {
            array.push({show: true, message: res.errors[key][0]});
          }
          setErrors(array);
        }
      })
      .catch((errors) => {
        dispatch(stopLoading());// hide loading
        setStatusMessage(Object.values(errors).map(errorArray => errorArray[0]));
      });
  };

  const registrationFormProps = {
    firstName,
    lastName,
    email,
    password,
    prefix,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    errors,
    submitData,
    setCoCNo,
    setcompanyEmail,
    setName,
    setVat,
    setStreet,
    setHouseNo,
    setHNoSuffix,
    setPostCode,
    setCity,
    setCountry,
    setPNo,
    setWebsite,
    setPrefix,
    country,
    name,
    city,
    coCNo,
    vat,
    postCode,
    street,
    houseNo,
    hNoSuffix,
    companyId,
    pNo,
    website,
    companyEmail,
    setCompanyId,
    statusMessage
  };

  return (
    <Container className="full-height-container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {message.show ? <AlertDismissible type="message" textMessage={message.message}/> : ""}
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible key={index} textMessage={message.message} variant="danger"/>
              )
            })
          }
        </div>
      </div>
      <RegistrationForm {...registrationFormProps}  />
    </Container>
  );
}

export default Register;