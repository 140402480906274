import React, {useEffect, useState} from "react";
import YukiLogo from "../logos/logo-yuki.png";
import Image from "react-bootstrap/Image";
import {Button, Form, FormControl, InputGroup} from "react-bootstrap";
import Company from "../../companies/libs/Company";
import {useTranslation} from "react-i18next";

function Yuki() {
  const [apiKey, setApiKey] = useState();
  // const [name, setName] = useState("yuki");
  let name = "yuki";
  const [isInvalid, setIsInvalid] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (apiKey) {
      setIsInvalid(false);
    } else setIsInvalid(true);
  }, [apiKey]);

  useEffect(() => {
    getCompanyYukiSetting();
  }, []);

  const createYukiSetting = () => {
    const yukiData = {
      name: name,
      api_key: apiKey,
    };

    Company.createCompanyYukiSetting(yukiData).then(() => {
      getCompanyYukiSetting();
    });
  };

  const getCompanyYukiSetting = () => {
    Company.getCompanyYukiSetting().then((data) => {
      if (data.yukiConfigs) {
        setApiKey(data.yukiConfigs.api_key);
      }
    });
  };

  return (
    <div className="row mt-75 align-items-baseline">
      <div className="col-6">
        <Image
          fluid
          style={{height: "90px", objectFit: "contain"}}
          src={YukiLogo}
        />{" "}
      </div>
      {" "}
      <div className="col-6">
        <Form>
          <InputGroup className="mb-3" hasvalidation="true">
            <InputGroup.Prepend>
              <Button
                onClick={() => {
                  if (
                    window.confirm(
                      t("You are now using ") + name + t(" to store invoices.")
                    )
                  )
                    createYukiSetting();
                }}
              >
                {t("Save")}
              </Button>
            </InputGroup.Prepend>
            <FormControl
              placeholder={t("enter API key")}
              onChange={(e) => setApiKey(e.target.value)}
              required
              isInvalid={isInvalid}
              value={apiKey}
            />
          </InputGroup>
        </Form>
      </div>
    </div>
  );
}

export default Yuki;
