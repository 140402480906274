import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Button, Card, Col, Container, Form, FormCheck, InputGroup, Row, Spinner,} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import BgImage from "../../assets/img/illustrations/signin.svg";
import ErrorNotice from "../../auth/components/ErrorNotice";
import Reseller from "../libs/ResellerAuth";

export default function ResellerLogin() {
  const {t} = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const history = useHistory();

  const login = (e) => {
    // e.preventDefault();
    setLoading(true);
    Reseller.login(email, password)
      .then(() => {
        setLoading(false);
        history.push("/");
        // window.location.reload();
      })
      .catch(() => {
        setErrors([t('Login unsuccessful')]);
        setLoading(false);
      });
  };


  const handleRememberMe = (event) => {
    let Ischecked = event.target.checked;
    setRememberMe(Ischecked);
    localStorage.setItem("reseller", Ischecked);
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };


  return (
    <section className="d-flex align-items-center bg-dark h-100">
      <Container>
        {errors && <ErrorNotice className="mb-5" errors={errors}/>}
        <Row
          className="justify-content-center form-bg-image"
          style={{backgroundImage: `url(${BgImage})`}}
        >
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h3 className="mb-0">{t("Reseller Log in")}</h3>
              </div>
              <Form className="mt-4" onSubmit={login}>
                <Form.Group id="email" className="mb-4">
                  <Form.Label>{t("Your Email")}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope}/>
                    </InputGroup.Text>
                    <Form.Control
                      disabled={loading}
                      autoFocus
                      onChange={changeEmail}
                      required
                      type="email"
                      placeholder="example@company.com"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>{t("Your Password")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                      </InputGroup.Text>
                      <Form.Control
                        disabled={loading}
                        onChange={changePassword}
                        required
                        type="password"
                        placeholder={t("Password")}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Form.Check type="checkbox">
                      <FormCheck.Input
                        id="defaultCheck5"
                        className="me-2"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                      />
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                        {t("Remember me")}
                      </FormCheck.Label>
                    </Form.Check>
                    <Card.Link
                      className="small text-end"
                    >
                      {t("Forgot password")}?
                    </Card.Link>
                  </div>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  {loading ? (
                    <Spinner size="lg" animation="border" role="status">
                      <span className="visually-hidden">{t("Loading")}...</span>
                    </Spinner>
                  ) : (
                    "Log in"
                  )}
                </Button>
              </Form>

              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  {t("Not registered")}?
                  <Card.Link className="fw-bold">
                   {t("Create account")}
                  </Card.Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
