import React from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import OffersActions from "../libs/Offers";

export default function ConfirmAcceptOffer() {
  const appUrl = process.env.REACT_APP_FACTUURSNEL_URL;
  const {t} = useTranslation();
  const {token} = useParams();

  const confirmAcceptOffer = () => {
    OffersActions.confirmAcceptOffer(token)
      .then((res) => {
        alert(t("Your offer has been accepted"))
        window.location = appUrl
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="d-flex  flex-column align-items-center mt-5 fs-1"
    >
      <h4>{t("Send email for confirm offer")}! </h4>
      <div className="mt-5">
        <Button variant="info" className="mx-3"
                onClick={() => confirmAcceptOffer()}
        >{t('Send Confirm offer mail')}</Button>
      </div>
    </div>
  );
}
