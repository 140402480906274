// Global Variables
let config = {
  trackGa: process.env.REACT_APP_GA || false,
  hotJar: process.env.REACT_APP_HOTJAR || false,
  trackGTagManager: process.env.REACT_APP_GTAG_MANAGER || false,
  trackFbAds: process.env.REACT_APP_FB_ADS || false,
};


export {config};
