import {
  GET_TEMPLATES_FAILED,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  templates: [],
  success: false,
  isLoading: false,
  error: "",
};
const templates = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_TEMPLATES_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const selectTemplates = (state) => state.templates.templates;

export default templates;
  