import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import StatusBullet from "../../invoices/components/utils/StatusBullet";
import Invoice from "../../invoices/libs/Invoice";
import moment from 'moment-timezone';
import {useHistory} from "react-router-dom";

const SearchResult = ({search}) => {
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    Invoice.searchInvoiceAndCustomers(search)
      .then(res => {
        if (res) {
          if (res.invoices && res.invoices.length > 0) {
            setInvoices(res.invoices);
          }
          if (res.customers && res.customers.length > 0) {
            setCustomers(res.customers);
          }
        }

      }).catch(error => console.log(error));

  }, [search])


  const CustomersRow = (props) => {
    const suffix = props.house_number_suffix ? ' ' + props.house_number_suffix : "";
    return (
      <tr style={{cursor: 'pointer'}} onClick={() => history.push(`/customer-details/${props.id}`)}>
        <td>
          <span className="fw-normal">
            {props.index + 1}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.company_name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.phone_number}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.street + ' ' + props.house_number + suffix + ', ' + props.city + ' ' + props.country}
          </span>
        </td>
      </tr>
    );
  };

  const InvoiceRow = (props) => {
    const suffix = props.customer_house_number_suffix ? ' ' + props.customer_house_number_suffix : "";
    const status = props.status.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
    return (
      <tr style={{cursor: 'pointer'}} onClick={() => history.push(`/view-invoice/${props.id}`)}>
        <td>
            <span className="fw-normal">
            {props.index + 1}
            </span>
        </td>
        <td>
          <span className="fw-normal">
            {
              moment
                .tz(props.invoice_date, "Europe/Amsterdam")
                .format("DD-MM-YYYY")
            }
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.invoice_number}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.customer_name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
          {props.customer_street + ' ' + props.customer_house_number + suffix + ', ' + props.customer_city + ' ' + props.customer_country}
          </span>
        </td>
        <td>
          <span className="fw-normal">
              <StatusBullet status={props.status}/>
            &nbsp;&nbsp;
            {
              status === 'Published' ? "Open" : status
            }
          </span>
        </td>

      </tr>
    );
  };


  return (
    <>
      <div>
        <h2>Invoices</h2>
        {invoices && invoices.length > 0 ? <div className="mt-2">
          <Table hover className="user-table align-items-center bg-white">
            <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Invoice Date</th>
              <th className="border-bottom">Invoice Number</th>
              <th className="border-bottom">Customer Name</th>
              <th className="border-bottom">Customer Address</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
            </thead>
            <tbody>
            {invoices && invoices.map((invoice, index) => <InvoiceRow key={`contract-${index}`}
                                                                      index={index} {...invoice}  />)}
            </tbody>
          </Table>
        </div> : <div className="text-center"> Invoices are not available. </div>}
        <div className="mt-2">
          <h2>Customers</h2>
          {customers && customers.length > 0 ? <Table hover className="user-table align-items-center bg-white">
            <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Company Name</th>
              <th className="border-bottom">Phone Number</th>
              <th className="border-bottom">Address</th>
            </tr>
            </thead>
            <tbody>
            {customers && customers.map((customer, index) => <CustomersRow key={`customer-${index}`}
                                                                           index={index} {...customer} />)}
            </tbody>
          </Table> : <div className="text-center"> Customers are not available. </div>}
        </div>
      </div>
    </>
  );
}

export default SearchResult;


