import Api from "../../libs/Api";
import {
  GET_TEMPLATES_FAILED,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
} from "./actionTypes";

export const templatesFetchSuccess = (templates) => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    payload: templates,
  };
}

export const termplateFetchFailed = (error) => {
  return {
    type: GET_TEMPLATES_FAILED,
    payload: error,
  };
}

export const fetchTemplates = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_TEMPLATES_START,
    });

    try {
      const response = await Api.get(`/api/templates/all`);
      if (response.data.templates) {
        dispatch(templatesFetchSuccess(response.data.templates));
      }
    } catch (e) {
      console.log(e.response);
      dispatch(termplateFetchFailed(e.response.data.message));
    }
  };
};
