import React, {useEffect, useState} from "react";
import {Alert, Container} from "react-bootstrap";
import Auth from "../libs/Auth";

import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {useTranslation} from "react-i18next";

function Activate({location, history}) {
  document.body.classList.add('bg-gradient-primary');
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const hash = pathParts[2];
    const id = pathParts[3];

    dispatch(startLoading());

    Auth.confirm(id, hash)
      .then(() => setActivated(true))
      .catch(() => {
        setActivated(false);
        stopLoading();
      })
      .finally(() => dispatch(stopLoading()));

    if (activated) {
      setTimeout(() => history.push('/'), 5000);
    }
  });

  const ActivatedNotice = () => (
    <Alert variant="success" className="mt-5">
      {t("Activation successful, you can log in")}
    </Alert>
  );

  const NotActivatedNotice = () => (
    <Alert variant="danger" className="mt-5">
      {t("Activation failed or account already activated")}
    </Alert>
  );

  return (
    <Container>
      {activated ? <ActivatedNotice/> : <NotActivatedNotice/>}
    </Container>
  );
}

export default Activate;