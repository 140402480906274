import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  companySettings: {},
  success: false,
  isLoading: false,
  error: "",
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    companySettingsFetchingStarted: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.success = false;
    },
    companySettingsFetched: (state, action) => {
      state.companySettings = action.payload;
      state.success = true;
      state.isLoading = false;
      state.error = "";
    },
    companySettingsFetchingFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    }
  }
})

export const selectCompanySettings = (state) => state.settings?.companySettings?.companySetting;

export const {companySettingsFetched, companySettingsFetchingFailed, companySettingsFetchingStarted} = settings.actions

export default settings.reducer;
