import {Field, Formik} from "formik";
import moment from "moment-timezone";
import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {firstInvoiceStep3Schema} from "../../validation";

function CreateFirstInvoiceStep3({setStep, handleCallbackStepThree}) {
  const {t} = useTranslation();

  let persistentData = localStorage.getItem("FormData")
    ? JSON.parse(localStorage.getItem("FormData"))
    : {};

  const [invoicePrefix, setInvoicePrefix] = useState(persistentData.invoicePrefix ? persistentData.invoicePrefix : "INV");
  const [invoiceNumber, setInvoiceNumber] = useState(persistentData.invoiceNumber ? persistentData.invoiceNumber : "1");
  const [invoiceDescription, setInvoiceDescription] = useState(persistentData.invoiceDescription);
  const [invoiceDate, setInvoiceDate] = useState(persistentData.invoiceDate ? new Date(persistentData.invoiceDate) : new Date());
  const [paddedInoviceNumber, setPaddedInvoiceNumber] = useState(
    persistentData.paddedInoviceNumber
      ? persistentData.paddedInoviceNumber
      : "INV00001"
  );

  const [bankAccount, setBankAccount] = useState(persistentData.bankAccount);
  const [bankAccountholder, setBankAccountholder] = useState(persistentData.bankAccountholder);
  const [expirationDays, setExpirationDays] = useState(persistentData.expirationDays ? persistentData.expirationDays : 30);

  const padInvoiceNumber = (event) => {
    if (event.target.name === "invoicePrefix") {
      setInvoicePrefix(event.target.value);
      setPaddedInvoiceNumber(
        `${event.target.value}${invoiceNumber.padStart(5, "0")}`
      );
    }

    if (event.target.name === "invoiceNumber") {
      setInvoiceNumber(event.target.value);
      setPaddedInvoiceNumber(
        `${invoicePrefix}${event.target.value.padStart(5, "0")}`
      );
    }
  };

  const saveStepThreeData = (e) => {
    const stepThreeObj = {
      invoiceNumber: invoiceNumber,
      paddedInoviceNumber: paddedInoviceNumber,
      invoiceDescription: invoiceDescription,
      invoiceDate: moment(invoiceDate).format("YYYY-MM-DD"),
      invoicePrefix,
      bankAccount,
      bankAccountholder,
      expirationDays,
    };
    handleCallbackStepThree(stepThreeObj);
    localStorage.setItem(
      "FormData",
      JSON.stringify({...persistentData, ...stepThreeObj})
    );
    setStep(4);
  };

  return (
    <>
      <div className="col-md-12 card o-hidden border-0 shadow-lg">
        <div className="card-body p-0">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">
                    {t("Add Invoice Data")}
                  </h1>
                </div>
                <Formik
                  initialValues={{
                    invoiceDescription: invoiceDescription,
                    bankAccount: bankAccount,
                    bankAccountholder: bankAccountholder,
                    expirationDays: expirationDays,
                    invoicePrefix: invoicePrefix,
                    invoiceNumber: invoiceNumber,
                  }}
                  validationSchema={firstInvoiceStep3Schema}
                  onSubmit={(values, {setSubmitting}) => {
                    saveStepThreeData(values);
                    setSubmitting(false);
                  }}
                >
                  {({
                      setFieldValue,
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                    <form className="user" onSubmit={handleSubmit}>
                      <div className="form-group row mb-sm-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>{t("Invoice Prefix")}</label>
                          <Field
                            type="text"
                            name="invoicePrefix"
                            className="form-control form-control-user"
                            value={invoicePrefix}
                            onChange={(e) => {
                              padInvoiceNumber(e);
                              setFieldValue("invoicePrefix", e.target.value);
                            }}
                          />
                          {errors.invoicePrefix && touched.invoicePrefix ? (
                            <span className="error-message">
                              {t(errors.invoicePrefix)}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-6 ">
                          <label>{t("Invoice Number")}</label>
                          <Field
                            type="text"
                            name="invoiceNumber"
                            autoComplete="off"
                            className="form-control form-control-user"
                            value={invoiceNumber}
                            onChange={(e) => {
                              padInvoiceNumber(e);
                              setFieldValue("invoiceNumber", e.target.value);
                            }}
                          />
                          {errors.invoiceNumber && touched.invoiceNumber ? (
                            <span className="error-message">
                              {t(errors.invoiceNumber)}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row mb-sm-3">
                        <div className="col-sm-12 mb-3 mb-sm-0">
                          <label>
                            {t("Your first invoice number will become")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={paddedInoviceNumber}
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-sm-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>{t("Invoice Description")}</label>
                          <Field
                            name="invoiceDescription"
                            type="text"
                            autoComplete="off"
                            value={invoiceDescription}
                            className="form-control form-control-user"
                            onChange={(e) => {
                              setInvoiceDescription(e.target.value);
                              setFieldValue(
                                "invoiceDescription",
                                e.target.value
                              );
                            }}
                          />
                          {errors.invoiceDescription &&
                          touched.invoiceDescription ? (
                            <span className="error-message">
                              {t(errors.invoiceDescription)}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-6 ">
                          <label>{t("Invoice date")}</label>
                          <DatePicker
                            className="form-control w-100"
                            wrapperClassName="w-100"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Choose a date"
                            selected={invoiceDate}
                            calendarStartDay={1}
                            onChange={(date) => setInvoiceDate(date)}
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-sm-3">
                        <div className="col-sm-12 mb-3 mb-sm-0">
                          <label>{t("Bank Account")}</label>
                          <Field
                            type="text"
                            name="bankAccount"
                            autoComplete="off"
                            value={bankAccount}
                            className="form-control form-control-user"
                            onChange={(e) => {
                              setBankAccount(e.target.value);
                              setFieldValue("bankAccount", e.target.value);
                            }}
                          />
                          {errors.bankAccount && touched.bankAccount ? (
                            <span className="error-message">
                              {t(errors.bankAccount)}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row mb-sm-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>{t("Bank Account Holder")}</label>
                          <Field
                            type="text"
                            name="bankAccountholder"
                            autoComplete="off"
                            value={bankAccountholder}
                            className="form-control form-control-user"
                            onChange={(e) => {
                              setBankAccountholder(e.target.value);
                              setFieldValue(
                                "bankAccountholder",
                                e.target.value
                              );
                            }}
                          />
                          {errors.bankAccountholder &&
                          touched.bankAccountholder ? (
                            <span className="error-message">
                              {t(errors.bankAccountholder)}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-6">
                          <label>{t("Expiration Days")}</label>
                          <Field
                            name="expirationDays"
                            type="number"
                            autoComplete="off"
                            className="form-control form-control-user"
                            value={expirationDays}
                            onChange={(e) => {
                              setExpirationDays(e.target.value);
                              setFieldValue("expirationDays", e.target.value);
                            }}
                          />
                          {errors.expirationDays && touched.expirationDays ? (
                            <span className="error-message">
                              {t(errors.expirationDays)}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group justify-content-center row mb-sm-3">
                        <div className="col-sm-3">
                          <button
                            type="button"
                            onClick={() => {
                              setStep(2);
                            }}
                            className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button mt-3 mt-md-0"
                          >
                            {t("Previous")}
                          </button>
                        </div>
                        <div className="col-sm-3 ml-auto ">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary btn-user btn-block p-1 py-2 next-button mt-2 mt-md-0"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFirstInvoiceStep3;
