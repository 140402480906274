import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import CustomSpinner from "../../components/CustomSpinner";
import {startLoading, stopLoading} from "../../store/actions/loading";
import SubscriptionDetail from "../../subscription/components/SubscriptionDetail";
import Subscription from "../../subscription/libs/Subscription";
import ResellerAction from "../libs/ResellerAction"

export default function ResellerSubscription() {
  const {t} = useTranslation();

  const [subscriptionsList, setSubscriptionList] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  // const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");

  console.log(userId, "userIduserId");

  useEffect(() => {
    Subscription.getSubscriptions()
      .then((res) => {

        console.log(res, "resresres");
        setLoading(false);
        setSubscriptionList(res.subscriptions);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSubscriptionClick = (subscriptionId) => {
    dispatch(startLoading());
    ResellerAction.resellerSubscription(userId, subscriptionId)
      .then((res) => {
        dispatch(stopLoading());
        history.push("/reseller/user");
      })
      .catch((error) => {
        console.log(error);
        dispatch(stopLoading());
      });
  };

  return (
    <div className="mt-3">
      <div className="d-block mb-4 mb-md-0">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
        >
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHome}/>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/reseller/user">{t("Users")}</Breadcrumb.Item>
          <Breadcrumb.Item href="/reseller/create-user">{t("Create user")}</Breadcrumb.Item>
          <Breadcrumb.Item href="/reseller/create-company">{t("Create company")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Select subscription")}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4 className="text-center mt-3">{t("Select subscription")}</h4>
      {loading ? (
        <CustomSpinner/>
      ) : (
        <div className="row mt-5">
          {subscriptionsList &&
            subscriptionsList.map((subscription, index) => {
              return (
                <SubscriptionDetail
                  key={index}
                  subscription={subscription}
                  handleSubscriptionClick={handleSubscriptionClick}
                />
              );
            })}
        </div>
      )}

      <div className="form-group justify-content-end row mb-sm-3 mt-5 cursor-pointer col-11"
           onClick={() => history.push("/reseller/user")}>
        {t("Skip this step")}
      </div>
    </div>
  );
}
