import React, {useState} from "react";
import RegistrationStep1 from "./RegistrationStep1";
import RegistrationStep2 from "./RegistrationStep2";
import RegistrationStep3 from "./RegistrationStep3";

function RegistrationForm({
                            firstName, lastName, email, password, prefix,
                            country, postCode, street, houseNo, hNoSuffix,
                            city, vat, name, coCNo, pNo, website, companyEmail, setFirstName,
                            setLastName, setEmail,
                            setPassword,
                            submitData,
                            setCoCNo,
                            setName,
                            setVat,
                            setStreet,
                            setHouseNo,
                            setHNoSuffix,
                            setPostCode,
                            setCity,
                            setCountry,
                            setPNo,
                            setWebsite,
                            setPrefix,
                            companyId,
                            setCompanyId,
                            setStatusMessage,
                            statusMessage,
                            setcompanyEmail
                          }) {
  const [step, setStep] = useState(1)
  const RegistrationStep1Props = {
    submitData,
    setCompanyId,
    setStep
  }
  const RegistrationStep2Props = {
    country,
    companyId,
    postCode,
    street,
    houseNo,
    hNoSuffix,
    city,
    coCNo,
    vat,
    pNo,
    website,
    companyEmail,
    name,
    setName,
    setCoCNo,
    setVat,
    setStreet,
    setHouseNo,
    setHNoSuffix,
    setPostCode,
    setCity,
    setCountry,
    setPNo,
    setWebsite,
    submitData,
    setStep,
    setcompanyEmail
  }
  const RegistrationStep3Props = {
    firstName,
    lastName,
    email,
    password,
    prefix,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setPrefix,
    submitData,
    statusMessage,
    setStep
  }

  if (step === 1) {
    return <RegistrationStep1 setStep={setStep} setName={setName} {...RegistrationStep1Props}/>
  }
  if (step === 2) {
    return <RegistrationStep2 setStep={setStep} setCompanyId={setCompanyId} {...RegistrationStep2Props}/>
  }
  if (step === 3) {
    return <RegistrationStep3 setStep={setStep} {...RegistrationStep3Props}/>
  }
}

export default RegistrationForm;