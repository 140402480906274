import {Alert, Col, Row} from "react-bootstrap";
import React from "react";

function ErrorNotice({errors}) {
  return (
    <Row className="mt-5">
      <Col>
        {errors.map((error) => <Alert className="text-center" key={error} variant="danger">{error}</Alert>)}
      </Col>
    </Row>
  )
}

export default ErrorNotice;