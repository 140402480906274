import React, {useEffect, useState} from "react";
import {Card, Form, Button, Breadcrumb} from "@themesberg/react-bootstrap";
import Company from "../../companies/libs/Company";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {startLoading, stopLoading} from '../../store/actions/loading';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import CustomSpinner from "../../components/CustomSpinner";

function OfferSettings() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [offerPrefix, setOfferPrefix] = useState("");
  const [nextOfferNumber, setNextOfferNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getOfferSetting();
  }, []);

  const getOfferSetting = () => {
    Company.getOfferSetting()
      .then(res => {
        setLoading(false);
        if (res.success === true) {
          setOfferPrefix(res.offerSetting.offer_prefix);
          setNextOfferNumber(res.offerSetting.next_offer_number);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error)
      });
  }

  const updateOfferSetting = () => {
    try {
      dispatch(startLoading());// show loading
      const settings = {
        'offer_prefix': offerPrefix,
        'next_offer_number': nextOfferNumber
      }

      Company.updateOfferSetting(settings)
        .then(res => {
          if (res.success === true) {
            setMessage({show: true, message: res.message, variant: "success"});
            getOfferSetting();
            dispatch(stopLoading());// hide loading
          } else if (res.success === false) {
            setMessage({show: true, message: res.message, variant: "warning"});
            dispatch(stopLoading());// hide loading
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({show: true, message: res.errors[key][0], variant: "danger"});
            }
            setErrors(array);
            dispatch(stopLoading());// hide loading
          }
        }).catch(error => {
        dispatch(stopLoading());
        console.log(error);
      });
    } catch (error) {
      dispatch(stopLoading());
      console.log(error)
    }

  }

  useEffect(() => {
    handleOfferNumber();

    // eslint-disable-next-line
  }, [nextOfferNumber])


  const handleOfferNumber = () => {
    if (nextOfferNumber < 0) {
      setErrorMsg(t("Please enter number more than 0"));
    } else {
      setErrorMsg("")
    }
  }

  const settingsDetails = () => {
    return (
      <div className="row mb-5">
        <div className="col-lg-12">
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible key={index} textMessage={message.message} variant="danger"/>
              )
            })
          }
          <Card>
            <Card.Header>
              <h5>{t("settings")}</h5>
            </Card.Header>
            <Card.Body>
              <div className="row my-3">
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>{t("Offer Prefix")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={offerPrefix}
                      onChange={(e) => setOfferPrefix(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-12 h-80">
                  <Form.Group>
                    <Form.Label>{t("Next offer number")}</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      value={nextOfferNumber && Math.max(0, nextOfferNumber)}
                      onChange={(e) => setNextOfferNumber(e.target.value)}
                    />
                    {errorMsg && <span className="error-message">{errorMsg}</span>}
                  </Form.Group>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Form.Group className="mt-4">
            <Button onClick={updateOfferSetting}>{t("Save Settings")}</Button>
          </Form.Group>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
                      listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Offer Settings")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Offer Settings")}</h4>
          <p className="mb-0">{t("Showing all offer settings of your company")}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant}/> : ""}
        </div>
      </div>
      {!loading ? settingsDetails() : <CustomSpinner/>}
    </>
  );
}

export default OfferSettings;
