import React from "react";
import {useField, useFormikContext} from "formik";
import {NumericFormat} from "react-number-format";
import {FormControl} from "react-bootstrap";

const PriceField = ({name, locale = 'nl-NL', currency = 'EUR', ...props}) => {
  const [field,] = useField(name);
  const {setFieldValue} = useFormikContext();

  // Use Intl to get the formatting options
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  const parts = formatter.formatToParts(12345.67);
  const thousandSeparator = parts.find(part => part.type === 'group').value;
  const decimalSeparator = parts.find(part => part.type === 'decimal').value;
  const currencySymbol = parts.find(part => part.type === 'currency').value;

  return (
    <NumericFormat
      customInput={FormControl}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={`${currencySymbol} `}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={true}
      value={field.value / 100}  // Convert cents to euros for display
      onValueChange={(vals) => {
        setFieldValue(name, vals.floatValue * 100 || 0);  // Convert euros to cents for storing
      }}
      {...props}
    />
  );
};

export default PriceField;
