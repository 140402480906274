import React, {useEffect, useState} from "react";
import Sidebar from "../layout/components/Sidebar";
import Navbar from "../layout/components/Navbar";
// import Footer from "../layout/components/Footer";
import SearchResult from "./SearchResults";
import {useLocation} from "react-router-dom";
import {faBoxes, faCog, faFile, faFileContract, faFileInvoice, faUsers,} from "@fortawesome/free-solid-svg-icons";
import FactuursnelLogo2 from "../../assets/img/logo.png";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {CollapsableNavItem, NavItem} from "../../components/NavItem";
import {useBooleanFlagValue} from "@openfeature/react-sdk";
import {selectResellerCompanyLogo} from "../../store/reducers/userCompany";

function Admin({children}) {
  const [search, setSearch] = useState("");
  const logo = useSelector(selectResellerCompanyLogo);
  const {t} = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setSearch("");
  }, [location]);

  const contractsEnabled = useBooleanFlagValue('contracts', false);
  const offersEnabled = useBooleanFlagValue('offers', false);

  document.body.classList.remove("bg-gradient-primary");

  return (
    <>
      <Sidebar>
        <NavItem title="FACTUURSNEL" link="/" image={FactuursnelLogo2}/>

        <NavItem title={t("invoices")} link="/invoices" icon={faFileInvoice}/>
        {offersEnabled && <NavItem title={t("offers")} link="/offers" icon={faFile}/>}
        <NavItem title={t("customers")} link="/customers" icon={faUsers}/>
        {contractsEnabled && <NavItem title={t("contracts")} link="/contracts" icon={faFileContract}/>}
        <NavItem title={t("products")} link="/products" icon={faBoxes}/>
        <CollapsableNavItem eventKey="settings" title={t("settings")} icon={faCog}>
          <NavItem title={t("Company settings")} link="/company-settings"/>
          <NavItem title={t("Invoice settings")} link="/invoice-settings"/>
          <NavItem title={t("Template settings")} link="/template-settings"/>
          {offersEnabled && <NavItem title={t("Offer settings")} link="/offer-settings"/>}
        </CollapsableNavItem>
        {logo && <div className="mt-5 d-flex flex-column align-items-center logo-wrapper">
          <p>Powered by</p>
          <img src={logo} alt="logo" className="siderbar-logo"/>
        </div>}
      </Sidebar>

      <main className="content">
        <Navbar search={search} setSearch={setSearch}/>
        {search ? <SearchResult search={search}/> : children}
      </main>
    </>
  );
}

export default Admin;
