import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Accordion, Badge, Image, Nav} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";


export function CollapsableNavItem(props) {
  const location = useLocation();
  const {pathname} = location;

  const {eventKey, title, icon, children = null} = props;
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
  return (
    <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button
          as={Nav.Link}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon}/>{" "}
            </span>
            <span className="sidebar-text">{title}</span>
          </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
          <Nav className="flex-column">{children}</Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export function NavItem(props) {
  const location = useLocation();
  const {pathname} = location;

  const {
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = "secondary",
    badgeColor = "primary",
  } = props;
  const classNames = badgeText
    ? "d-flex justify-content-start align-items-center justify-content-between"
    : "";
  const navItemClassName = link === pathname ? "active" : "";
  const linkProps = external ? {href: link} : {as: Link, to: link};
  return (
    <Nav.Item className={navItemClassName}>
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon}/>
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              width={20}
              height={20}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          <span className="sidebar-text text-capitalize">{title}</span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
}
