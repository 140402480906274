import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumb, Card, Col, Container, FormControl, InputGroup, Row} from "@themesberg/react-bootstrap";
import {faAngleLeft, faAngleRight, faHome} from "@fortawesome/free-solid-svg-icons";
import DashboardLib from '../lib/DashboardLib';
import InvoiceChart from './charts/InvoiceChart';
import {formatCurrency} from '../../utils/helpers';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import CustomSpinner from "../../components/CustomSpinner"
import AlertDismissible from "../../components/old/AlertDismissible";

function Dashboard() {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [invoiceChartData, setInvoiceChartData] = useState(null);
  const [yearTurnoverAmt, setYearTurnoverAmt] = useState(0);
  const [yearOpenTurnoverAmt, setYearOpenTurnoverAmt] = useState(0);
  const [yearPaidTurnoverAmt, setYearPaidTurnoverAmt] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getInvoiceChartData(startDate);
    /***** below comment is disable esling because of using outside business logic*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);
  /** method of prepare to set chart data state */
  const chartData = (data) => {
    let labels = [];//chart labels
    let totalTurnover = {// Total turnover chart datasets
      label: t('Total'),
      data: [],
      backgroundColor: '#596285',
    };
    let totalPaid = {// Total Paid turnover chart datasets
      label: t('Paid amount'),
      data: [],
      backgroundColor: '#262B40',
    };
    let totalOpen = {// Total Open turnover chart datasets
      label: t('Open amount'),
      data: [],
      backgroundColor: '#596285',
    };
    let turnoverAmt = 0;// for year turnover amount
    let paidTurnoverAmt = 0;// for year paid turnover amount
    let openTurnoverAmt = 0;// for year open turnover amount
    // transform data  from api response
    for (const property in data) {
      labels.push(property);
      let m_turnoverAmt = 0;
      let m_paidTurnoverAmt = 0;
      let m_openTurnoverAmt = 0;
      if (data[property].length) {
        for (let element of data[property]) {
          m_turnoverAmt += element.total_amount_including_vat;
          m_paidTurnoverAmt += element.paid_amount;
          m_openTurnoverAmt += (element.total_amount_including_vat - element.paid_amount);
        }
        totalTurnover.data.push(m_turnoverAmt);
        totalPaid.data.push(m_paidTurnoverAmt);
        totalOpen.data.push(m_openTurnoverAmt);
      } else {
        totalTurnover.data.push(0);
        totalPaid.data.push(0);
        totalOpen.data.push(0);
      }
      turnoverAmt += m_turnoverAmt;
      paidTurnoverAmt += m_paidTurnoverAmt;
      openTurnoverAmt += m_openTurnoverAmt;
    }
    // set total amount of a year to show on right side of chart
    setYearTurnoverAmt(turnoverAmt);
    setYearOpenTurnoverAmt(openTurnoverAmt)
    setYearPaidTurnoverAmt(paidTurnoverAmt);
    const Chdata = {// final chart data to pass chart component
      labels,
      datasets: [
        // totalTurnover,
        totalPaid,
        totalOpen
      ],
    };
    setInvoiceChartData(Chdata);
  }
  /** request Chart data to show  */
  const getInvoiceChartData = (date) => {
    setLoading(true);
    let year = date?.getFullYear();
    DashboardLib.requestInvoiceChartData(year).then(res => {
        setLoading(false);
        if (res.success) {
          chartData(res.chart_data);
        } else if (res.errors) {
          let array = [];
          for (const key in res.errors) {
            array.push({show: true, message: res.errors[key][0], variant: "danger"});
          }
          setErrors(array);
          setInvoiceChartData(null);
        } else {
          setInvoiceChartData(null);
          setMessage({show: true, message: res.message, variant: "warning"});
        }
      }
    )
  }
  /** customize input desigin of react-datepicker package */
  const ReactDatePickerCustomInput = React.forwardRef(({value, onChange, onClick}, inputRef) => (
    <InputGroup className="ps-auto ms-auto ps-lg-5 ms-lg-5">
      <InputGroup.Text style={{border: 'none', background: 'none', cursor: 'pointer', paddingRight: 4}}
                       onClick={onClick}>
        <FontAwesomeIcon icon={faAngleLeft}/>
      </InputGroup.Text>
      <FormControl
        style={{border: 'none', background: 'none', marginRight: 0, maxWidth: 40}}
        value={value}
        onChange={onChange}
        onClick={onClick}
        ref={inputRef}
        className="cursor-pointer"
      />
      <InputGroup.Text
        style={{border: 'none', background: 'none', paddingLeft: 4, marginLeft: 0, cursor: 'pointer'}}
        onClick={onClick}>
        <FontAwesomeIcon icon={faAngleRight}/>
      </InputGroup.Text>
    </InputGroup>
  ));
  return (
    <>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
                      listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Dashboard")}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} sm={8} md={9}>
            <h4>{t("Dashboard")}</h4>
            <p className="mb-0">{t("Here you can find your yearly turnover")}</p>
          </Col>
          <Col xs={12} sm={4} md={3}>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setMessage({});
                setErrors([]);
              }}
              showYearPicker
              dateFormat="yyyy"
              customInput={<ReactDatePickerCustomInput/>}
            />
          </Col>
        </Row>
      </Container>
      {/* Error message and loding indicatior */}
      {loading ? <CustomSpinner/> : ""}
      <div className="row">
        <div className="col-md-12">
          {message.show ?
            <AlertDismissible textMessage={message.message} variant={message.variant}
                              onAfterClose={() => setStartDate(new Date())}/> : ""}
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible key={index} textMessage={message.message} variant="danger"/>
              )
            })
          }
        </div>
      </div>

      {
        invoiceChartData && <Container fluid>
          <Row>
            <Col xs={12} sm={8} md={10}>
              <div style={{height: '60vh'}}>
                <InvoiceChart data={invoiceChartData}/>
              </div>
            </Col>
            <Col xs={12} sm={4} md={2}>
              <div style={{height: '65vh'}}>
                <Col>
                  <Card className="mt-3 mt-sm-6" border="light" style={{backgroundColor: '#596285'}}>
                    <Card.Body className="p-0">
                      <p className="text-left mt-sm-auto text-white p-2">{t("Total")}</p>
                      <h6
                        className="text-right text-white p-2">{formatCurrency(yearTurnoverAmt / 100, 'nl-NL', 'currency', 'EUR')}</h6>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card border="light" style={{backgroundColor: '#262B40'}}>
                    <Card.Body className="p-0">
                      <p className="text-left text-white p-2">{t("Paid amount")}</p>
                      <h6
                        className="text-right text-white p-2">{formatCurrency(yearPaidTurnoverAmt / 100, 'nl-NL', 'currency', 'EUR')}</h6>

                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card border="light" style={{backgroundColor: '#3f3f3f'}}>
                    <Card.Body className="p-0">
                      <p className="text-left text-white p-2">{t("Open amount")}</p>
                      <h6
                        className="text-right text-white p-2">{formatCurrency(yearOpenTurnoverAmt / 100, 'nl-NL', 'currency', 'EUR')}</h6>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      }
    </>
  );
}

export default Dashboard;
