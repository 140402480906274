import Api from "../../libs/Api";

import {
  GET_CUSTOMERS_START,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMER_BY_ID_START,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILED,
} from "./actionTypes";

export const fetchCompanyCustomers = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS_START,
    });

    try {
      const response = await Api.get(`/api/customers/all`);
      console.log(response);
      dispatch({
        payload: response.data?.customers,
        type: GET_CUSTOMERS_SUCCESS,
      });
    } catch (e) {
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_CUSTOMERS_FAILED,
      });
    }
  };
};

export const getCustomerById = (customerId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_BY_ID_START,
    });

    try {
      const response = await Api.get(`/api/customers/${customerId}`);
      dispatch({
        payload: response.data?.customer,
        type: GET_CUSTOMER_BY_ID_SUCCESS,
      });
    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_CUSTOMER_BY_ID_FAILED,
      });
    }
  };
};
