import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumb, Button, Card, Form} from "@themesberg/react-bootstrap";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import AlertDismissible from "../../components/old/AlertDismissible";
import CustomSpinner from "../../components/CustomSpinner";
import Invoice from "../../invoices/libs/Invoice";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {SettingsSchema} from "../../validation";
import Toggle from "./ToggleSwitch/switch";

function InvoiceSettings() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [disableReminderButton, setDisableReminderButton] = useState(false);
  const [disableReminderInput, setDisableReminderInput] = useState(false);
  const [message, setMessage] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);

  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      expiration_date: "",
      reminder_interval: "",
      bcc_email: "",
      next_invoice_number: "",
      invoice_prefix: "",
      second_reminder: false,
      first_reminder: false,
    },
    validationSchema: SettingsSchema,
    onSubmit: (values, {setSubmitting}) => {
      setTimeout(() => {
        setSubmitting(false);
        updateInvoiceSetting(values);
      }, 1000);
    },
  });

  const getInvoiceSetting = () => {
    Invoice.getInvoiceSetting()
      .then((res) => {
        if (res.success === true) {
          values.expiration_date = res.invoiceSetting.expiration_date;
          values.invoice_prefix = res.invoiceSetting.invoice_prefix;
          values.next_invoice_number = res.invoiceSetting.next_invoice_number;
          values.reminder_interval = res.invoiceSetting?.reminder_interval
            ? res.invoiceSetting?.reminder_interval
            : 14;
          values.bcc_email = res.invoiceSetting.bcc_email;
          setFieldValue(
            "first_reminder",
            res.invoiceSetting?.first_reminder || false
          );
          setFieldValue(
            "second_reminder",
            res.invoiceSetting?.second_reminder || false
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const updateInvoiceSetting = (values) => {
    try {
      dispatch(startLoading());
      Invoice.updateInvoiceSetting(values)
        .then((res) => {
          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            dispatch(stopLoading()); // hide loading
          } else if (res.success === false) {
            setMessage({
              show: true,
              message: res.message,
              variant: "warning",
            });
            dispatch(stopLoading()); // hide loading
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            setErrorMessages(array);
            dispatch(stopLoading()); // hide loading
          }
        })
        .catch((error) => {
          dispatch(stopLoading());
          console.log(error);
        });
    } catch (error) {
      dispatch(stopLoading());
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoiceSetting();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.second_reminder) {
      setDisableReminderInput(false);
    } else {
      setDisableReminderInput(true);
    }

    if (values.first_reminder) {
      setDisableReminderButton(false);
    } else {
      setDisableReminderButton(true);
      setDisableReminderInput(true);
      setFieldValue("second_reminder", false);
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Invoice Settings")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Invoice Settings")}</h4>
          <p className="mb-0">
            {t("Showing all invoice settings of your company")}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          {message.show ? (
            <AlertDismissible
              textMessage={message.message}
              variant={message.variant}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {loading ? (
        <CustomSpinner/>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row mb-5">
            <div className="col-lg-12">
              {errorMessages &&
                errorMessages.map((message, index) => {
                  return (
                    <AlertDismissible
                      key={index}
                      textMessage={message.message}
                      variant="danger"
                    />
                  );
                })}
              <Card>
                <Card.Header>
                  <h5>{t("settings")}</h5>
                </Card.Header>
                <Card.Body>
                  <div className="row mb-3 h-90">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>{t("Expiration Days")}</Form.Label>
                        <Form.Control
                          name="expiration_date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.expiration_date}
                        />
                        {errors.expiration_date && touched.expiration_date ? (
                          <span className="error-message">
                            {t(errors.expiration_date)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row my-3 h-90">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>{t("Invoice Prefix")}</Form.Label>
                        <Form.Control
                          name="invoice_prefix"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.invoice_prefix}
                        />
                        {errors.invoice_prefix && touched.invoice_prefix ? (
                          <small className="error-message">
                            {t(errors.invoice_prefix)}
                          </small>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row my-3 h-90">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>{t("Next Invoice Number")}</Form.Label>
                        <Form.Control
                          name="next_invoice_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.next_invoice_number}
                        />
                        {errors.next_invoice_number &&
                        touched.next_invoice_number ? (
                          <span className="error-message">
                            {t(errors.next_invoice_number)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row my-3 h-90">
                    <div className="col-4">
                      <Form.Group>
                        <Form.Label>{t("Reminder Interval Days")}</Form.Label>
                        <Form.Control
                          disabled={disableReminderInput}
                          name="reminder_interval"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.reminder_interval}
                        />
                        {errors.reminder_interval &&
                        touched.reminder_interval ? (
                          <span className="error-message">
                            {t(errors.reminder_interval)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group>
                        <Form.Label>{t("Reminder")}</Form.Label>
                        <div>
                          <Toggle
                            checked={values.first_reminder}
                            name="first_reminder"
                            handleChange={(checked) =>
                              setFieldValue("first_reminder", checked)
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group>
                        <Form.Label>{t("Second Reminder")}</Form.Label>
                        <div>
                          <Toggle
                            checked={values.second_reminder}
                            name="second_reminder"
                            handleChange={(checked) =>
                              setFieldValue("second_reminder", checked)
                            }
                            disabled={disableReminderButton}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row my-3 h-90">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>Bcc {t("Address")}</Form.Label>
                        <Form.Control
                          type="email"
                          name="bcc_email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bcc_email}
                        />
                        {errors.bcc_email && touched.bcc_email ? (
                          <span className="error-message">
                            {t(errors.bcc_email)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Form.Group className="my-4">
                <Button type="submit" disabled={isSubmitting}>
                  {t("Save Settings")}
                </Button>
              </Form.Group>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default InvoiceSettings;
