import React, {useEffect, useState} from "react";
import {Button, Card, Table} from "react-bootstrap";
import OfferLine from "./OfferLine";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {FieldArray, useFormikContext} from "formik";
import {getProducts} from "../../store/actions/products";
import {useDispatch} from "react-redux";

export default function OfferForm() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const {setFieldValue, errors, touched, values, handleBlur} = useFormikContext();

  useEffect(() => {
    dispatch(getProducts());

    if (values?.offer_line?.length !== 1) {
      setShowDeleteButton(true);
    }

    if (values?.offer_line?.length === 1) {
      setShowDeleteButton(false);
    }

    //eslint-disable-next-line
  }, [values?.offer_line]);

  return (
    <Card className="mt-4">
      <Card.Header className="p-2">
        <h5>{t("Offer lines")}</h5>
      </Card.Header>
      <Card.Body>
        <FieldArray name="offer_line">
          {({remove, push, handleChange}) => (
            <>
              <Table striped hover responsive size="sm" borderless={true}>
                <thead>
                <tr>
                  <th className="text-center">{t("Description")}</th>
                  <th className="text-center">{t("Quantity")}</th>
                  <th className="text-center">{t("price")}</th>
                  <th className="text-center">{t("Vat percentage")}</th>
                  <th className="text-center">{t("price excl. VAT")}</th>
                </tr>
                </thead>
                <tbody>
                {values?.offer_line?.map((offer_line, index) => (
                  <OfferLine
                    key={`offer_line-${index}`}
                    rowId={index}
                    deleteOfferLine={remove}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    values={offer_line}
                    handleBlur={handleBlur}
                    showDeleteButton={showDeleteButton}
                  />
                ))}
                </tbody>
              </Table>
              <div className=" col-12 d-flex justify-content-center align-items-center p-2">
                <Button
                  variant="outline-primary"
                  className="btn-sm"
                  onClick={() =>
                    push({
                      description: "",
                      quantity: 1,
                      price: 0,
                      vat_percentage: 0,
                      amountExclVat: 0,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </div>
            </>
          )}
        </FieldArray>
      </Card.Body>
    </Card>
  );
}
