import React from "react";
import {Spinner} from "react-bootstrap";

export default function CustomSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{marginTop: "20%"}}>
      <Spinner size="lg" animation="border" role="status"></Spinner>
    </div>
  );
}
