import React, {useState} from "react";
import {Alert, Button, Form, InputGroup, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../store/actions/loading";
import ResellerAction from "../libs/ResellerAction";

export default function CreateMandate() {
  const {t} = useTranslation();

  const [name, setName] = useState("");
  const [iban, setIban] = useState("");
  const [modal, showModal] = useState(true);
  const [error, setError] = useState("");
  const [mandateErrors, setMandateErrors] = useState("");

  const dispatch = useDispatch();

  const createMandatePayment = () => {
    const paymentData = {
      consumerName: name,
      consumerAccount: iban,
    };

    dispatch(startLoading());
    ResellerAction.createMandate(paymentData)
      .then((res) => {
        dispatch(stopLoading());
        showModal(false);
      })
      .catch((error) => {
        dispatch(stopLoading());
        console.log(error);
        setMandateErrors(
          t("Something went wrong, please try again at a later moment")
        );
      });
  };

  const isIban =
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/g;

  const ibanValidate = () => {
    if (iban.length && !iban.match(isIban)) {
      setError(t("Please enter a valid iban"));
      return false;
    } else if (!iban || iban.length === 0) {
      setError(t("Iban field is required"));
      return false;
    } else if (iban.length && iban.match(isIban)) {
      setError("");
    } else {
      return null;
    }
  };

  return (
    <Modal size="lg" centered show={modal}>
      <Form>
        <Modal.Header>
          <Modal.Title>{t("Create a mandate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mandateErrors && <Alert variant="danger">{mandateErrors}</Alert>}
          <Form.Group className="mb-4">
            <Form.Label>{t("Account holder name")}:</Form.Label>
            <InputGroup>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
                required
                type="text"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("Iban")}:</Form.Label>
            <InputGroup>
              <Form.Control
                onChange={(e) => setIban(e.target.value)}
                required
                type="text"
              />
            </InputGroup>
            {error && <span className="error-message mt-2">{error}</span>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="danger" onClick={handleClose}>
            {t("Close")}
          </Button> */}
          <Button
            variant="primary"
            onClick={() => {
              ibanValidate();
              createMandatePayment();
            }}
          >
            {t("Create")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
