import React, {useEffect, useState} from "react";
import {Breadcrumb, ButtonGroup, Card, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CreateTemplate from "./CreateTemplate";
import ViewTemplate from "./ViewTemplate";
import Templates from "./TemplateService";
import {faEdit, faHome, faPlus, faStar, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../../components/old/AlertDismissible";
import CustomSpinner from "../../../components/CustomSpinner";
import {OldPagination} from "../../../components/old/OldPagination";

function TemplateSettings() {
  const {t} = useTranslation();

  const [templateId, setTemplateId] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, showModal] = useState(false);
  const [viewModal, showViewModal] = useState(false);
  const [allTemplet, setAllTemplet] = useState([]);
  const [message, setMessage] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: ""
  });

  useEffect(() => {
    setLoading(false);
    let AllData = Templates.getTemplates();
    setAllTemplet(AllData);
  }, []);


  useEffect(() => {
    getPaginatedTemplates();
  }, []);

  useEffect(() => {
    if (refreshData) {
      getPaginatedTemplates();
      setRefreshData(false);
    }
  }, [refreshData]);


  const getPaginatedTemplates = (page = null) => {
    setLoading(true);
    Templates.getPaginatedTemplates(page)
      .then(res => {
        setLoading(false);
        if (res.templates) {
          setAllTemplet(res.templates.data);
          setPaginationData({
            links: res.templates.links,
            prevPageUrl: res.templates.prev_page_url,
            nextPageUrl: res.templates.next_page_url,
            showingPerPage: res.templates.to,
            total: res.templates.total
          });
        } else {
          setMessage({show: true, message: res.message, variant: "warning"});
        }
      })
  }

  const deleteTemplate = async (id) => {
    let deleteTemplate = await Templates.deleteTemplates(id);
    if (deleteTemplate.success) {
      setRefreshData(true);
      setMessage({show: true, message: deleteTemplate.message, success: deleteTemplate.success, variant: "success"})
    } else {
      setMessage({show: true, message: deleteTemplate && deleteTemplate.message, variant: "warning"})
    }
  }

  const Setdefault = async (id) => {
    let updateStatus = await Templates.setDefaultTemplate(id);
    if (updateStatus.success) {
      setRefreshData(true);
      setMessage({show: true, message: updateStatus.message, success: updateStatus.success, variant: "success"})
    } else {
      setMessage({show: true, message: updateStatus && updateStatus.message, variant: "warning"})
    }
  }

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">{props.index + 1}</span>
        </td>
        <td colSpan="4" style={{cursor: "pointer"}} onClick={() => {
          showViewModal(true);
          setTemplateId(props.id);
        }}>
          <span className="fw-normal">{props.name}</span>
        </td>
        <td>
          <span className="fw-normal ">
          <div className="btn  btn-sm me-2"
               onClick={() => Setdefault(props.id)}>
              <FontAwesomeIcon icon={faStar} color={props.is_default ? "black" : "grey"}/>
            </div>
            <div
              className="btn btn-outline-primary btn-sm me-2"
              onClick={() => {
                setTemplateId(props.id);
                showModal(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit}/>
            </div>
            <div className="btn btn-outline-primary btn-sm me-2"
                 onClick={() => deleteTemplate(props.id)}><FontAwesomeIcon icon={faTrashAlt}/> </div>
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Templates")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Templates")}</h4>
          <p className="mb-0">{t("Showing all Templates")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <div
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                setTemplateId("");
                showModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2"/> {t("new")}
            </div>
          </ButtonGroup>
        </div>
      </div>
      {loading ? <CustomSpinner/> : ""}

      <div className="row">
        <div className="col-md-12">
          {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant}/> : ""}
        </div>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0 pb-5">
          <Table hover className="user-table align-items-center">
            <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">{t("Name")}</th>
              <th className="border-bottom"></th>
              <th className="border-bottom"></th>
              <th className="border-bottom"></th>
              <th className="border-bottom">{t("action")}</th>
            </tr>
            </thead>
            <tbody>
            {allTemplet && allTemplet.length > 0 &&
              allTemplet.map((template, index) => (
                <TableRow
                  key={`template-${index}`}
                  index={index}
                  {...template}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>

        <OldPagination action={Templates} items={allTemplet} paginationData={paginationData}
                       getPaginated={getPaginatedTemplates}/>

        <CreateTemplate
          show={modal}
          templateId={templateId}
          handleClose={() => showModal(false)}
          setModalMessage={(data) => {
            setMessage(data);
          }}
          setRefreshData={setRefreshData}
        />
        <ViewTemplate
          show={viewModal}
          TemplateId={templateId}
          handleClose={() => showViewModal(false)}
        />
      </Card>
    </>
  );
}

export default TemplateSettings;
