import {
  GET_OFFERS_FAILED,
  GET_OFFERS_START,
  GET_OFFERS_SUCCESS,
  GET_OFFER_BY_ID_FAILED,
  GET_OFFER_BY_ID_START,
  GET_OFFER_BY_ID_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  offer: {
    offer: {},
    success: false,
    isLoading: false,
    error: "",
  },
  offers: {
    offers: {},
    success: false,
    isLoading: false,
    error: "",
  },
};
const offers = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_OFFERS_SUCCESS:
      return {
        ...initialState,
        offers: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_OFFERS_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };

    case GET_OFFER_BY_ID_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_OFFER_BY_ID_SUCCESS:
      return {
        ...initialState,
        offer: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_OFFER_BY_ID_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default offers;
