import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, FieldArray, Formik} from "formik";
import {FirstInvoiceStep4Schema} from "../../validation";
import PriceField from "../../components/inputs/PriceField";
import Form from "react-bootstrap/Form";

function CreateFirstInvoiceStep4({
                                   setStep,
                                   handleCallbackStepFour,
                                   img,
                                   stepTwoData,
                                   stepThreeData,
                                 }) {
  const {t} = useTranslation();

  const persistentData = localStorage.getItem("FormData")
    ? JSON.parse(localStorage.getItem("FormData"))
    : {};

  const saveInputList = (values) => {
    localStorage.setItem(
      "FormData",
      JSON.stringify({...persistentData, inputList: values?.list})
    );
  };

  const invoiceDataSubmit = (values) => {
    const persistentData = localStorage.getItem("FormData")
      ? JSON.parse(localStorage.getItem("FormData"))
      : {}

    handleCallbackStepFour({...persistentData, inputList: values?.list});
  };

  const initialValues = {
    list: persistentData?.inputList ? persistentData?.inputList : [{
      vat_percentage: "",
      quantity: "1",
      price: 0,
      description: ""
    }],
  };

  return (
    <>
      <div className="col-md-12 card o-hidden border-0 shadow-lg">
        <div className="card-body p-0">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="p-5">
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <h4 className="text-gray-900">
                      {t("Company Information")}
                    </h4>
                    <div className="row">
                      {img !== "../../../default-logo.png" ? (
                        <div className="col-3 invoice-logo">
                          <img
                            className="logo-company-img"
                            src={img}
                            alt="logo"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-9">
                        <p className="mb-0">
                          {localStorage.getItem("company_name")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_street")}{" "}
                          {localStorage.getItem("company_house_number")}{" "}
                          {localStorage.getItem("company_house_number_suffix")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_postcode")}{" "}
                          {localStorage.getItem("company_city")}{" "}
                          {localStorage.getItem("company_country")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_phone_number")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_email")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_website_url")}
                        </p>
                        <p className="mb-0">
                          {localStorage.getItem("company_kvk_number")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-0 mt-3 mb-2">
                    <h4 className="text-gray-900">
                      {t("Customer Information")}
                    </h4>
                    <p className="mb-0">{stepTwoData.customerCompany?.companyName}</p>
                    <p className="mb-0">{stepTwoData.customerVAT}</p>
                    <p className="mb-0">
                      {stepTwoData.customerStreet}{" "}
                      {stepTwoData.customerHouseNumber}{" "}
                      {stepTwoData.customerSuffix}
                    </p>
                    <p className="mb-0">
                      {stepTwoData.customerPostcode} {stepTwoData.customerCity}{" "}
                      {stepTwoData.customerCountry}{" "}
                    </p>
                    <p className="mb-0">{stepTwoData.customerNumber}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3 mb-2">
                    <h4 className="text-gray-900">{t("Invoice Data")}</h4>
                  </div>
                  <div className="col-md-6">
                    <p className="mb-0">
                      <span className="text-gray-900">
                        {t("Invoice Number")}:{" "}
                      </span>
                      {stepThreeData.paddedInoviceNumber}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="mb-0">
                      <span className="text-gray-900">
                        {t("Invoice date")}:{" "}
                      </span>
                      {moment
                        .tz(stepThreeData.invoiceDate, "Europe/Amsterdam")
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="mb-0">
                      <span className="text-gray-900">
                        {t("Invoice Description")}:{" "}
                      </span>
                      {stepThreeData.invoiceDescription}
                    </p>
                  </div>
                </div>
                <div className="text-left">
                  <h4 className="text-gray-900 my-4">{t("Invoice Line")}</h4>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={(values, {setSubmitting}) => {
                    invoiceDataSubmit(values);
                    setSubmitting(false);
                  }}
                  validationSchema={FirstInvoiceStep4Schema}
                >
                  {({
                      setFieldValue,
                      errors,
                      handleSubmit,
                      isSubmitting,
                      values,
                      handleChange,
                      setFieldError,
                    }) => (
                    <>
                      <form className="user" onSubmit={handleSubmit}>
                        <FieldArray
                          name="list"
                          render={({remove, push}) => (
                            <div className="form-group mb-sm-3 mt-3">
                              {values?.list?.map((x, i) => (
                                <div key={i} className="row invoice-data">
                                  <div className="col-sm-3 mb-3">
                                    <label className="pl-2">
                                      {t("Description")}
                                    </label>
                                    <Field
                                      as={Form.Control}
                                      type="text"
                                      placeholder={t("Description")}
                                      name={`list.${i}.description`}
                                    />
                                    <ErrorMessage
                                      name={`list.${i}.description`}
                                      component="small"
                                      className="error-message"
                                    />
                                  </div>

                                  <div className="col-sm-2 mb-3 pr-0">
                                    <label className="pl-2">
                                      {t("Quantity")}
                                    </label>
                                    <Field
                                      as={Form.Control}
                                      type="text"
                                      placeholder="quantity"
                                      name={`list.${i}.quantity`}
                                      value={values.list[i].quantity}
                                    />
                                    <ErrorMessage
                                      name={`list.${i}.quantity`}
                                      component="small"
                                      className="error-message"
                                    />
                                  </div>
                                  <div className="col-sm-2 mb-3">
                                    <label className="pl-2">{t("price")}</label>
                                    <PriceField name={`list.${i}.price`}/>
                                    {errors &&
                                      errors?.list &&
                                      errors?.list[i] &&
                                      errors?.list[i]?.price && (
                                        <small className="error-message">
                                          {errors.list[i].price}
                                        </small>
                                      )}
                                  </div>
                                  <div className="col-sm-3 mb-3">
                                    <label className="pl-2">
                                      {t("VAT Percentage")}
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-control invoice-select pr-0"
                                      name={`list.${i}.vat_percentage`}
                                      value={values.list[i].vat_percentage}
                                    >
                                      <option value="" disabled>
                                        {t("Select vat percentage")}
                                      </option>
                                      <option value="0">{t("VAT 0%")}</option>
                                      <option value="6"> {t("VAT low 6%")}</option>
                                      <option value="9">{t("VAT low 9%")}</option>
                                      <option value="21">{t("VAT high 21%")}</option>
                                      <option value="2">{t("VAT shifted 0%")}</option>
                                      <option value="3">{t("VAT exempt 0%")}</option>
                                    </Field>
                                    <ErrorMessage
                                      name={`list.${i}.vat_percentage`}
                                      component="small"
                                      className="error-message"
                                    />
                                  </div>
                                  <div className="col-sm-2">
                                    {values?.list?.length !== 1 && (
                                      <button
                                        type="button"
                                        onClick={() => remove(i)}
                                        className="btn btn-danger"
                                        style={{marginTop: 22}}
                                      >
                                        <FontAwesomeIcon icon={faTimes}/>
                                      </button>
                                    )}
                                    {values?.list?.length - 1 === i && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          push({
                                            vat_percentage: "",
                                            quantity: "1",
                                            price: 1,
                                            description: "",
                                          })
                                        }
                                        className="btn btn-primary mt-2 mt-md-0"
                                        style={{
                                          marginLeft: 10,
                                          marginTop: 22,
                                        }}
                                        aria-label="plus"
                                      >
                                        <FontAwesomeIcon icon={faPlus}/>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        />

                        <div className="form-group justify-content-center row">
                          <div className="col-sm-3">
                            <button
                              type="button"
                              onClick={() => {
                                saveInputList(values);
                                setStep(3);
                              }}
                              className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button mt-3 mt-md-0"
                            >
                              {t("Previous")}
                            </button>
                          </div>

                          <div className="col-sm-3 ml-auto ">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              onClick={() => {
                                saveInputList(values);
                              }}
                              className="btn btn-primary btn-user btn-block p-1 py-2 next-button mt-2 mt-md-0"
                            >
                              {t("Create First Invoice")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFirstInvoiceStep4;
