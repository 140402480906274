import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumb, Button, Form} from "@themesberg/react-bootstrap";
import {parseISO} from "date-fns";
import {Formik} from "formik";
import moment from "moment";
import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Company from "../../companies/libs/Company";
import AlertDismissible from "../../components/old/AlertDismissible";
import {numberFormatter} from "../../libs/numberFormatter";
import PriceCard from "../../components/PriceCard";
import CustomerModal from "../../customers/components/CustomerModal";
import InvoiceForm from "../../invoices/components/InvoiceForm";
import InvoiceReminder from "../../invoices/components/InvoiceReminder";
import {getContractById} from "../../store/actions/contracts";
import {fetchCompanyCustomers, getCustomerById} from "../../store/actions/customers";
import {stopLoading} from "../../store/actions/loading";
import {getProducts} from "../../store/actions/products";
import {contractSchema} from "../../validation";
import Contract from "../libs/Contract";
import ContractInformation from "./ContractInformation";
import "../style/loader.scss";
import Auth from "../../auth/libs/Auth";
import {selectCustomers} from "../../store/reducers/customers";
import Preloader from "../../admin/layout/components/Preloader";

function CreateContract() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {id} = useParams();
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [defaultSetting, setDefaultSetting] = useState(true);
  const [firstReminder, setFirstReminder] = useState(null);
  const [secondReminder, setSecondReminder] = useState(true);
  const [disableReminderButton, setDisableReminderButton] = useState(false);
  const [message, setMessage] = useState({});
  const [error, setError] = useState([]);
  const [formattedTotalPriceExclVat, setFormattedTotalPriceExclVat] =
    useState("");
  const [formattedTotalVat, setFormattedTotalVat] = useState("");
  const [formattedTotalInclVat, setFormattedTotalInclVat] = useState("");
  const [showCustomerModal, setShowCustomer] = useState(false);
  const [invoiceLines, setInvoiceLines] = useState([]);
  const [totalPriceExclVat, setTotalPriceExclVat] = useState(0.00);
  const [totalVat, setTotalVat] = useState(0.00);
  const [totalInclVat, setTotalInclVat] = useState(0.00);
  const handleCloseCustomer = () => setShowCustomer(false);
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    Auth.getUserData()
      .then((res) => {
        setCompanyId(res.company.id);
        dispatch(stopLoading());
      })
      .catch();
  });

  const customerList =
    useSelector(selectCustomers);
  const contract =
    useSelector((state) => state.contracts.contract?.contract) || {};

  useEffect(() => {
    if (customerList.length > 0) {
      getCustomers();
    }
    dispatch(fetchCompanyCustomers());
    dispatch(getContractById(id));
    dispatch(getProducts());

    // eslint-disable-next-line
  }, [dispatch]);

  const getCustomers = () => {
    setCustomers(
      customerList?.map((customer) => ({
        label: customer.company_name,
        value: customer.id,
      }))
    );
  };

  useEffect(() => {
    if (customerList.length > 0) {
      getCustomers();
    }
    dispatch(getCustomerById(customerId));
    // eslint-disable-next-line
  }, [customerId, customerList]);

  useEffect(() => {
    if (firstReminder) {
      setDisableReminderButton(false);
    } else {
      setSecondReminder(null);
      setDisableReminderButton(true);
    }
  }, [firstReminder]);

  useEffect(() => {
    if (contract?.first_reminder) {
      setDefaultSetting(false);
    }
    setFirstReminder(contract?.first_reminder);
    setSecondReminder(true);

    //eslint-disable-next-line
  }, []);
  const storeContract = (values) => {
    if (id) {
      const newContract = {
        id: id,
        customer_id: values.customer_id,
        start_date: values.start_date
          ? moment(values.start_date).format("YYYY-MM-DD")
          : "",
        end_date: values.end_date
          ? moment(values.end_date).format("YYYY-MM-DD")
          : "",
        first_invoice_date: values.first_invoice_date
          ? moment(values.first_invoice_date).format("YYYY-MM-DD")
          : "",
        status: values.status,
        email: values.email,
        frequency: values.frequency,

        first_reminder: firstReminder,
        second_reminder: secondReminder,
        invoice_lines: values.invoice_lines,
        description: values.description,
      };
      Contract.updateContract(newContract)
        .then((res) => {
          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            // window.location.href = "/contracts";
          } else if (res.success === false) {
            setMessage({
              show: true,
              message: res.message,
              variant: "warning",
            });
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            setError(array);
          }
        })
        .catch((error) => console.log(error));
    } else {
      const newContracts = {
        ...values,
        start_date: values.start_date
          ? moment(values.start_date).format("DD-MM-YYYY")
          : "",
        end_date: values.end_date
          ? moment(values.end_date).format("DD-MM-YYYY")
          : "",
        first_invoice_date: values.first_invoice_date
          ? moment(values.first_invoice_date).format("DD-MM-YYYY")
          : "",
      };
      Contract.storeContract(newContracts)
        .then((res) => {
          dispatch(stopLoading()); // hide loading
          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            setError(array);
          }

          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            window.location.href = "/contracts";
          } else if (res.success === false) {
            setMessage({show: true, message: res.message, variant: "danger"});
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleDefaultSetting = () => {
    if (defaultSetting) {
      setFirstReminder(null);
      setSecondReminder(null);
      setDefaultSetting(false);
    } else {
      setDefaultSetting(true);
    }
  };

  const setAndReturnFormattedPrice = (invoiceLines, calculationType) => {
    let price = [];
    let totalVat = 0;
    if (invoiceLines) {
      for (let i = 0; i < invoiceLines?.length; i++) {
        price.push(invoiceLines[i]?.amountExclVat_field);
        totalVat =
          totalVat +
          (invoiceLines[i]?.amountExclVat_field / 100) * invoiceLines[i]?.vat_percentage;
      }
    }
    let amountExclVat = price.reduce((sum, newValue) => sum + newValue, 0);
    setTimeout(() => {
      setFormattedTotalPriceExclVat(amountExclVat);
      setFormattedTotalVat(totalVat);
      setFormattedTotalInclVat(amountExclVat + totalVat);
    }, 0);

    if (calculationType === 1) {
      return formattedTotalPriceExclVat;
    } else if (calculationType === 2) {
      return formattedTotalVat;
    } else if (calculationType === 3) {
      return formattedTotalInclVat;
    }
  };

  const customerDetails = (customerId) => {
    Company.getCompanyCustomer(customerId)
      .then((data) => {
        setCustomers(data.customer);
        console.log(data.customer, "data.customer");
      })
      .catch((error) => console.log(error));
  };

  const contractInvoiceLines = contract?.contract_invoice_lines || [
    {
      description: "",
      product_id: "",
      quantity: 1,
      price: 0,
      price_formatted: 0,
      vat_percentage: 0,
      amountExclVat_field: 0,
    },
  ];

  const initialcontractInvoiceLines = contractInvoiceLines.map(
    (invoiceLine) => ({
      amountExclVat_field: invoiceLine.price / 100,
      description: invoiceLine.description,
      price: invoiceLine.price,
      price_formatted: numberFormatter.format(invoiceLine.price / 100),
      product_id: invoiceLine.product_id,
      quantity: invoiceLine.quantity,
      vat_percentage: invoiceLine.vat_percentage,
    })
  );

  const initialValues = {
    id: id,
    customer_id: contract?.customer_id,
    start_date: contract?.start_date && parseISO(contract?.start_date),
    first_invoice_date:
      contract?.first_invoice_date && parseISO(contract?.first_invoice_date),
    status: contract?.status,
    email: contract?.email,
    frequency: contract?.frequency,
    end_date: contract?.end_date && parseISO(contract?.end_date),
    first_reminder: firstReminder,
    second_reminder: secondReminder,
    invoice_lines: initialcontractInvoiceLines,
    description: contract?.description,
    company_id: companyId,
  };

  // console.log(moment.tz(parseISO(contract?.start_date), "Europe/Amsterdam").format("YYYY-MM-DD"), "parseISO(contract?.start_date)parseISO(contract?.start_date)parseISO(contract?.start_date)");

  return (
    <>
      {!companyId && <Preloader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/contracts">
              {t("contracts")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {id ? t("edit") : t("Create")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{id ? t("Edit contract") : t("Create new contract")}</h4>
          <p className="mb-0">
            {id
              ? t("Edit an existing contract")
              : t("create a new contract for your company")}
          </p>
        </div>
      </div>

      <div className="row">
        {message.show ? (
          <AlertDismissible
            textMessage={message.message}
            variant={message.variant}
          />
        ) : (
          ""
        )}
        {error &&
          error.map((message, index) => {
            return (
              <AlertDismissible
                key={index}
                textMessage={message.message}
                variant="danger"
              />
            );
          })}
      </div>
      <Formik

        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            setSubmitting(false);
            storeContract(values);
          }, 1000);
        }}
        validationSchema={contractSchema}
      >
        {({
            errors,
            touched,
            handleSubmit,
            handleBlur,
            values,
            setFieldValue,
            isSubmitting,
            handleChange,
          }) => (
          <Form onSubmit={handleSubmit}>
            <ContractInformation
              setFieldValue={setFieldValue}
              customers={customers}
              setMessage={setMessage}
              customerId={customerId}
              setCustomerId={setCustomerId}
              handleBlur={handleBlur}
              errors={errors}
              values={values}
              touched={touched}
              setShowCustomer={setShowCustomer}
            />

            <InvoiceForm/>

            <PriceCard
              cardType="contract"
            />

            <InvoiceReminder
              setFirstReminder={setFirstReminder}
              defaultSetting={defaultSetting}
              disableReminderButton={disableReminderButton}
              firstReminder={firstReminder}
              handleDefaultSetting={handleDefaultSetting}
              secondReminder={secondReminder}
              setSecondReminder={setSecondReminder}
            />

            <div className="row my-3 mx-0">
              <div className="col-md-4 mb-3">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {id ? t("Update Contract") : t("Save Contract")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <CustomerModal
        show={showCustomerModal}
        handleClose={handleCloseCustomer}
        getCustomers={getCustomers}
        setModalMessage={setMessage}
        customerDetails={customerDetails}
        updateCustomer={customers}
      />
    </>
  );
}

export default CreateContract;
