import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLaptopHouse} from "@fortawesome/free-solid-svg-icons";
import {Form, InputGroup, Spinner} from "@themesberg/react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDebounce} from "react-use";
import Api from "../../libs/Api";

const AutoCompleteCompanyField = ({withData = false, value: selectedCompany, onChange}) => {
  const {t} = useTranslation();

  const [suggestions, setSuggestions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useDebounce(() => {
    if (!selectedCompany) {
      setCompanies([]);
      setSuggestions([]);
    }

    if ((selectedCompany?.label || '').match(`.+, ${selectedCompany?.cocNumber}`)) {
      return;
    }

    setIsSearching(true);
    Api.get("/api/company-suggestion/" + selectedCompany?.companyName)
      .then((response) => {
        const companyData = response?.data?.map((company, index) => ({
          label: `${company.companyName}, ${company.cocNumber}`,
          id: index,
          value: company.companyId,
          ...company
        }));

        setIsSearching(false);
        setCompanies(companyData);
        setSuggestions(companyData.slice(0, 5));
      })
      .catch((error) => {
        setIsSearching(false);
        console.error(error)
      });
  }, 500, [selectedCompany?.label]);

  function handleChange(searchInput = "") {
    let found = false;

    for (const currentCompany of companies) {
      if (currentCompany.label === searchInput) {
        onChange(currentCompany);
        found = true;

        if (withData) {
          Api.get("/api/company-details/" + currentCompany.value)
            .then((response) => {
              const mergedCompany = {...currentCompany, ...response.data};
              onChange(mergedCompany);
            });
        }

        break;
      }
    }

    if (!found) {
      onChange({label: searchInput, companyName: searchInput});
    }
  }

  return (
    <>
      <InputGroup>
        <InputGroup.Text>
          {isSearching ? (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">{t("Loading")}...</span>
            </Spinner>
          ) : (
            <FontAwesomeIcon icon={faLaptopHouse}/>
          )}
        </InputGroup.Text>
        <Form.Control
          list="companies"
          value={selectedCompany?.companyName || ''}
          onChange={(e) => handleChange(e.target.value)}
          type="text"
        />
      </InputGroup>
      <datalist id="companies">
        {suggestions &&
          suggestions?.map((suggestion, key) => (
            <option key={key} value={suggestion.label}/>
          ))}
      </datalist>
    </>
  );
};

export default AutoCompleteCompanyField;
