import moment from "moment-timezone";
import React from "react";
import {Button, Form, Image, Modal, Table} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {numberFormatter} from "../../libs/numberFormatter";

export default function OfferLayout({
                                      show,
                                      handleClose,
                                      today,
                                      offerexpirationDate,
                                      values,
                                      customerData,
                                      Template,
                                      totalPriceExcl,
                                      totalBtwPrice,
                                      totalPriceIncl,
                                    }) {
  const {t} = useTranslation();
  const company =
    useSelector((state) => state.userCompany?.user?.company) || {};
  const logo = useSelector(
    (state) => state.settings?.companySettings?.companySetting?.logo_url
  );
  const backgroundImg = Template?.background_image_url || "";

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Offer Layout")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          width: "210mfcm",
          height: "auto",
          fontFamily: "--bs-font-monospace",
          marginTop: Template && Template.margin_top ? Template.margin_top : 10,
          marginBottom:
            Template && Template.margin_bottom ? Template.margin_bottom : 10,
        }}
      >
        <div
          className="container text-black"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row">
            <div className="col-12">
              <Form.Group>
                {logo && (
                  <Image
                    src={logo}
                    style={{width: "200px", objectFit: "contain"}}
                    alt="logo"
                  />
                )}
              </Form.Group>
              <h1
                style={{fontFamily: "--bs-font-monospace"}}
                className=" mt-4 mb-2"
              >
                {company.name}
              </h1>
              <div style={{fontSize: "14px"}}>
                {company.street + " " + company.house_number}
                <br/>
                {company.postcode + " " + company.city + " " + company.country}
                <br/>
                {company.phone_number}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              <div className="text-center fs-5">
                <strong> {t("Offer details")}</strong>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div className="col-md-6">
              <div>
                <strong>{t("Offered to")}</strong>
                <hr style={{margin: "0px 0px 5px 0px", opacity: 0.5}}/>
              </div>
              {customerData && (
                <div style={{fontSize: "14px"}}>
                  <div>{customerData.company_name}</div>
                  <div>
                    {customerData.street + " " + customerData.house_number}
                    <br/>
                    {customerData.postcode +
                      " " +
                      customerData.city +
                      " " +
                      customerData.country}
                  </div>
                  <div>{customerData.phone_number}</div>
                </div>
              )}
            </div>
            <div className="col-6">
              <div style={{float: "right"}}>
                <div className="d-flex">
                  <strong>{t("Offer Date")}: </strong>
                  <p>
                    {moment.tz(today, "Europe/Amsterdam").format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="d-flex">
                  <strong>{t("Offer expiration date")}: </strong>
                  {moment
                    .tz(offerexpirationDate, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <h5 className="fw-bold">{t("Offer Notes")}:</h5>
            <div> {ReactHtmlParser(values?.offer_notes)}</div>
          </div>

          <div className="d-flex col-12 mt-5">
            <Table size="sm">
              <thead>
              <tr
                style={{
                  fontSize: " 0.8em",
                  lineHeight: "0.8",
                  backgroundColor:
                    Template && Template.theme_color_primary
                      ? Template.theme_color_primary
                      : "#78ad91",
                  color: "white",
                }}
              >
                <th className="text-center">SL#</th>
                <th className="text-center">{t("Description")}</th>
                <th className="text-center">{t("Quantity")}</th>
                <th className="text-center">{t("unit price")}</th>
                <th className="text-center">{t("VAT Percentage")}</th>
                <th className="text-center">{t("Total")}</th>
              </tr>
              </thead>
              <tbody>
              {values?.offer_line &&
                values?.offer_line.map((offerLine, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{offerLine.description}</td>
                      <td className="text-center">{offerLine.quantity}</td>
                      <td className="text-center">
                        {numberFormatter.format(offerLine.price / 100)}
                      </td>
                      <td className="text-center">
                        {offerLine.vat_percentage}%
                      </td>
                      <td className="text-center">
                        {numberFormatter.format(
                          (offerLine.quantity * offerLine.price) / 100
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="row mt-4 mb-4 justify-content-end">
            <div className="col-md-6">
              <div className="row">
                <div className="col-8">
                  <strong>{t("Total excl. VAT")}: </strong>
                </div>
                <div className="col-4">
                  <strong>{numberFormatter.format(totalPriceExcl)}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <strong>{t("Total VAT")}: </strong>
                </div>
                <div className="col-4">
                  <strong>{numberFormatter.format(totalBtwPrice)}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <strong>{t("Total incl. VAT")}:</strong>
                </div>
                <div className="col-4">
                  <strong> {numberFormatter.format(totalPriceIncl)}</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 my-5">
            <h5 className="fw-bold">{t("Purchase Conditions")}:</h5>
            <div>{ReactHtmlParser(values?.offer_purchase_conditions)}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
