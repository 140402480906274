import React from "react";
import {useTranslation} from "react-i18next";

const InvoiceLineHeadRow = () => {
  const {t} = useTranslation();

  return (<tr>
    <th className="text-center">{t("Name")}</th>
    <th className="text-center">{t("Quantity")}</th>
    <th className="text-center">{t("price")}</th>
    <th className="text-center">{t("VAT Percentage")}</th>
    <th className="text-center">{t("price excl. VAT")}</th>
  </tr>);
}

export default InvoiceLineHeadRow;