import Api from "../../libs/Api";

const clientId = process.env.REACT_APP_CLIENT_ID || 2;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || "dtUo4Ml9GdOBo9EEhaD95q7BIRR0Nnwq5O4vgcyJ";

class ResellerAuth {
  login = (email, password) => {
    return new Promise((resolve, reject) => {
      Api.post("/oauth/token", {
        grant_type: "password",
        client_id: clientId,
        client_secret: clientSecret,
        username: email,
        password: password,
        scope: "*",
      })
        .then((response) => {
          if (
            response.data.access_token !== undefined &&
            response.data.refresh_token !== undefined
          ) {
            this.setRefreshToken(response.data.refresh_token);
            this.setToken(response.data.access_token);
            this.getUserData();
            resolve();
          }
          reject(response.data.errors || []);
        })
        .catch((error) =>
          reject((((error || {}).response || {}).data || {}).errors || [])
        );
    });
  };

  getToken = () => localStorage.getItem("token");

  setToken = (token) => localStorage.setItem("token", token);

  isResellerLoggedIn = () =>
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined;
}

export default new ResellerAuth();
