/**
 *
 * @param {int} amount
 * @param {string} formatString
 * @param {string} style
 * @param {string} currency
 * @param  {object} options
 * @returns format string
 */
import {format} from 'date-fns';

export function formatCurrency(amount, formatString, style, currency, ...options) {
  return new Intl.NumberFormat(formatString, {
    style,
    currency,
    ...options
  }).format(amount);
}

export function isoDateToYmd(isoDate) {
  return format(isoDate, 'yyyy-MM-dd');
}

export function isoDateToDmy(isoDate) {
  return format(isoDate, 'dd-MM-yyyy');
}