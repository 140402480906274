import Auth from "../../auth/libs/Auth";
import Api from "../../libs/Api";

import {userFetched, userFetchingFailed, userFetchingStarted} from "../reducers/userCompany";
import {OpenFeature} from "@openfeature/react-sdk";

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch(userFetchingStarted())

    try {
      if (Auth.isLoggedIn()) {
        const response = await Api.get(`/api/user`);
        dispatch(userFetched(response.data))

        const {user} = response.data;
        await OpenFeature.setContext({targetingKey: user?.id, traits: user});
      }
    } catch (e) {
      console.log(e.response);
      dispatch(userFetchingFailed(e?.response?.data?.message))
    }
  };
};
