import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  messages: []
};

export const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageShown: (state, action) => {
      const {message, variant} = action.payload;
      state.messages.push({message, variant})
    },
    messageDismissed: (state, action) => {
      state.messages.splice(action.payload, 1)
    }
  },
})

export const selectMessages = (state) => state.message.messages
export const selectWarnings = (state) => state.message.messages.filter(message => message.variant === 'warning')
export const selectErrors = (state) => state.message.messages.filter(message => message.variant === 'danger')

export const {messageShown, messageDismissed} = message.actions

export default message.reducer