import React, {useEffect, useState} from "react";
import Subscription from "../libs/Subscription";
import SubscriptionDetail from "./SubscriptionDetail";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomSpinner from "../../components/CustomSpinner";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptions} from "../../store/actions/subscriptions";
import {selectUser} from "../../store/reducers/userCompany";

export default function ChangeSubscription() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [subscriptionModel, setSubscriptionModel] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const subscriptions = useSelector((state) => state.subscriptions.subscriptions.subscriptions) || [];
  const user = useSelector(selectUser) || {};
  const resellerActiveSubscription = user.companySubscription?.subscription_id;
  const handleClose = () => setModal(false);
  const handelShow = () => setModal(true);

  const handleSubscriptionClick = (subscription) => {
    setSubscriptionModel((prev) => !prev);
    setSelectedSubscription(subscription);
    handelShow();
  };
  const saveNewSubscription = () => {
    // Assuming Subscription.updateCompanySubscription returns a Promise
    Subscription.updateCompanySubscription(selectedSubscription)
      .then(() => {
        // Subscription updated successfully
        handleClose();
      })
      .catch((error) => {
        // Check if the error is a 403 Forbidden error
        if (error.response && error.response.status === 403) {
          // Handle 403 error, show a message to the user
          alert('Permission Denied: You do not have the necessary permissions to update the subscription.');
          window.location.reload()
        } else {
          alert('An error occurred while updating the subscription. Please try again later.');
        }
      });
  };


  useEffect(() => {
    if (user?.userType !== "reseller") fetchData();
    dispatch(getSubscriptions());
    // eslint-disable-next-line
  }, []);

  let fetchData = async () => {
    try {
      const activeSub = Subscription.getCompanyActiveSubscription();
      if (activeSub) {
        activeSub.then(async (res) => {
          await setActiveSubscription(res.companySubscription.subscription_id);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h4 className="my-4">{t("Change Subscription")}</h4>

      {subscriptions.length ? (
        <div className="row">
          {subscriptions &&
            subscriptions.map((subscription, index) => {
              return (
                <SubscriptionDetail
                  title={activeSubscription === subscription.id ? t("Current Plan") : "Plan"} key={index}
                  subscription={subscription} handleSubscriptionClick={() => {
                  handleSubscriptionClick(subscription)
                }}
                  className={
                    (activeSubscription === subscription.id ||
                      resellerActiveSubscription === subscription.id) && "active-border"
                  }
                />
              );
            })}
        </div>
      ) : (
        <CustomSpinner/>
      )}

      {subscriptionModel && (
        <Modal centered show={modal} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Change Subscription")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>{t("Are you sure you want to change subscription")}?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={saveNewSubscription}>
              {t("Save")}
            </Button>
            <Button variant="danger" onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}