import React, {useEffect, useState} from 'react';
import Subscription from '../libs/Subscription';
import ReactGA from "react-ga";
import CustomSpinner from '../../components/CustomSpinner';
import {useSelector} from "react-redux";
import {selectActiveCompanyId} from "../../store/reducers/userCompany";


function AdminPaymentSuccess() {
  const [firstPaymentId, setFirstPaymentId] = useState("");
  const [paymentMessage, setPaymentMessage] = useState("");
  const companyId = useSelector(selectActiveCompanyId);

  useEffect(() => {
    Subscription.getFirstPayment(companyId)
      .then(res => {
        setFirstPaymentId(res.payment_id);
      }).catch(error => console.log(error));
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (firstPaymentId) {
      Subscription.checkPaymentStatus(companyId, firstPaymentId)
        .then(res => {
          if (res.paymentSuccessful === true) {
            setPaymentMessage(res.message);
            if (res.payment && res.payment.amount && res.payment.amount.value) {
              let amount = res.payment.amount.value;
              ReactGA.event({
                action: "Purchase",
                label: "Admin Purchase",
                value: amount
              });
              window.dataLayer.push({
                event: 'event',
                eventProps: {
                  action: "Purchase",
                  category: "Purchase",
                  label: "Admin Purchase",
                  value: amount
                }
              });
            }
          } else if (res.paymentSuccessful === false) {
            setPaymentMessage(res.message);
          }

        }).catch(error => console.log(error));
    }
    //eslint-disable-next-line
  }, [firstPaymentId]);

  return (
    <>
      <div className="card-body p-0">
        <div className="row justify-content-center">

          <div className="col-lg-7">
            <div className="p-5">
              <div className="d-flex justify-content-center thanksText">
                {paymentMessage ? <p className="h4 my-auto" dangerouslySetInnerHTML={{__html: paymentMessage}}></p> :
                  <CustomSpinner/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPaymentSuccess;