import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Modal} from "@themesberg/react-bootstrap";
import Template from './TemplateService'
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import PopOver from "./PopOver";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../../components/old/AlertDismissible";
import {messageShown} from "../../../store/reducers/message";
import {useDispatch} from "react-redux";

const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

function CreateTemplate({
                          show,
                          handleClose,
                          templateId,
                          setRefreshData
                        }) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [marginTop, setMarginTop] = useState(null);
  const [marginBottom, setMarginBottom] = useState(null);
  const [Name, setName] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [showLogo, setshowLogo] = useState(true);
  const [closeText, setCloseText] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [isDefault, setIsDefault] = useState("");
  const [message, setMessage] = useState();
  const [primaryPickerShow, setPrimaryPickerShow] = useState(false);
  const [secondaryPickerShow, setSecondaryPickerShow] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [viewBackgroundImage, setViewBackgroundImage] = useState("");

  useEffect(() => {
    if (templateId) {
      Template.getTemplate(templateId).then((data) => {
        if (data && data.template) {
          setName(data.template.name);
          setMarginTop(data.template.margin_top);
          setMarginBottom(data.template.margin_bottom);
          setshowLogo(data.template.show_logo === 1);
          setPrimaryColor(data.template.theme_color_primary);
          setIsDefault(data.template.is_default === 1);
          setSecondaryColor(data.template.theme_color_secondary);
          setCloseText(data.template.closing_text);
          setBackgroundImage(data.template.background_image);
          let backgroundImageUrl = data.template.background_image_url.split("/");
          if (backgroundImageUrl.length >= 5) {
            setViewBackgroundImage(data.template.background_image_url);
          }
        }
      });
    } else {
      clearForm();
    }
  }, [templateId]);

  const clearForm = () => {
    setName("");
    setMarginTop(null);
    setMarginBottom(null);
    setBackgroundImage("");
    setIsDefault("");
    setshowLogo("");
    setCloseText("");
    setPrimaryColor("");
    setSecondaryColor("");
  }

  const createORUpdateTemplate = () => {
    if (
      Name.trim() === "" ||
      marginTop === "" || marginTop === null ||
      marginBottom === "" || marginBottom === null ||
      primaryColor.trim() === "" ||
      secondaryColor.trim() === ""
    ) {
      setMessage({message: t("Please fill all required fields"), variant: "danger"});
      return;
    }
    let formData = new FormData();
    if (templateId) {
      formData.append("id", templateId);
    }

    formData.append("name", Name);
    formData.append("margin_top", marginTop ? parseInt(marginTop) : 0);
    formData.append("margin_bottom", marginBottom ? parseInt(marginBottom) : 0);
    formData.append("show_logo", !!showLogo);
    formData.append("theme_color_primary", primaryColor);
    formData.append("theme_color_secondary", secondaryColor);
    formData.append("background_image", backgroundImage ? backgroundImage[0] : "");
    formData.append("closing_text", closeText);
    formData.append("is_default", !!isDefault);
    if (templateId) {
      Template.UpdateTemplate(templateId, formData).then((data) => {
        setRefreshData(true);
        dispatch(messageShown({message: data.message, success: data.success, variant: "success"}));
      }).catch((error) => {
        dispatch(messageShown({message: error.message, variant: "danger"}));
        return;
      })
      handleClose();
    } else {
      Template.CreateTemplate(formData).then((data) => {
        dispatch(messageShown({message: data.message, success: data.success, variant: "success"}));
        setRefreshData('create');//set "create" string value to set last temmplate id for auto select after create new template
      }).catch((error) => {
        setMessage({message: error.message, variant: "danger"});
        return;
      });

      clearForm();
      handleClose();
    }
  };


  //Get window dimensions
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      <Modal enforceFocus={false} show={show} onHide={() => handleClose()} size="lg"
             style={{height: windowDimensions.width <= 768 ? (windowDimensions.width > 540 ? '90vh' : '70vh') : ''}}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{templateId ? t("update template") : t("New Template")}</Modal.Title>
          </Modal.Header>
          {message && (
            <AlertDismissible textMessage={message.message} variant={message.variant}
                              onAfterClose={() => setMessage(null)}/>
          )}
          <Modal.Body>
            <div className="row">
              <Form.Group className="col">
                <Form.Label>{t("Name")}*</Form.Label>
                <Form.Control
                  type="text"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

            </div>
            <div className="row mt-2">
              <Form.Group className="col-md-6">
                <Form.Label>{t("Margin Top")}*</Form.Label>
                <Form.Control
                  type="number"
                  value={marginTop || ''}
                  onChange={(e) => setMarginTop(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>{t("Margin Bottom")}*</Form.Label>
                <Form.Control
                  type="number"
                  value={marginBottom || ''}
                  onChange={(e) => setMarginBottom(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="mt-2">
              <Form.Group className="row">
                <div className="col-md-3">
                  <Form.Label>{t("Primary Theme Color")}*</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" style={{width: '25%'}}>
                      <span style={{width: '100%', height: 20, backgroundColor: primaryColor}}></span>
                    </InputGroup.Text>
                    <InputGroup.Text style={{width: '75%'}}>
                      <PopOver color={primaryColor} pickerShow={primaryPickerShow} setPickerShow={setPrimaryPickerShow}
                               handleColorChange={setPrimaryColor}/>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <Form.Label>{t("Secondary Theme Color")}*</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" style={{width: '25%'}}>
                      <span style={{width: '100%', height: 20, backgroundColor: secondaryColor}}></span>
                    </InputGroup.Text>
                    <InputGroup.Text style={{width: '75%'}}>
                      <PopOver color={secondaryColor} pickerShow={secondaryPickerShow}
                               setPickerShow={setSecondaryPickerShow} handleColorChange={setSecondaryColor}/>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <Form.Label>{t("Set Default")}</Form.Label>
                  <div className="mt-2">
                    <BootstrapSwitchButton
                      checked={isDefault}
                      onlabel="Yes"
                      onstyle="success"
                      offlabel="No"
                      offstyle="danger"
                      onChange={(checked) => {
                        setIsDefault(checked);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <Form.Label>{t("Show Logo")}*</Form.Label>
                  <br/>
                  <div className="mt-2">
                    <BootstrapSwitchButton
                      checked={showLogo}
                      onlabel="Yes"
                      onstyle="success"
                      offlabel="No"
                      offstyle="danger"
                      onChange={(checked) => {
                        setshowLogo(checked);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row mt-2">
              <Form.Group className="col-md-12">
                <Form.Label>{t("Closing text")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  value={closeText || ""}
                  onChange={(e) => setCloseText(e.target.value)}
                />
              </Form.Group>

            </div>
            <div className="row mt-2">
              <Form.Group className="col-md-12">
                <Form.Label>{t("Background Image")}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setBackgroundImage(e.target.files)}
                />
              </Form.Group>
              <div className="image-preview col-md-12 text-center">
                {viewBackgroundImage && <img src={`${viewBackgroundImage}`} width="100" height="100" alt="background"/>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleClose()}>
              {t("Close")}
            </Button>
            <Button variant="primary" onClick={() => createORUpdateTemplate()}>
              {templateId ? t("update template") : t("create template")}
            </Button>

          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateTemplate;
