import React, {useState} from "react";
import {Modal, Form, Button} from "@themesberg/react-bootstrap";
import Customer from "../lib/Customer";
import {useTranslation} from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import {Field, Formik} from "formik";
import {contactPersonSchema} from "../../validation";
import {useDispatch} from "react-redux";
import {messageShown} from "../../store/reducers/message";

function ContactPersonModal({
                              show,
                              handleClose,
                              handleSave,
                              contactPersonData = {},
                              customerId,
                              contactPersonId = null,
                            }) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);

  const contactPersonUpdated = (res) => {
    dispatch(messageShown({
      message: res.message,
      variant: res.success ? "success" : "warning",
    }));

    if (res.success) {
      if (handleSave) {
        handleSave(res.contact_person);
      }

      if (handleClose) {
        handleClose();
      }
    }

    if (res.errors) {
      setErrors(res.errors.map((error) => ({
        message: error[0],
        variant: "danger",
      })));
    }
  }

  const storeContactPerson = (values) => {
    if (contactPersonId) {
      Customer.updateCustomerContactPerson(customerId, values).then(contactPersonUpdated);
    } else {
      Customer.createCustomerContactPerson(customerId, values).then(contactPersonUpdated);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {contactPersonId ? t("Edit Contact Person") : t("New Contact Person")}
        </Modal.Title>
      </Modal.Header>
      {errors &&
        errors.map((message, index) => {
          return (
            <AlertDismissible
              key={index}
              type="validation"
              textMessage={message.message}
              index={index}
              variant="danger"
            />
          );
        })}

      <Formik
        enableReinitialize
        initialValues={{
          id: contactPersonId,
          prefix: contactPersonData?.prefix,
          first_name: contactPersonData?.first_name,
          last_name: contactPersonData?.last_name,
          email: contactPersonData?.email,
          phone_number: contactPersonData?.phone_number,
        }}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            setSubmitting(false);
            storeContactPerson(values);
          }, 1000);
        }}
        validationSchema={contactPersonSchema}
      >
        {({setFieldValue, errors, touched, handleSubmit, isSubmitting}) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mt-2">
                    <Form.Label>{t("First Name")}</Form.Label>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="first_name"
                    />
                    {errors.first_name && touched.first_name ? (
                      <span className="error-message">
                        {t(errors.first_name)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t("Prefix")}</Form.Label>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="prefix"
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>{t("Last Name")}</Form.Label>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="last_name"
                    />
                    {errors.last_name && touched.last_name ? (
                      <span className="error-message">
                        {t(errors.last_name)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="email"
                    />
                    {errors.email && touched.email ? (
                      <span className="error-message">{t(errors.email)}</span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>{t("Phone Number")}</Form.Label>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="phone_number"
                    />
                    {errors.phone_number && touched.phone_number ? (
                      <span className="error-message">
                        {t(errors.phone_number)}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {contactPersonId ? t("Update") : t("Create new")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ContactPersonModal;
