import React from "react";
import {
  Login,
  Activate,
  Register,
  ForgotPassword,
  ResetPassword,
  ThankyouForRegistering,
  CreateFirstInvoice,
  FirstInvoiceSuccess,
} from "./auth";
import {Dashboard} from "./admin";
import {Invoices, CreateInvoice, EditInvoice, ViewInvoice} from "./invoices";
import {Products, CreateOrEditProduct} from "./products";
import {Customers, CustomerDetails} from "./customers";
import {
  Settings,
  TemplateSettings,
  CompanySettings,
  InvoiceSettings,
  OfferSettings,
  CreateTemplate,
} from "./settings";
import {Profile} from "./profile";
import {
  Subscriptions,
  FirstPaymentSuccess,
  CompanySubscription,
  AdminPaymentSuccess,
} from "./subscription";
import {
  Contracts,
  CreateContract,
  ContractDetails,
} from "./contracts";
import ChangeSubscription from "./subscription/components/ChangeSubscription";
import {
  Offers,
  CreateOffer,
  ViewOffer,
  OfferDetails,
  ConfirmAcceptOffer,
} from "./offers"
import ResellerUser from "./reseller/Users/ResellerUser"
import ResellerLogin from "./reseller/auth/ResellerLogin";
import ResellerSubscription from "./reseller/subscription/ResellerSubscription";
import UserDetails from "./reseller/Users/UserDetails";
import ResellerProfile from "./reseller/ResellerProfile";
import StoreCompany from "./reseller/Companies/StoreCompany";
import StoreUser from "./reseller/Users/StoreUser";


const loggedInRoutes = [
  {
    name: "Dashboard",
    path: "/",
    exact: true,
    component: (props) => <Dashboard/>,
  },
  {
    name: "Invoice",
    path: "/invoices",
    exact: true,
    component: (props) => <Invoices/>,
  },
  {
    name: "Create Invoice",
    path: "/create-invoice",
    component: (props) => <CreateInvoice {...props} />,
  },
  {
    name: "Create Template",
    path: "/create-template",
    component: (props) => <CreateTemplate {...props} />,
  },
  {
    name: "Edit Invoice",
    path: "/edit-invoice/:id",
    component: (props) => <EditInvoice {...props} />,
  },
  {
    name: "View Invoice",
    path: "/view-invoice/:id",
    component: (props) => <ViewInvoice {...props} />,
  },
  {
    name: "Customers",
    path: "/customers",
    component: (props) => <Customers/>,
  },
  {
    name: "Settings",
    path: "/settings",
    component: (props) => <Settings/>,
  },
  {
    name: "Profile",
    path: "/profile",
    component: (props) => <Profile/>,
  },
  {
    name: "Subscription Details",
    path: "/company-subscription",
    component: (props) => <CompanySubscription/>,
  },
  {
    name: "Change Subscription",
    path: "/change-subscription",
    component: (props) => <ChangeSubscription/>,
  },
  {
    name: "Company Settings",
    path: "/company-settings",
    component: (props) => <CompanySettings/>,
  },
  {
    name: "Template Settings",
    path: "/template-settings",
    component: (props) => <TemplateSettings/>,
  },
  {
    name: "Customer Details",
    path: "/customer-details/:id",
    component: (props) => <CustomerDetails/>,
  },
  {
    name: "Invoice Settings",
    path: "/invoice-settings",
    component: (props) => <InvoiceSettings/>,
  },
  {
    name: "Contracts",
    path: "/contracts",
    component: (props) => <Contracts {...props} />,
  },
  {
    name: "Products",
    path: "/products",
    component: (props) => <Products {...props} />,
  },
  {
    name: "Create Product",
    path: "/create-product",
    component: (props) => <CreateOrEditProduct {...props} />,
  },
  {
    name: "Edit Product",
    path: "/edit-product/:id",
    component: (props) => <CreateOrEditProduct {...props} />,
  },
  {
    name: "Create Contract",
    path: "/create-contract",
    component: (props) => <CreateContract {...props} />,
  },
  {
    name: "Edit Contract",
    path: "/edit-contract/:id",
    component: (props) => <CreateContract {...props} />,
  },
  {
    name: "Contract Details",
    path: "/contract-details/:id",
    component: (props) => <ContractDetails {...props} />,
  },
  {
    name: "First Payment Success",
    path: "/first-payment-success",
    component: (props) => <AdminPaymentSuccess {...props} />,
  },
  {
    name: "Offers",
    path: "/offers",
    component: (props) => <Offers/>,
  },
  {
    name: "Create Offer",
    path: "/create-offer",
    component: (props) => <CreateOffer/>,
  },
  {
    name: "View Offer",
    path: "/view-offer/:id",
    component: (props) => <ViewOffer/>,
  },
  {
    name: "Edit Offer",
    path: "/edit-offer/:id",
    component: (props) => <CreateOffer/>,
  },
  {
    name: "Offer Settings",
    path: "/offer-settings",
    component: (props) => <OfferSettings/>,
  },
  {
    name: "Confirm Accept Offer",
    path: "/confirm-accept-offer/:token",
    component: (props) => <ConfirmAcceptOffer/>,
  },
  {
    name: "Offer Details",
    path: "/offer-details/:uuid",
    component: (props) => <OfferDetails/>,
  },
];

const guestRoutes = [
  {
    name: "Login",
    path: "/",
    exact: true,
    component: (props) => <Login {...props} />,
  },
  {
    name: "Thank You Register",
    path: "/thank-you-register",
    component: (props) => <ThankyouForRegistering {...props} />,
  },
  {
    name: "First Invoice Wizard",
    path: "/first-invoice-wizard",
    component: (props) => <CreateFirstInvoice {...props} />,
  },
  {
    name: "Verify",
    path: "/verify",
    component: (props) => <Activate {...props} />,
  },
  {
    name: "Register",
    path: "/register",
    component: (props) => <Register {...props} />,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: (props) => <ForgotPassword {...props} />,
  },
  {
    name: "Reset Password",
    path: "/reset-password/:token",
    component: (props) => <ResetPassword {...props} />,
  },
  {
    name: "Choose Subscription",
    path: "/choose-subscription",
    component: (props) => <Subscriptions {...props} />,
  },
  {
    name: "First Invoice Success",
    path: "/first-invoice-success",
    component: (props) => <FirstInvoiceSuccess {...props} />,
  },
  {
    name: "First Payment Success",
    path: "/first-payment-success",
    component: (props) => <FirstPaymentSuccess {...props} />,
  },
  {
    name: "Reseller Login",
    path: "/reseller/login",
    exact: true,
    component: (props) => <ResellerLogin {...props} />,
  },
  {
    name: "Offer Details",
    path: "/offers/received/:uuid",
    component: (props) => <OfferDetails/>,
  },
  {
    name: "Confirm Accept Offer",
    path: "/offers-confirm/:token",
    component: (props) => <ConfirmAcceptOffer/>,
  },
];

const resellerRoutes = [
  {
    name: "Reseller",
    path: "/",
    exact: true,
    component: (props) => <ResellerUser/>,
  },
  {
    name: "Reseller User",
    path: "/reseller/user",
    exact: true,
    component: (props) => <ResellerUser/>,
  },
  {
    name: "Settings",
    path: "/settings",
    exact: true,
    component: (props) => <Settings/>,
  },
  {
    name: "Company Settings",
    path: "/company-settings",
    exact: true,
    component: (props) => <CompanySettings/>,
  },
  {
    name: "Create User",
    path: "/reseller/create-user",
    exact: true,
    component: (props) => <StoreUser/>,
  },
  {
    name: "Update User",
    path: "/reseller/update-user/:id",
    exact: true,
    component: (props) => <StoreUser/>,
  },
  {
    name: "Create Company",
    path: "/reseller/create-company",
    exact: true,
    component: (props) => <StoreCompany/>,
  },
  {
    name: "Update Company",
    path: "/reseller/update-company/:id",
    exact: true,
    component: (props) => <StoreCompany/>,
  },
  {
    name: "User Subscription ",
    path: "/reseller/subscription",
    exact: true,
    component: (props) => <ResellerSubscription/>,
  },
  {
    name: "User Details ",
    path: "/reseller/user-details/:id",
    exact: true,
    component: (props) => <UserDetails/>,
  },
  {
    name: "Subscription Details",
    path: "/reseller/company-subscription",
    component: (props) => <CompanySubscription/>,
  },
  {
    name: "Change Subscription",
    path: "/reseller/change-subscription",
    component: (props) => <ChangeSubscription/>,
  },
  {
    name: "Reseller Profile",
    path: "/reseller/profile",
    exact: true,
    component: (props) => <ResellerProfile/>,
  },
  {
    name: "Subscription ",
    path: "/reseller/subscription-details",
    exact: true,
    component: (props) => <CompanySubscription/>,
  },
];

export {loggedInRoutes, guestRoutes, resellerRoutes};
