import Api from "../../libs/Api";
import {
  GET_RESELLER_USER_BY_ID_FAILED,
  GET_RESELLER_USER_BY_ID_START,
  GET_RESELLER_USER_BY_ID_SUCCESS,
} from "./actionTypes";

export const getResellerUserById = (userId) => {

  return async (dispatch) => {
    dispatch({
      type: GET_RESELLER_USER_BY_ID_START,
    });

    try {
      const response = await Api.get(`/api/reseller/user/${userId}`);

      dispatch({
        payload: response.data,
        type: GET_RESELLER_USER_BY_ID_SUCCESS,
      });

    } catch (e) {
      console.log(e.response);
      dispatch({
        payload: e?.response?.data?.message,
        type: GET_RESELLER_USER_BY_ID_FAILED,
      });
    }
  };
};
