import React from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {numberFormatter} from "../../libs/numberFormatter";

function SubscriptionDetail({
                              subscription,
                              handleSubscriptionClick,
                              className,
                              title,
                            }) {
  const {t} = useTranslation();
  return (
    <>
      <div className="col-md-3 mx-md-5">
        <div
          className={`card-body shadow mb-5 bg-white rounded subscription-card ${className}`}
        >
          <h6 className="h6 text-center">{title}</h6>
          <h2 className="text-center">{subscription.name}</h2>
          <h6 className="h6 text-center">{t("Monthly")}</h6>
          <p className="text-center mb-0">
            {subscription.num_invoices} {t("invoices")}
          </p>
          <h4 className="text-center mb-0">
            {numberFormatter.format(subscription.price / 100)}
          </h4>
          <div className="row mt-3">
            <div className="col-md-2">
              {title === t("Current Plan") ? (
                <Button className="disable-btn">{t("Choose")}</Button>
              ) : (
                <Button
                  className="btn-warning btn-sm px-4 py-2"
                  onClick={() => handleSubscriptionClick(subscription.id)}
                >
                  Choose
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionDetail;

