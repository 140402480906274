import {
  RESET_INVOICE,
  SET_INVOICE_CUSTOMER,
  SET_INVOICE_CUSTOMER_ID
} from "../actions/actionTypes";

const initialState = {
  customer: {},
  customerId: null,
  templates: [],
  template: {},
  templateId: null,
  invoiceLines: [],
  errors: [],
  touched: false,
  values: [],
  isSubmitting: false,
  isValidating: false,
};

const invoice = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case SET_INVOICE_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    case RESET_INVOICE:
      return {
        ...initialState
      }
    default:
      return state;
  }
};

export default invoice;