import Api from "../../libs/Api";
import Auth from '../../auth/libs/Auth';

class Customer {
  createCompanyCustomer = () => {
    let companyId = Auth.getActiveCompanyId();

    return new Promise((resolve, reject) => {
      Api.get("/api/company/" + companyId + "/products")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getCustomer = (customerId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/customers/" + customerId)
        .then((response) => {
          if (response.data) {
            console.log(response.data, "response.data");
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  fetchCustomerContactPersons = (customerId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/customers/" + customerId + "/contact-persons")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getCustomerContactPerson = (CustomerId, ContactPersonId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/customers/" + CustomerId + "/contact-persons/" + ContactPersonId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }


  createCustomerContactPerson = (customerId, contactPersonData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/customers/" + customerId + "/contact-persons", contactPersonData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  updateCustomerContactPerson = (customerId, contactPersonData) => {
    const contactPersonId = contactPersonData.id;
    return new Promise((resolve, reject) => {
      Api.put("/api/customers/" + customerId + "/contact-persons/" + contactPersonId, contactPersonData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  deleteCustomerContactPerson = (customerId, contactPersonId) => {
    return new Promise((resolve, reject) => {
      Api.delete("/api/customers/" + customerId + "/contact-persons/" + contactPersonId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }
}

export default new Customer();
