import React, {useEffect, useState} from "react";
import {SelectCompanySchema} from "../../validation";
import {useDispatch, useSelector} from "react-redux";
import ResellerAction from "../libs/ResellerAction";
import {Formik} from "formik";
import {Button, Form, InputGroup} from "@themesberg/react-bootstrap";
import Select from "react-select";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function SelectCompany() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [company, setCompany] = useState([]);
  const companyData = useSelector((state) => state?.reseller?.reseller?.user?.companies) || [];
  const userId = localStorage.getItem("userId");

  const resellerData = useSelector((state) => state?.reseller);

  useEffect(() => {
    setCompany(
      companyData?.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    );
    console.log('companydata' + resellerData?.reseller?.user)
    // eslint-disable-next-line
  }, [resellerData?.reseller?.user]);

  useEffect(() => {
    ResellerAction.getResellerCompanies()
      .then(res => {
        setCompany(
          res.companies?.map((company) => ({
            label: company.name,
            value: company.id,
          }))
        );
      }).catch(error => {
      console.log(error);
    })
  }, [])


  const SelectCompany = ({select_company}) => {
    const companyData = {
      company_id: select_company,
    };

    dispatch(startLoading());
    ResellerAction.selectCompany(userId, companyData)
      .then((res) => {
        dispatch(stopLoading());
        history.push("/reseller/subscription");
      })
      .catch((error) => {
        dispatch(stopLoading());
        console.log(error);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        select_company: "",
      }}
      onSubmit={SelectCompany}
      validationSchema={SelectCompanySchema}
    >
      {({setFieldValue, errors, touched, handleSubmit, isSubmitting, values, handleBlur}) => (
        <Form className="user w-100" onSubmit={handleSubmit}>
          <Form.Group className="mb-4 mt-3">
            <Form.Label>{t("Select Company")} </Form.Label>
            <InputGroup>
              <Select
                name="select_company"
                placeholder={t("select")}
                className="form-control p-0"
                options={company}
                value={company?.filter(
                  (company) => company.value === values.select_company
                )}
                onChange={(e) => setFieldValue("select_company", e.value)}
                onBlur={handleBlur("select_company")}
              />
            </InputGroup>
            {errors.select_company && touched.select_company ? (
              <span className="error-message">{t(errors.select_company)}</span>
            ) : null}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="col-2" type="submit" disabled={isSubmitting}>
              {t("Next")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
