import React, {useState} from "react";
import SimpleBar from 'simplebar-react';
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button, Image, Nav, Navbar} from '@themesberg/react-bootstrap';
import {Link} from 'react-router-dom';

// import ProfilePicture from "../../../assets/img/team/profile-picture-5.jpg";
import FactuursnelLogo2 from '../../../assets/img/logo.png';
import Auth from "../../../auth/libs/Auth";
import {useSelector} from "react-redux";
import {selectUser} from "../../../store/reducers/userCompany";

export default function Sidebar({children}) {
  const [show, setShow] = useState(false);
  const user = useSelector(selectUser) || {};
  const firstName = user.user?.first_name;
  const lastName = user.user?.last_name;
  const resellerId = user.user?.reseller_id;
  const logo = user.reseller?.companies[0]?.company_setting?.logo_url;
  const showClass = show ? "show" : "";

  const logout = () => {
    Auth.logout()
      .then(response => {
        window.location.href = "/";
      }).catch(error => console.log(error));
  };
  const onCollapse = () => setShow(!show);

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        {resellerId ? (
          <div>
            <img src={logo} alt="logo" className="reseller-logo"/>
          </div>
        ) : (
          <Navbar.Brand className="me-lg-5" as={Link} to="/">
            <Image src={FactuursnelLogo2} className="navbar-brand-light"/>
          </Navbar.Brand>
        )}
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon"/>
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div
              className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                {/* <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div> */}
                <div className="d-block">
                  <h6>Hi, {`${firstName} ${lastName}`}</h6>
                  <Button onClick={(e) => logout()} variant="secondary" size="xs" to="/" className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2"/> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes}/>
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {children}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
