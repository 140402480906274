import React from 'react';
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";
import {useTranslation} from 'react-i18next';

function FirstInvoiceSuccess() {
  document.body.classList.add('bg-gradient-primary');
  const {t} = useTranslation();

  return (
    <Container className="full-height-container ">
      <>
        <div className="col-md-12 card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row justify-content-center">

              <div className="col-lg-9">
                <div className="p-5">
                  <div className="d-flex justify-content-center thanksText">
                    <p className="h1 my-auto">{t("Your first invoice created successfully")}!</p>
                  </div>
                  <form
                    className="user"
                  >
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-4 mb-3 mb-sm-0 ">
                        <Link to="/choose-subscription">
                          <button
                            className="btn btn-primary btn-user btn-block p-1 py-2 next-button"
                          >
                            {t("Choose Your Subscription")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Container>
  );
}

export default FirstInvoiceSuccess;