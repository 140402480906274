import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {startLoading, stopLoading} from "../../store/actions/loading";
import {ResellerCompanySchema} from "../../validation";
import ResellerAction from "../libs/ResellerAction";
import SelectCompany from "./SelectCompany";

export default function StoreCompany() {
  const {t} = useTranslation();
  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [country, setCountry] = useState(t("Netherlands"));
  const [isChecked, setIsChecked] = useState(false);
  const companyInfo =
    useSelector((state) => state.reseller.reseller.user?.companies[0]) || {};
  const userId = localStorage.getItem("userId");
  const companyId = localStorage.getItem("companyId");

  //console.log(companyId);

  useEffect(() => {
    if (id) {
      setIsChecked(true)
    }
  }, [id]);

  const storeResellerCompany = ({
                                  name,
                                  email,
                                  website_url,
                                  phone_number,
                                  street,
                                  house_number,
                                  coc_number,
                                  postcode,
                                  city,
                                  vat_number,
                                  house_number_suffix,
                                }) => {
    const values = {
      name: name,
      street: street,
      house_number: house_number,
      coc_number: coc_number,
      postcode: postcode,
      city: city,
      vat_number: vat_number,
      house_number_suffix: house_number_suffix,
      country: country,
      email: email,
      website_url: website_url,
      phone_number: phone_number,
      status: "pending",
    };

    if (id) {
      ResellerAction.updateUserCompany(userId, companyId, values)
        .then((res) => {
          dispatch(stopLoading());
          history.push("/reseller/user");
        })
        .catch((error) => {
          dispatch(stopLoading());
          console.log(error);
        });
    } else {
      dispatch(startLoading());
      ResellerAction.createResellerCompany(userId, values)
        .then((res) => {
          dispatch(stopLoading());
          localStorage.setItem("companyId", res.company.id);
          history.push("/reseller/subscription");
        })
        .catch((error) => {
          dispatch(stopLoading());
          console.log(error);
        });
    }
  };

  //console.log(companyInfo, "companyInfo");
  //console.log(id, "id");

  const initialValues = {
    name: id ? companyInfo?.name : "",
    street: id ? companyInfo?.street : "",
    house_number: id ? companyInfo?.house_number : "",
    coc_number: id ? companyInfo?.coc_number : "",
    postcode: id ? companyInfo?.postcode : "",
    city: id ? companyInfo?.city : "",
    vat_number: id ? companyInfo?.vat_number : "",
    house_number_suffix: id ? companyInfo?.house_number_suffix : "",
    country: country,
    email: id ? companyInfo?.email : "",
    website_url: id ? companyInfo?.website_url : "",
    phone_number: id ? companyInfo?.phone_number : "",
    status: "pending",
  };

  return (
    <div className="mt-3">
      <div className="d-block mb-4 mb-md-0">
        <Breadcrumb
          className="d-none d-md-inline-block"
          listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
        >
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHome}/>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/reseller/user">{t("Users")}</Breadcrumb.Item>
          <Breadcrumb.Item href="/reseller/create-user">
            {id ? t("Update user company") : t("Create user")}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Company")}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {(userId || id) && (
        <Form.Check
          className="mt-3"
          type="switch"
          id="custom-switch"
          label="Do you want to create a new company?"
          onChange={() => setIsChecked((prev) => !prev)}
          checked={id ? isChecked : null}
        />
      )}

      {!isChecked && (id || userId) ? (
        <SelectCompany/>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              setSubmitting(false);
              storeResellerCompany(values);
            }, 1000);
          }}
          validationSchema={ResellerCompanySchema}
        >
          {({
              setFieldValue,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
            <Form className="user w-100" onSubmit={handleSubmit}>
              <>
                <h4 className="my-3 text-center">
                  {id ? t("Update company") : t("Create company")}
                </h4>
                <Card className="p-4 col-7 d-flex flex-column align-items-center m-auto mb-5">
                  <div className="col-12">
                    <div className="form-group row h-80 mb-4">
                      <Form.Group className="col-sm-6 mb-3">
                        <Form.Label>{t("Company Name")}</Form.Label>
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("name", e.target.value)
                          }
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <span className="error-message">
                            {t(errors.name)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>{t("Street")}</Form.Label>
                        <Field
                          name="street"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("street", e.target.value)
                          }
                          value={values.street}
                        />
                        {errors.street && touched.street ? (
                          <span className="error-message">
                            {t(errors.street)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="form-group row h-80 mb-4">
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>{t("House Number")}</Form.Label>
                        <Field
                          name="house_number"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("house_number", e.target.value)
                          }
                          value={values.house_number}
                        />
                        {errors.house_number && touched.house_number ? (
                          <span className="error-message">
                            {t(errors.house_number)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-sm-6 ">
                        <Form.Label>{t("House Suffix")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="house_number_suffix"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("house_number_suffix", e.target.value)
                          }
                          value={values.house_number_suffix}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group row h-80 mb-4">
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>Postcode</Form.Label>
                        <Field
                          type="text"
                          name="postcode"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("postcode", e.target.value)
                          }
                          value={values.postcode}
                        />
                        {errors.postcode && touched.postcode ? (
                          <span className="error-message">
                            {t(errors.postcode)}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-sm-6 ">
                        <Form.Label>{t("City")}</Form.Label>
                        <Field
                          name="city"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("city", e.target.value)
                          }
                          value={values.city}
                        />
                        {errors.city && touched.city ? (
                          <span className="error-message">
                            {t(errors.city)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="form-group row h-80 mb-4">
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>{t("Country")}</Form.Label>
                        <select
                          className="form-control user-select"
                          value={country || ""}
                          onChange={(e) => setCountry(e.target.value)}
                          required
                        >
                          <option value={t("Netherlands")}>
                            {t("Netherlands")}
                          </option>
                        </select>
                      </Form.Group>
                      <Form.Group className="col-sm-6">
                        <Form.Label>{t("Phone Number")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone_number"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("phone_number", e.target.value)
                          }
                          value={values.phone_number}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group row h-80 mb-4">
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          value={values.email}
                        />
                      </Form.Group>
                      <Form.Group className="col-sm-6 ">
                        <Form.Label>{t("Website")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="website_url"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("website_url", e.target.value)
                          }
                          value={values.website_url}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group row mb-sm-3">
                      <Form.Group className="col-sm-6 mb-3 mb-sm-0">
                        <Form.Label>{t("VAT Number")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="vat_number"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("vat_number", e.target.value)
                          }
                          value={values.vat_number}
                        />
                      </Form.Group>
                      <Form.Group className="col-sm-6">
                        <Form.Label>{t("coc number")}</Form.Label>
                        <Field
                          name="coc_number"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("coc_number", e.target.value)
                          }
                          value={values.coc_number}
                        />
                        {errors.coc_number && touched.coc_number ? (
                          <span className="error-message">
                            {t(errors.coc_number)}
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="form-group justify-content-between row mb-sm-3 mx-0">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        className="col-12 mt-2 mb-3"
                      >
                        {id ? t("Update Company") : t("Next")}
                      </Button>
                    </div>
                  </div>
                </Card>
              </>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
