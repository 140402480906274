import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import Api from "../../libs/Api";
import {startLoading, stopLoading} from '../../store/actions/loading';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Card} from "react-bootstrap";
import '../style.css';

function RegistrationStep2({
                             country,
                             companyId,
                             postCode,
                             street,
                             houseNo,
                             hNoSuffix,
                             city,
                             coCNo,
                             vat,
                             pNo,
                             website,
                             companyEmail,
                             name,
                             setName,
                             setCoCNo,
                             setVat,
                             setcompanyEmail,
                             setStreet,
                             setHouseNo,
                             setHNoSuffix,
                             setPostCode,
                             setCity,
                             setCountry,
                             setPNo,
                             setWebsite,
                             setStep,
                             setCompanyId
                           }) {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(startLoading());// show loading
    if (companyId) {
      new Promise((resolve, reject) => {
        Api.get("/api/company-details/" + companyId)
          .then((response) => {
            setName(response.data.name);
            setCity(response.data.city);
            setCoCNo(response.data.kvk_number);
            setVat(response.data.btwnumber)
            setPostCode(response.data.postcode);
            setStreet(response.data.street);
            setHouseNo(response.data.street_number);
            setHNoSuffix(response.data.street_number_suffix)

            setValue("name", response.data.name);
            setValue("city", response.data.city);
            setValue("coc", response.data.kvk_number);
            setValue("vat", response.data.btwnumber);
            setValue("postcode", response.data.postcode);
            setValue("street", response.data.street);
            setValue("house_no", response.data.street_number);
            setValue("house_no_suffix", response.data.street_number_suffix);

            dispatch(stopLoading());
            resolve(response.data);
          })
          .catch((error) => {
              reject(error);
              dispatch(stopLoading());
            }
          );
      });
    } else {
      dispatch(stopLoading());
    }
    //eslint-disable-next-line
  }, [companyId]);

  const schema = yup.object().shape({
    name: yup.string().default(name).required().trim().label(t("Company Name")),
    street: yup.string().required().trim().default(street).label(t("Street")),
    house_no: yup.string().default(houseNo).required().trim().label(t("House Number")),
    house_no_suffix: yup.string().nullable().default(hNoSuffix).trim().label(t("Suffix")),
    postcode: yup.string().default(postCode).required().trim().label(t("Postcode")),
    city: yup.string().default(city).required().trim().label(t("City")),
    country: yup.string().default(country).required().trim().label(t("Country")),
    phone_no: yup.string().default(pNo).trim().label(t("Company Phone Number")),
    email: yup.string().default(companyEmail).email().trim().label(t("Company Email")),
    website: yup.string().default(website).url().trim().label(t("Website")),
    vat: yup.string().nullable().default(vat).trim().label(t("vat number")),
    coc: yup.string().default(coCNo).required().trim().label(t("coc number")),
  });

  const {register, handleSubmit, formState: {errors, isSubmitting}, setValue, reset} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    setStep(3);
    reset();
  }

  const goBack = () => {
    setName('');
    setCity('');
    setCoCNo('');
    setVat('')
    setPostCode('');
    setStreet('');
    setHouseNo('');
    setHNoSuffix('')
    setCompanyId('');
    setStep(1);
  }

  return (
    <>
      <div className="col-md-8 offset-md-2 card o-hidden border-0 shadow-lg">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">{t("Company Details")}</h1>
                </div>
                <form className="user" onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-group mb-sm-3">
                    <label>{t("Company Name")}</label>
                    <input
                      {...register("name")}
                      type="text"
                      className="form-control form-control-user"
                      onChange={(e) => {
                        setName(e.target.value);
                        setValue('name', e.target.value);
                      }}
                      value={name || ''}
                      required
                    />
                    <p className="validation-error">{errors.name?.message}</p>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("Street")}</label>
                      <input
                        {...register("street")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setStreet(e.target.value);
                          setValue('street', e.target.value);
                        }}
                        value={street || ''}
                        required
                      />
                      <p className="validation-error">{errors.street?.message}</p>
                    </div>
                    <div className="col-sm-3 mb-3 mb-sm-0">
                      <label>{t("House Number")}</label>
                      <input
                        {...register("house_no")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setHouseNo(e.target.value);
                          setValue('house_no', e.target.value);
                        }}
                        value={houseNo || ''}
                        required
                      />
                      <p className="validation-error">{errors.company_house_no?.message}</p>
                    </div>
                    <div className="col-sm-3 ">
                      <label>{t("House Suffix")}</label>
                      <input
                        {...register("house_no_suffix")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setHNoSuffix(e.target.value);
                          setValue('house_no_suffix', e.target.value);
                        }}
                        value={hNoSuffix || ''}
                      />
                      <p className="validation-error">{errors.house_no_suffix?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>Postcode</label>
                      <input
                        {...register("postcode")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setPostCode(e.target.value);
                          setValue('postcode', e.target.value);
                        }}
                        value={postCode || ''}
                        required
                      />
                      <p className="validation-error">{errors.postcode?.message}</p>
                    </div>
                    <div className="col-sm-6 ">
                      <label>{t("City")}</label>
                      <input
                        {...register("city")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setCity(e.target.value);
                          setValue('city', e.target.value);
                        }}
                        value={city || ''}
                        required
                      />
                      <p className="validation-error">{errors.city?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("Country")}</label>
                      <select
                        {...register("country")}
                        className="form-control user-select" value={country || ''}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setValue('country', e.target.value);
                        }}
                        required
                      >
                        <option value={t("Netherlands")}>{t("Netherlands")}</option>
                      </select>
                      <p className="validation-error">{errors.country?.message}</p>
                    </div>
                    <div className="col-sm-6">
                      <label>{t("Company Phone Number")}</label>
                      <input
                        {...register("phone_no")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setPNo(e.target.value);
                          setValue('phone_no', e.target.value);
                        }}
                        value={pNo || ''}
                      />
                      <p className="validation-error">{errors.phone_no?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t('Company Email')}</label>
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setcompanyEmail(e.target.value);
                          setValue('email', e.target.value);
                        }}
                        value={companyEmail || ''}
                      />
                      <p className="validation-error">{errors.email?.message}</p>
                    </div>
                    <div className="col-sm-6 ">
                      <label>Website</label>
                      <input
                        {...register("website")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setWebsite(e.target.value);
                          setValue('website', e.target.value);
                        }}
                        value={website || ''}
                      />
                      <p className="validation-error">{errors.website?.message}</p>
                    </div>
                  </div>
                  <div className="form-group row mb-sm-3">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>{t("VAT number")}</label>
                      <input
                        {...register("vat")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setVat(e.target.value);
                          setValue('vat', e.target.value);
                        }}
                        value={vat || ''}
                      />
                      <p className="validation-error">{errors.vat?.message}</p>
                    </div>
                    <div className="col-sm-6">
                      <label>{t("coc number")}</label>
                      <input
                        {...register("coc")}
                        type="text"
                        className="form-control form-control-user"
                        onChange={(e) => {
                          setCoCNo(e.target.value);
                          setValue('coc', e.target.value);
                        }}
                        value={coCNo || ''}
                        required
                      />
                      <p className="validation-error">{errors.coc?.message}</p>
                    </div>
                  </div>
                  <div className="form-group justify-content-center row mb-sm-3">
                    <div className="col-sm-3  ">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button"
                        onClick={goBack}>
                        {t("Previous")}
                      </button>
                    </div>
                    <div className="col-sm-3 ml-auto mt-2 mt-md-0">
                      <button type="submit" className="btn btn-primary btn-user btn-block p-1 py-2 next-button"
                              disabled={isSubmitting}>
                        {t("Next")}
                      </button>
                    </div>
                  </div>
                </form>
                <hr/>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    {t("Already have an account")}?
                    <Card.Link as={Link} to="/" className="fw-bold">
                      {t(" Login here")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationStep2;
