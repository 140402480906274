import React, {useState} from "react";
import CreateFirstInvoiceStep1 from "./CreateFirstInvoiceStep1";
import CreateFirstInvoiceStep2 from "./CreateFirstInvoiceStep2";
import CreateFirstInvoiceStep3 from "./CreateFirstInvoiceStep3";
import CreateFirstInvoiceStep4 from "./CreateFirstInvoiceStep4";

function CreateFirstInvoiceForm({
                                  img,
                                  setImg,
                                  setUploadableImage,
                                  handleCallbackStepFour,
                                  handleCallbackStepTwo,
                                  handleCallbackStepThree,
                                  stepThreeData,
                                  stepTwoData
                                }) {
  const CreateFirstInvoiceStep1Props = {img, setImg, setUploadableImage};
  const CreateFirstInvoiceStep4Props = {img, stepTwoData, stepThreeData};

  document.body.classList.add("bg-gradient-primary");
  const [step, setStep] = useState(1);
  if (step === 1) {
    return (
      <CreateFirstInvoiceStep1
        setStep={setStep}
        {...CreateFirstInvoiceStep1Props}
      />
    );
  }
  if (step === 2) {
    return (
      <CreateFirstInvoiceStep2
        setStep={setStep}

        handleCallbackStepTwo={handleCallbackStepTwo}
      />
    );
  }
  if (step === 3) {
    return (
      <CreateFirstInvoiceStep3
        setStep={setStep}

        handleCallbackStepThree={handleCallbackStepThree}
      />
    );
  }
  if (step === 4) {
    return (
      <CreateFirstInvoiceStep4
        setStep={setStep}

        handleCallbackStepFour={handleCallbackStepFour}
        {...CreateFirstInvoiceStep4Props}
      />
    );
  }
}

export default CreateFirstInvoiceForm;
