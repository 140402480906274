import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, InputGroup, Row} from '@themesberg/react-bootstrap';
import {Link} from 'react-router-dom';
import Auth from "../libs/Auth";
import {useTranslation} from "react-i18next";


export default () => {
  const {t} = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  document.body.classList.add('bg-gradient-primary');

  const sendResetLink = (e) => {
    e.preventDefault();

    Auth.forgotPassword(email)
      .then((res) => {
        setMessage(res.message);
      })
      .catch(() => setMessage(t("Password reset link can't be sent")));
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to="/" className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2"/> {t("Back to homepage")}
            </Card.Link>
          </p>
          {message && <div className="row">
            <p className="col-md-6 offset-md-3 bg-white p-2 text-center rounded-2">{message}</p>
          </div>}
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">{t("Forgot Password")}</h3>
                </div>
                <Form className="mt-4" onSubmit={sendResetLink}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>{t("Your Email")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope}/>
                      </InputGroup.Text>
                      <Form.Control autoFocus onChange={changeEmail} required type="email"
                                    placeholder="example@company.com"/>
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    {t("Get Reset Link")}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
