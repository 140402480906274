import React, {useState} from "react";
import {Alert} from "react-bootstrap";

const AlertDismissible = ({
                            textMessage,
                            onAfterClose,
                            variant = "warning",
                          }) => {
  const [show, setShow] = useState(true)

  const hide = () => {
    setShow(false);

    if (onAfterClose) {
      onAfterClose();
    }
  };

  if (show === true) {
    return (
      <Alert variant={variant} onClose={hide} dismissible>
        <p className="m-0">{textMessage}</p>
      </Alert>
    );
  }
};

export default AlertDismissible;
