import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  products: [],
  success: false,
  isLoading: false,
  error: "",
};

export const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productFetchingStarted: (state) => {
      state.isLoading = true;
    },
    productsFetched: (state, action) => {
      state.products = action.payload;
      state.isLoading = false;
      state.success = true;
    },
    productFetchingFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export const selectProducts = (state) => state.products.products || [];

export const {productFetchingFailed, productFetchingStarted, productsFetched} = products.actions;

export default products.reducer;