import {faTimes, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Form} from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {numberFormatter} from "../../libs/numberFormatter";
import PriceField from "../../components/inputs/PriceField";
import {useSelector} from "react-redux";
import {selectProducts} from "../../store/reducers/products";
import AutoCompleteProductField from "../../components/inputs/AutoCompleteProductField";
import {Field} from "formik";

function InvoiceLine({
                       rowId,
                       deleteInvoiceLine,
                       setFieldValue,
                       errors,
                       touched,
                       values,
                       showDeleteButton,
                     }) {
  const {t} = useTranslation();

  const [isPricePrefilledFromProduct, setIsPricePrefilledFromProduct] = useState(false);
  const products = useSelector(selectProducts);

  useEffect(() => {
    if (!values.product?.id) {
      setIsPricePrefilledFromProduct(false);
      return;
    }

    setFieldValue(`invoice_lines.${rowId}.price`, values.product?.price);
    setFieldValue(`invoice_lines.${rowId}.vat_percentage`, values.product?.vat);
    setIsPricePrefilledFromProduct(true);
  }, [values.product]);

  useEffect(() => {
    if (values?.price !== undefined && values.quantity !== undefined) {
      const totalAmountExclVat = (values.price * values.quantity) / 100;
      setFieldValue(`invoice_lines.${rowId}.amountExclVat_field`, totalAmountExclVat);
    }
  }, [values.price, values.quantity, rowId, setFieldValue]);

  return (
    <>
      <tr>
        <th>
          <Form.Group style={{width: "200px"}}>
            <AutoCompleteProductField
              name={`invoice_lines.${rowId}.product`}
              products={products}
            />
            {errors?.invoice_lines && touched?.invoice_lines ? (
              <div className="validation-error">
                {t(errors?.invoice_lines[rowId]?.product)}
              </div>
            ) : null}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <Field
              as={Form.Control}
              type="number"
              name={`invoice_lines.${rowId}.quantity`}
              className="text-center"
              autoComplete="off"
              placeholder={t("Quantity")}
            />
            {errors?.invoice_lines && touched?.invoice_lines ? (
              <div className="validation-error">
                {t(errors?.invoice_lines[rowId]?.quantity)}
              </div>
            ) : null}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <PriceField
                  name={`invoice_lines.${rowId}.price`}
                  disabled={isPricePrefilledFromProduct}
                  className="text-center"
                  autoComplete="off"
                />
              </div>
              {isPricePrefilledFromProduct && (
                <OverlayTrigger
                  placement="right"
                  delay={{show: 250, hide: 400}}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {t(
                        "This invoice line was pre-filled using an existing product. The product is still attached to this invoice line. This will affect the product statistics. Clicking the cross will detach the product"
                      )}
                    </Tooltip>
                  )}
                >
                  <span
                    onClick={() => {
                      setIsPricePrefilledFromProduct(false);
                      setFieldValue(`invoice_lines.${rowId}.product.id`, null);
                    }}
                    className="p-1"
                  >
                    <FontAwesomeIcon icon={faWindowClose}/>
                  </span>
                </OverlayTrigger>
              )}
            </div>
            {errors?.invoice_lines && (
              <div className="validation-error">
                {t(errors?.invoice_lines[rowId]?.price)}
              </div>
            )}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <div className="d-flex align-items-center">
              <Field
                as={Form.Select}
                disabled={isPricePrefilledFromProduct}
                name={`invoice_lines.${rowId}.vat_percentage`}
              >
                <option value="" disabled>
                  {t("Select vat percentage")}
                </option>
                <option value="0">{t("VAT 0%")}</option>
                <option value="6">{t("VAT low 6%")}</option>
                <option value="9">{t("VAT low 9%")}</option>
                <option value="21">{t("VAT high 21%")}</option>
                <option value="1">{t("VAT shifted 0%")}</option>
                <option value="2">{t("VAT exempt 0%")}</option>
              </Field>
              {isPricePrefilledFromProduct && (
                <OverlayTrigger
                  placement="right"
                  delay={{show: 250, hide: 400}}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {t(
                        "This invoice line was pre-filled using an existing product. The product is still attached to this invoice line. This will affect the product statistics. Clicking the cross will detach the product"
                      )}
                    </Tooltip>
                  )}
                >
                  <span
                    onClick={() => {
                      setIsPricePrefilledFromProduct(false);
                      setFieldValue(`invoice_lines.${rowId}.product.id`, null);
                    }}
                    className="p-1"
                  >
                    <FontAwesomeIcon icon={faWindowClose}/>
                  </span>
                </OverlayTrigger>
              )}
            </div>
            {errors?.invoice_lines && touched?.invoice_lines ? (
              <div className="validation-error">
                {errors?.invoice_lines[rowId]?.vat_percentage}
              </div>
            ) : null}
          </Form.Group>
        </th>
        <th>
          <Form.Group>
            <Form.Control
              type="text"
              className="text-center"
              name={`invoice_lines.${rowId}.amountExclVat_field`}
              readOnly
              value={numberFormatter.format(values.amountExclVat_field) || ""}
              placeholder={t("amountExclVat")}
            />
            {errors?.invoice_lines && touched?.invoice_lines ? (
              <div className="validation-error">
                {errors?.invoice_lines[rowId]?.amountExclVat_field}
              </div>
            ) : null}
          </Form.Group>
        </th>
        <th>
          {showDeleteButton && (
            <Form.Group>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => deleteInvoiceLine(rowId)}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </Form.Group>
          )}
        </th>
      </tr>
    </>
  );
}

export default InvoiceLine;
