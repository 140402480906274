import Api from "../../libs/Api";

class OffersActions {
  getOffers = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/offers")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  storeOffer = (offerData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/offers", offerData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  editOffer = (offerId, offerData) => {
    return new Promise((resolve, reject) => {
      Api.put(`/api/offers/${offerId}`, offerData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getOffer = (offerId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/offers/" + offerId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getOfferByUUID = (offerId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/offers/by-uuid/" + offerId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  acceptOffer = (offerUUID) => {
    return new Promise((resolve, reject) => {
      Api.post(`api/offers/accept/${offerUUID}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  confirmAcceptOffer = (token) => {
    return new Promise((resolve, reject) => {
      Api.post(`api/offers/confirm/${token}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPaginatedOffers = (page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("api/offers", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          //reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteOffer = (offerId) => {
    return new Promise((resolve, reject) => {
      Api.delete(`/api/offers/${offerId}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  };
}

export default new OffersActions();
