import {
  GET_CUSTOMERS_START,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMER_BY_ID_START,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILED,
} from "../actions/actionTypes";

const initialState = {
  // customers: { customers: [], success: false, isLoading: false, error: "" },
  // customer: { customer: [], success: false, isLoading: false, error: "" },
  customers: [],
  customer: {}
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...initialState,
        customers: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_CUSTOMERS_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    case GET_CUSTOMER_BY_ID_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...initialState,
        customer: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_CUSTOMER_BY_ID_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const selectCustomers = state => state.customers.customers;
export const selectCustomer = state => state.customers.customer;

export default customers;
