import React, {useEffect, useState} from "react";
import {Container} from "@themesberg/react-bootstrap";
import Auth from "../libs/Auth";
import CreateFirstInvoiceForm from "./CreateFirstInvoiceForm";
import {useHistory} from "react-router-dom";
import AlertDismissible from "../../components/old/AlertDismissible";

function CreateFirstInvoice() {
  const companyId = Auth.getActiveCompanyId();
  const [img, setImg] = useState("../../../default-logo.png");
  const [stepFourData, setStepFourData] = useState("")
  const [stepTwoData, setStepTwoData] = useState("")
  const [stepThreeData, setStepThreeData] = useState("")
  let history = useHistory();
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);
  const [uploadableImage, setUploadableImage] = useState("");

  useEffect(() => {
    if (stepFourData !== "") {
      const data = {
        "company_id": companyId,
        "image": uploadableImage,
        "invoice_number": stepThreeData.paddedInoviceNumber,
        "next_invoice_number": stepThreeData.invoiceNumber,
        "invoice_prefix": stepThreeData.invoicePrefix,
        "invoice_description": stepThreeData.invoiceDescription,
        "invoice_date": stepThreeData.invoiceDate,
        "expiration_days": stepThreeData.expirationDays,
        "company_bank_account": stepThreeData.bankAccount,
        "company_bank_account_holder": stepThreeData.bankAccountholder,
        "company_vat_number": localStorage.getItem("company_vat_number") || null,
        "company_name": localStorage.getItem("company_name"),
        "company_phone_number": localStorage.getItem("company_phone_number") || null,
        "company_email": localStorage.getItem("company_email"),
        "company_website_url": localStorage.getItem("company_website_url"),
        "company_coc_number": localStorage.getItem("company_kvk_number") || null,
        "company_street": localStorage.getItem("company_street"),
        "company_house_number": localStorage.getItem("company_house_number"),
        "company_house_number_suffix": localStorage.getItem("company_house_number_suffix") || null,
        "company_country": localStorage.getItem("company_country"),
        "company_city": localStorage.getItem("company_city"),
        "company_postcode": localStorage.getItem("company_postcode"),
        "customer_phone_number": stepTwoData.customerNumber || null,
        "customer_street": stepTwoData.customerStreet,
        "customer_house_number": stepTwoData.customerHouseNumber,
        "customer_house_number_suffix": stepTwoData.customerSuffix || null,
        "customer_country": stepTwoData.customerCountry || "NL",
        "customer_city": stepTwoData.customerCity,
        "customer_postcode": stepTwoData.customerPostcode,
        "customer_name": stepTwoData.customerCompany?.companyName,
        "customer_vat_number": stepTwoData.customerVAT || null,
        "customer_coc_number": stepTwoData.customerCoC || null,
        "invoice_lines": stepFourData?.inputList?.map((item) => {
          return {
            "vat_percentage": item.vat_percentage,
            "quantity": item.quantity,
            "price": parseInt(item.price.toString().replace(/€|,/g, "")),
            "description": item.description,
          }
        })
      }

      let formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else if (value !== null && value !== undefined && value !== 'null') {
          formData.append(key, value);
        }
      }

      Auth.createFirstInvoice(formData)
        .then((res) => {
          if (res.success === true) {
            setMessage({show: true, message: res.message});
            localStorage.removeItem('FormData');
            history.push('/choose-subscription');
          }
          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({show: true, message: res.errors[key][0]});
            }
            setErrors(array);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
    //eslint-disable-next-line
  }, [stepFourData, stepThreeData, stepTwoData]);

  const handleCallbackStepFour = (inputInvoiceData) => {
    setStepFourData(inputInvoiceData)
  }
  const handleCallbackStepTwo = (CustomerInformation) => {
    setStepTwoData(CustomerInformation)
  }
  const handleCallbackStepThree = (AddInvoiceData) => {
    setStepThreeData(AddInvoiceData)
  }
  const CreateFirstInvoiceFormProps = {img, setImg, setUploadableImage, stepTwoData, stepThreeData}

  return (
    <Container className="full-height-container ">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {message.show ? <AlertDismissible textMessage={message.message} variant="info"/> : ""}
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible key={index} textMessage={message.message} variant="danger"/>
              )
            })
          }
        </div>
      </div>
      <CreateFirstInvoiceForm {...CreateFirstInvoiceFormProps} handleCallbackStepFour={handleCallbackStepFour}
                              handleCallbackStepTwo={handleCallbackStepTwo}
                              handleCallbackStepThree={handleCallbackStepThree}/>
    </Container>
  );
}

export default CreateFirstInvoice;