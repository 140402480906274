import React, {useEffect, useState} from "react";
import {Breadcrumb} from "@themesberg/react-bootstrap";
import Subscription from "../libs/Subscription";
import moment from "moment-timezone";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import CustomSpinner from "../../components/CustomSpinner";
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {numberFormatter} from "../../libs/numberFormatter";
import {selectActiveCompanyId, selectUser} from "../../store/reducers/userCompany";

function CompanySubscription() {
  const history = useHistory();
  const {t} = useTranslation();
  const user = useSelector(selectUser);

  // console.log(useSelector((state) => state.userCompany.user?.user?.reseller), "useSelector((state) => state.userCompany.user?.user?.reseller)");
  // const userType = useSelector((state) => state.userCompany.user.userType);
  // const user = useSelector((state) => state.userCompany.user) ;
  console.log(user, "user");

  const [subscription, setSubscription] = useState(null);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resellerUser, setResellerUser] = useState("");

  const companyId = useSelector(selectActiveCompanyId);

  useEffect(() => {
    const firstName = user?.user?.first_name;
    const lastName = user?.user?.last_name;
    setResellerUser(firstName + " " + lastName);

    if (user?.userType === "reseller") {
      setSubscription(user?.companySubscription);
      setLoading(false)
    } else {
      getCompanySubscription();
      companySubscriptionPayments();
    }
    //eslint-disable-next-line
  }, []);

  const getCompanySubscription = () => {
    Subscription.getCompanySubscription(companyId)
      .then((res) => {
        if (res.subscription) {
          setSubscription(res.subscription);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const companySubscriptionPayments = () => {
    Subscription.companySubscriptionPayments(companyId)
      .then((res) => {
        if (res.payments) {
          setPayments(res.payments);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t("Company subscription")}
            </Breadcrumb.Item>
          </Breadcrumb>

          <h4>{t("Company subscription")}</h4>
          <p className="mb-0">
            {t("Details about the subscription of your company and payment history")}
          </p>
        </div>
      </div>
      <>
        {loading ? (
          <CustomSpinner/>
        ) : (
          <>
            {user?.reseller ? (
              <Alert variant="warning">
                {t("You have a subscription to the services of ")}
                <u className="fw-bold">{resellerUser}</u> ,
                {t("please contact them if you need more information about your subscription")}
                .
              </Alert>
            ) : (
              <div className="row mt-4 justify-content-between">
                <div className="col-3">
                  <h5>{t("Subscription Details")}</h5>
                  {subscription && (
                    <div className="card-body shadow p-3 mb-5 bg-white rounded d-flex flex-column">
                      <h6 className="text-center">Plan</h6>
                      <h2 className="text-center">
                        {subscription.subscription_name}
                      </h2>
                      <h6 className="h6 text-center">{t("Monthly")}</h6>
                      <p className="text-center mb-0">
                        {subscription.subscription_num_invoices} {t("invoices")}
                      </p>
                      <h4 className="text-center">
                        {numberFormatter.format(
                          subscription.subscription_price / 100
                        )}
                      </h4>
                      <button
                        className="subscription-btn"
                        onClick={() => {
                          history.push(
                            user?.userType === "reseller"
                              ? "/reseller/change-subscription"
                              : "/change-subscription"
                          );
                        }}
                      >
                        {t("Change Subscription")}
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-9">
                  <h5>{t("Payment Details")}</h5>
                  {payments ? (
                    <div className="table-responsive bg-white">
                      <table className="table table-hover">
                        <thead>
                        <tr>
                          <th>{t("Date")}</th>
                          <th className="text-center">{t("status")}</th>
                          <th className="text-center">{t("Amount")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {payments.map((payment) => {
                          return (
                            <tr key={payment.id}>
                              <td>
                                {moment
                                  .tz(payment.createdAt, "Europe/Amsterdam")
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td className="text-center">
                                {payment.status === "paid" ? (
                                  <i
                                    className="fa fa-check text-success"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-times text-danger"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </td>
                              <td className="text-center">
                                €{payment.amount.value}
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Alert variant="warning">
                      {t(
                        "There are no payments registered for your account yet"
                      )}
                    </Alert>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default CompanySubscription;
