import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

function CreateFirstInvoiceStep1({setStep, img, setImg, setUploadableImage}) {
  const {t} = useTranslation();

  let persistData = localStorage.getItem("FormData")
    ? JSON.parse(localStorage.getItem("FormData"))
    : {};
  const changeHandler = (event) => {
    const file = event.target.files[0];
    setImg(URL.createObjectURL(file));
    setUploadableImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      localStorage.setItem(
        "FormData",
        JSON.stringify({...persistData, img: reader.result})
      );
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const saveStepFirstData = (e) => {
    e.preventDefault();
    setStep(2);
  };

  useEffect(() => {
    setImg(persistData.img ? persistData.img : "../../../default-logo.png");
  }, [setImg, persistData.img]);

  return (
    <>
      <div className="col-md-12 card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-0">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">
                    {t("Upload your company logo")}
                  </h1>
                </div>
                <form
                  className="user"
                  onSubmit={saveStepFirstData}
                  encType="multipart/form-data"
                >
                  <div className="form-group mb-sm-3 d-flex justify-content-center align-items-start">
                    <div className="logo-select">
                      <img
                        className="logo-img"
                        src={img}
                        alt="logo"
                      />
                    </div>

                    <div className="pencil-icon">
                      <label
                        htmlFor="upload"
                        className="btn btn-user btn-block p-1 next-button"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} className="icon"/>
                      </label>
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form-group justify-content-center row mb-sm-3">
                    <div className="col-sm-5  ">
                      <button
                        onClick={() => {
                          setStep(2);
                          localStorage.removeItem("FormData");
                        }}
                        className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button mt-2 mt-md-0"
                      >
                        {t("Skip this step")}
                      </button>
                    </div>
                    <div className="col-sm-5 ml-auto ">
                      {img === "../../../default-logo.png" ? (
                        <div>
                          <label
                            htmlFor="upload"
                            className="btn btn-primary btn-user btn-block p-1 py-2 next-button mt-2 mt-md-0"
                          >
                            {t("Upload")}
                          </label>
                          <input
                            id="upload"
                            type="file"
                            className="d-none"
                            onChange={changeHandler}
                          />
                        </div>
                      ) : (
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block p-1 py-2 next-button mt-2 mt-md-0"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFirstInvoiceStep1;
