import React from "react";
import {useSelector} from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import {Spinner} from "@themesberg/react-bootstrap";
// To get rid of the warning: Failed prop type: LoadingOverlayWrapper: prop type `styles.content` is invalid; it must be a function
LoadingOverlay.propTypes = undefined

function SpinnerLoading({children}) {
  const isLoading = useSelector((state) => state.loading.loading);

  console.log('isLoading', isLoading)

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner animation="grow" variant="success"/>}
      >
        {children}
      </LoadingOverlay>
    </>
  )
    ;
}

export default SpinnerLoading;