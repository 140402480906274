import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, ButtonGroup, Card, Dropdown, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Invoice from "../libs/Invoice";
import {Link, useHistory} from "react-router-dom";
import {faEdit, faEllipsisH, faEye, faHome, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment-timezone';
import StatusBullet from "./utils/StatusBullet";
import InvoiceLimitModal from "./utils/InvoiceLimitModal";
import {useTranslation} from "react-i18next";
import CustomSpinner from "../../components/CustomSpinner";
import {OldPagination} from "../../components/old/OldPagination";
import {numberFormatter} from "../../libs/numberFormatter";
import {getCompanySettings} from "../../store/actions/settings";
import {useDispatch} from "react-redux";
import {fetchCompanyCustomers} from "../../store/actions/customers";
import Messages from "../../components/Messages";
import {messageShown} from "../../store/reducers/message";
import {extractPaginationData} from "../../libs/pagination";

function Invoices() {
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoiceLimitData, setInvoiceLimitData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: ""
  });
  const [invoiceLimitModalShow, setInvoiceLimitModalShow] = useState(false);
  const dispatch = useDispatch();

  const {t} = useTranslation();

  useEffect(() => {
    getPaginatedInvoices();
    dispatch(getCompanySettings());
    dispatch(fetchCompanyCustomers());
    // eslint-disable-next-line
  }, []);

  const getPaginatedInvoices = (page = null) => {
    setLoading(true);
    Invoice.getPaginatedInvoices(page)
      .then(res => {
        setLoading(false);
        if (res.invoices) {
          setInvoices(res.invoices.data);
          setInvoiceLimitData(res.invoices_limit);
          setPaginationData(extractPaginationData(res.invoices));
        } else {
          dispatch(messageShown({message: res.message, variant: "warning"}))
        }
      }).catch((error) => {
      setLoading(false);
    })
  }

  const deleteInvoice = (invoiceId) => {
    Invoice.deleteInvoice(invoiceId)
      .then(res => {
        if (res.success === true) {
          dispatch(messageShown({message: res.message, variant: "success"}))
          getPaginatedInvoices();
        } else if (res.success === false) {
          dispatch(messageShown({message: res.message, variant: "danger"}))
        }

      }).catch(error => console.log(error));
  }

  const TableRow = ({status, index, invoice_date, total_amount, invoice_number, customer_name, id}) => {
    const realStatus = status.split(' ').map(w => w[0].toUpperCase() + w.substring(1)).join(' ').toLowerCase();
    return (
      <tr>
        <td>
            <span className="fw-normal">
            {index + 1}
            </span>
        </td>
        <td>
          <span className="fw-normal">
            {
              moment
                .tz(invoice_date, "Europe/Amsterdam")
                .format("DD-MM-YYYY")
            }
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {invoice_number}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {customer_name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
              <StatusBullet status={realStatus}/>
            &nbsp;&nbsp;
            {
              realStatus.replace(/_/g, " ")
            }
          </span>
        </td>
        <td>
          <span className="fw-normal">
             {numberFormatter.format(total_amount / 100)}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" drop="down" className="text-dark m-0 p-0"
                             id="dropdown-split-basic">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown">
              {status !== 'draft' &&
                <>
                  <Dropdown.Item onClick={() => history.push(`/view-invoice/${id}`)}>
                    <FontAwesomeIcon icon={faEye} className="me-2"/> {t("view invoice")}
                  </Dropdown.Item>
                </>
              }
              {status === 'draft' &&
                <>
                  <Dropdown.Item href={`/edit-invoice/${id}`}>
                    <FontAwesomeIcon icon={faEdit} className="me-2"/> {t("edit")}
                  </Dropdown.Item>
                  <Dropdown.Item className="text-danger"
                                 onClick={(e) => {
                                   if (
                                     window.confirm(
                                       t("are you sure you wish to delete this invoice?")
                                     )
                                   )
                                     deleteInvoice(id);
                                 }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2"/> {t("remove")}
                  </Dropdown.Item>
                </>
              }

            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };


  return (
    <>
      <InvoiceLimitModal show={invoiceLimitModalShow}
                         handleClose={() => setInvoiceLimitModalShow(!invoiceLimitModalShow)} data={invoiceLimitData}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
                      listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("invoices")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("invoices")}</h4>
          <p className="mb-0">{t("Showing invoices of your company")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            {
              invoiceLimitData["total"] >= invoiceLimitData["limit"] ?
                <button onClick={() => setInvoiceLimitModalShow(true)} className="btn btn-outline-primary btn-sm">
                  <FontAwesomeIcon icon={faPlus} className="me-2"/>{t("new")}</button> :
                <Link to="/create-invoice" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faPlus}
                                                                                                       className="me-2"/>{t("new")}
                </Link>
            }

          </ButtonGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <Messages/>
            </div>
          </div>
        </div>
      </div>

      {loading ? <CustomSpinner/> : <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0 pb-5">
          <Table hover className="user-table align-items-center">
            <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">{t("invoice date")}</th>
              <th className="border-bottom">{t("invoice number")}</th>
              <th className="border-bottom">{t("customer name")}</th>
              <th className="border-bottom">{t("status")}</th>
              <th className="border-bottom">{t("price")}</th>
              <th className="border-bottom">{t("action")}</th>
            </tr>
            </thead>
            <tbody>
            {invoices && invoices.map((invoice, index) => <TableRow key={`contract-${index}`}
                                                                    index={index} {...invoice}  />)}
            </tbody>
          </Table>

          <OldPagination items={invoices} action={Invoice} paginationData={paginationData}
                         getPaginated={getPaginatedInvoices}/>
        </Card.Body>
      </Card>}

    </>
  );
}

export default Invoices;
