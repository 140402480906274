import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import ReactGA from "react-ga";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Auth from "../../auth/libs/Auth";
import Subscription from "../libs/Subscription";
import {useSelector} from "react-redux";
import {selectActiveCompanyId, selectUserId} from "../../store/reducers/userCompany";

function FirstPaymentSuccess() {
  const [firstPaymentId, setFirstPaymentId] = useState("");
  const [paymentMessage, setPaymentMessage] = useState("");
  const [paymentSubMessage, setPaymentSubMessage] = useState("");
  const companyId = useSelector(selectActiveCompanyId);
  const userId = useSelector(selectUserId);
  const {t} = useTranslation();

  useEffect(() => {
    if (companyId) {
      Subscription.getFirstPayment(companyId)
        .then((res) => {
          setFirstPaymentId(res.payment_id);
        })
        .catch((error) => console.log(error));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (firstPaymentId && companyId)
      Subscription.checkPaymentStatus(companyId, firstPaymentId)
        .then((res) => {
          if (res?.paymentSuccessful === true) {
            Subscription.createSubscription(res.payment.id).catch(() => {
            });
            setPaymentMessage(res.message);
            setPaymentSubMessage(res.subMessage);
            if (res.payment && res.payment.amount && res.payment.amount.value) {
              let amount = res.payment.amount.value;
              ReactGA.event({
                action: "Purchase",
                category: "Purchase",
                label: "Guest Purchase",
                value: amount,
              });

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "event",
                eventProps: {
                  action: "Purchase",
                  category: "Purchase",
                  label: "Guest Purchase",
                  value: amount,
                },
              });
            }
            Auth.loggedInafterFirstPayment(userId)
              .then(() => {
                Auth.clearActiveUserId();
                Auth.clearActiveCompanyId();
                // window.location.href = "/";
              })
              .catch((error) => console.log(error, "User not logged in"));
          } else if (res.paymentSuccessful === false) {
            setPaymentMessage(res.message);
          }
        })
        .catch((error) => console.log(error));
    //eslint-disable-next-line
  }, [firstPaymentId]);

  document.body.classList.add("bg-gradient-primary");
  return (
    <Container className="full-height-container ">
      <>
        <div className="col-md-12 card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="py-5">
                  <div className="d-flex justify-content-center thanks-text">
                    <h1>{paymentMessage ? paymentMessage : t('Loading...')}</h1>
                  </div>
                  <div className="d-flex justify-content-center thanks-text">
                    {paymentSubMessage && <p>{paymentSubMessage}</p>}
                  </div>
                  <div className="d-flex justify-content-center thanksText">
                    <Link to="/">
                      <button className="btn btn-primary">
                        {t("Go to login page")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Container>
  );
}

export default FirstPaymentSuccess;
