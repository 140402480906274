import {configureStore} from "@reduxjs/toolkit";
import {persistStore} from 'redux-persist';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer, // rootReducer already contains persisted reducers
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    })
});

export const persistor = persistStore(store);

export default store;
