import {faShare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {numberFormatter} from "../libs/numberFormatter";
import {useFormikContext} from "formik";
import Invoice from "../invoices/libs/Invoice";

export default function PriceCard({onClick, cardType}) {

  const {values} = useFormikContext();
  const {t} = useTranslation();

  const [totalPrices, setTotalPrices] = useState({
    totalPriceExclVat: 0,
    totalVat: 0,
    totalInclVat: 0,
  });

  useEffect(() => {
    setTotalPrices(Invoice.calculateTotalPrices(values.invoice_lines));
  }, [values.invoice_lines]);

  const formatPrice = (price) => {
    const parsedPrice = parseFloat(price);

    if (isNaN(parsedPrice)) {
      // Handle cases where price is not a valid number
      return "€ 0,00";
    }

    return numberFormatter.format(parsedPrice);
  };


  const {totalPriceExclVat, totalVat, totalInclVat} = totalPrices;

  return (
    <div className="row mt-2 mb-2 justify-content-end mx-0">
      <Card className="col-md-4 col-12">
        <div className="d-flex pt-3">
          <div className="col-7">
            <h6>{t("Total excl. VAT")}</h6>
            <h6>{t("VAT price")}</h6>
            <hr/>
            <h6>
              <strong>{t("Total incl. VAT")}</strong>
            </h6>
          </div>
          <div className="col-5 text-right">
            <h6>{formatPrice(totalPriceExclVat)}</h6>
            <h6>{formatPrice(totalVat)}</h6>
            <hr/>
            <h6>
              <strong>{formatPrice(totalInclVat)}</strong>
            </h6>
          </div>
        </div>
        {cardType !== "contract" && (
          <Button variant="primary" onClick={onClick} className="m-2">
            <FontAwesomeIcon icon={faShare}/> {t("preview")}
          </Button>
        )}
      </Card>
    </div>
  );
}
