import {useSelector} from "react-redux";
import {selectMessages} from "../store/reducers/message";
import Message from "./Message";

const Messages = (props) => {
  const messages = useSelector(selectMessages);

  return <div {...props}>
    {messages?.map((message, index) => <Message key={`message-${index}`} message={message.message}
                                                variant={message.variant} index={index}/>)}
  </div>
}

export default Messages;