import {
  GET_SUBSCRIPTIONS_START,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED
} from "../actions/actionTypes";

const initialState = {
  subscriptions: {},
  success: false,
  isLoading: false,
  error: "",
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...initialState,
        subscriptions: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_SUBSCRIPTIONS_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default subscriptions;
  