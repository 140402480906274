import React from "react";
import Yuki from "./Yuki";
import Image from "react-bootstrap/Image";

import ExactLogo from "../logos/exact-logo.svg";
import "../styles.css";
import {useTranslation} from "react-i18next";

function Settings() {
  const {t} = useTranslation();

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-12">
          <h1>{t("Administration Settings")} </h1>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <p style={{fontSize: "18px"}}>
            {t('To be able to use one of the supported accounting packages, you must have an account with the relevant party. You can then use the data provided to link "Factuursnel". All invoices will be automatically processed in the accounting package')}.
          </p>
        </div>
      </div>
      <Yuki/>
      <div className="row  mt-75 align-items-baseline">
        <div className="col-6">
          <Image
            fluid
            style={{height: "65px", objectFit: "contain"}}
            src={ExactLogo}
          />
        </div>
        <div className="col-6">
          <h3>{t("Available soon")}..</h3>
        </div>
      </div>
    </div>
  );
}

export default Settings;
