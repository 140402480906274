import Api from "../../libs/Api";

class Contract {
  getContracts = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/contracts/all")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getPaginatedContracts = (page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/contracts", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getCustomerContracts = (customerId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/customers/" + customerId + "/contracts")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  storeContract = (contractData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/contracts", contractData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  updateContract = (contractData) => {
    const contractId = contractData.id;
    return new Promise((resolve, reject) => {
      Api.put("/api/contracts/" + contractId, contractData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  deleteContract = (contractId) => {
    return new Promise((resolve, reject) => {
      Api.delete("/api/contracts/" + contractId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  }
}

export default new Contract();
