import React, {useCallback, useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import {config} from "./config";
import {useHistory} from "react-router-dom";

const Tracking = () => {
  const history = useHistory();

  const [hotJarInjected, setHotJarInjected] = useState(false);
  const [gTagManagerInjected, setGTagManagerInjected] = useState(false);
  const [fbInjected, setFbInjected] = useState(false);

  const doTracking = useCallback((firstTime = false) => {
    let path = history.location.pathname + history.location.search;

    if (config.trackGa !== false) {
      ReactGA.pageview(path);
    }

    if (config.trackFbAds !== false) {
      window.fbq('track', 'PageView');
    }

    console.log('track ' + path);
  }, [history]);

  useEffect(() => {
    if (config.trackGa !== false) {
      ReactGA.initialize(config.trackGa);
      ReactGA.ga('require', 'ec');
    }

    doTracking(true);

    const unlisten = history.listen(doTracking);

    return () => unlisten()
  }, [doTracking, history])

  const injectHotJar = () => {
    setHotJarInjected(true);

    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)
      };
      h._hjSettings = {hjid: config.hotJar, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  };

  const injectGoogleTagManager = () => {
    setGTagManagerInjected(true);

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', config.trackGTagManager);
  };

  const injectFBAds = () => {
    setFbInjected(true);

    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', config.trackFbAds);
  };

  return (<React.Fragment>
    <script>{config.hotJar !== false && !hotJarInjected && injectHotJar()}</script>
    <script>{config.trackGTagManager !== false && !gTagManagerInjected && injectGoogleTagManager()}</script>
    <script>{config.trackFbAds !== false && !fbInjected && injectFBAds()}</script>
  </React.Fragment>);
};

export default Tracking;
