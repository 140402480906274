import Api from "../../libs/Api";

class Product {
  getPaginatedProducts = (companyId, page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/companies/" + companyId + "/products", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          //reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getCompanyProduct = (companyId, productId) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/companies/" + companyId + "/products/" + productId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  createCompanyProduct = (companyId, productData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/companies/" + companyId + "/products", productData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  updateCompanyProduct = (companyId, productData) => {
    let productId = productData.id;

    return new Promise((resolve, reject) => {
      Api.put(
        "/api/companies/" + companyId + "/products/" + productId,
        productData
      )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteCompanyProduct = (companyId, productId) => {
    return new Promise((resolve, reject) => {
      Api.delete("/api/companies/" + companyId + "/products/" + productId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  }


}

export default new Product();
