import {SEARCH, FOCUS, BLUR} from "../actions/actionTypes";

const search = (state = {search: '', isFocused: false}, action) => {
  switch (action.type) {
    case SEARCH:
      return {...state, search: action.search};
    case FOCUS:
      return {...state, isFocused: true};
    case BLUR:
      return {...state, isFocused: false};
    default:
      return state;
  }
};

export default search;