import Api from "../../../libs/Api";
import Auth from "../../../auth/libs/Auth.js";

class Template {
  getTemplates() {
    return new Promise((resolve, reject) => {
      Api.get("/api/templates/all")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  deleteTemplates(id) {
    return new Promise((resolve, reject) => {
      Api.delete("/api/templates/" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getPaginatedTemplates(page = null) {
    return new Promise((resolve, reject) => {
      Api.get("api/templates", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          //reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  getTemplate(id) {
    return new Promise((resolve, reject) => {
      Api.get("/api/templates/" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  CreateTemplate(templateData) {
    return new Promise((resolve, reject) => {
      Api.post("/api/templates", templateData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            alert('Template has been successfully created')
            window.location.reload()
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  UpdateTemplate(id, templateData) {
    const TemplateId = id;
    return new Promise((resolve, reject) => {
      Api.post("/api/templates/" + TemplateId, templateData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  setDefaultTemplate(id) {
    return new Promise((resolve, reject) => {
      Api.post("/api/templates/" + id + "/setdefault")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  }

  // logo upload

  uploadLogo = (logo) => {
    let companyId = Auth.getActiveCompanyId();
    const formData = new FormData();
    formData.append('image', logo);

    return new Promise((resolve, reject) => {
      Api.post("/api/upload-logo/" + companyId, formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  }

}

const template = new Template();

export default template;
