import Api from "../../libs/Api";

class ResellerAction {
  getResellerCompanies = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/reseller/company/list")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getResellerUsers = () => {
    return new Promise((resolve, reject) => {
      Api.get("/api/reseller/user")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPaginatedResellerUsers = (page = null) => {
    return new Promise((resolve, reject) => {
      Api.get("/api/reseller/user", {page: page})
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  getPrevNextPageNumber = (string) => {
    let start = string.search("=") + 1;
    let end = string.length;
    let pageNumber = string.slice(start, end);
    return pageNumber;
  };

  storeUser = (userData) => {
    return new Promise((resolve, reject) => {
      Api.post("/api/reseller/user", userData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch((error) => {
          reject(error?.response?.data?.errors || null);
          // if (error.response && error.response.data && error.response.data.errors) {
          //   // Extract server-side validation errors from the error response
          //   const serverErrors = error.response.data.errors;
          //   reject(serverErrors);
          // } else {
          //   reject(null);
          // }
        });
    });
  };

  selectCompany = (userId, companyData) => {
    return new Promise((resolve, reject) => {
      Api.put(`api/reseller/user/${userId}/company`, companyData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  updateUser = (id, userData) => {
    return new Promise((resolve, reject) => {
      Api.put(`/api/reseller/user/${id}`, userData)
        .then((response) => {
          console.log(response);
          if (response.status) {
            resolve(response);
          }
        })
        .catch(() => reject(null));
    });
  };

  createMandate = (paymentData) => {
    return new Promise((resolve, reject) => {
      Api.post("api/reseller/mandate", paymentData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  deleteResellerUser = (userId) => {
    return new Promise((resolve, reject) => {
      Api.delete(`/api/reseller/user/${userId}`)
        .then((response) => {
          console.log(response, "response.data");
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  createResellerCompany = (userId, companyData) => {
    return new Promise((resolve, reject) => {
      Api.post(`/api/reseller/user/${userId}/company`, companyData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };

  updateUserCompany = (userId, companyId, companyData) => {
    return new Promise((resolve, reject) => {
      Api.put(`/api/reseller/user/${userId}/company/${companyId}`, companyData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(() => reject(null));
    });
  };

  resellerSubscription = (userId, subscriptionId) => {
    return new Promise((resolve, reject) => {
      Api.post(
        `/api/reseller/user/${userId}/company/${subscriptionId}/subscription`
      )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            console.log("response.data", response.data);
          }

          reject(response.data || null);
        })
        .catch(() => reject(null));
    });
  };
}

export default new ResellerAction();
