import {faHome, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Breadcrumb, ButtonGroup, Card, Tab, Table, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";
import OffersActions from "../libs/Offers";
import CustomSpinner from "../../components/CustomSpinner";
import OfferTable from "../../components/OfferTable";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getOffersData} from "../../store/actions/offer";
import AlertDismissible from "../../components/old/AlertDismissible";
import {OldPagination} from "../../components/old/OldPagination";
import {getCompanySettings} from "../../store/actions/settings";

export default function Offers() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const {t} = useTranslation();

  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPgae: "",
    total: "",
  });

  const dispatch = useDispatch();
  const offers = useSelector(state => state.offer.offers.data) || [];

  useEffect(() => {
    getPaginatedOffers();
    dispatch(getOffersData())
    dispatch(getCompanySettings());

    // eslint-disable-next-line
  }, [dispatch]);

  const getPaginatedOffers = (page = null) => {
    setLoading(true);
    OffersActions.getPaginatedOffers(page)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setPaginationData({
            links: res.links,
            prevPageUrl: res.prev_page_url,
            nextPageUrl: res.next_page_url,
            showingPerPage: res.to - res.from + 1,
            total: res.total,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const countOffersByStatus = (status) => {
    return offers.filter(offer => offer.status === status).length;
  };

  return (
    <div className=" py-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div>
          <Breadcrumb
            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome}/>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("offers")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("offers")}</h4>
          <p>{t("Show your Offers")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Link to="create-offer" className="btn btn-outline-primary btn-sm">
              <FontAwesomeIcon icon={faPlus} className="me-2"/> {t("new")}
            </Link>
          </ButtonGroup>
        </div>
      </div>
      {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant}/> : ""}

      <div style={{backgroundColor: '#fff', borderRadius: '10px'}}>
        <Tabs
          className="mt-3"
          defaultActiveKey="all" // Set default active tab
          id="offer-tabs"
          style={{padding: '8px'}}
        >
          <Tab eventKey="all" title={t("All")}>
            {loading ? (
              <CustomSpinner/>
            ) : (
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="pt-0 pb-5">
                  <Table hover className="user-table align-items-center">
                    <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">{t("Offer Date")}</th>
                      <th className="border-bottom">{t("Offer Number")}</th>
                      <th className="border-bottom">{t("customer name")}</th>
                      <th className="border-bottom">{t("status")}</th>
                      <th className="border-bottom">{t("price")}</th>
                      <th className="border-bottom">{t("action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers &&
                      offers.map((offers, index) => (
                        <OfferTable
                          key={index}
                          index={index}
                          {...offers}
                          setMessage={setMessage}
                          setPaginationData={setPaginationData}
                          setLoading={setLoading}
                        />
                      ))}
                    </tbody>
                  </Table>
                  <OldPagination
                    action={OffersActions}
                    items={offers}
                    paginationData={paginationData}
                    getPaginated={getPaginatedOffers}
                  />
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey="sent" title={`${t("Sent")} (${countOffersByStatus("open")})`}>
            {loading ? (
              <CustomSpinner/>
            ) : (
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="pt-0 pb-5">
                  <Table hover className="user-table align-items-center">
                    <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">{t("Offer Date")}</th>
                      <th className="border-bottom">{t("Offer Number")}</th>
                      <th className="border-bottom">{t("customer name")}</th>
                      <th className="border-bottom">{t("status")}</th>
                      <th className="border-bottom">{t("price")}</th>
                      <th className="border-bottom">{t("action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers.filter(offer => offer.status === "open").map((offer, index) => (
                      <OfferTable
                        key={index}
                        index={index}
                        {...offer}
                        setMessage={setMessage}
                        setPaginationData={setPaginationData}
                        setLoading={setLoading}
                      />
                    ))}
                    </tbody>
                  </Table>
                  <OldPagination
                    action={OffersActions}
                    items={offers}
                    paginationData={paginationData}
                    getPaginated={getPaginatedOffers}
                  />
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey="agree" title={`${t("Agree")} (${countOffersByStatus("accepted")})`}>
            {loading ? (
              <CustomSpinner/>
            ) : (
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="pt-0 pb-5">
                  <Table hover className="user-table align-items-center">
                    <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">{t("Offer Date")}</th>
                      <th className="border-bottom">{t("Offer Number")}</th>
                      <th className="border-bottom">{t("customer name")}</th>
                      <th className="border-bottom">{t("status")}</th>
                      <th className="border-bottom">{t("price")}</th>
                      <th className="border-bottom">{t("action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers.filter(offer => offer.status === "accepted").map((offer, index) => (
                      <OfferTable
                        key={index}
                        index={index}
                        {...offer}
                        setMessage={setMessage}
                        setPaginationData={setPaginationData}
                        setLoading={setLoading}
                      />
                    ))}
                    </tbody>
                  </Table>
                  <OldPagination
                    action={OffersActions}
                    items={offers}
                    paginationData={paginationData}
                    getPaginated={getPaginatedOffers}
                  />
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey="pending" title={`${t("Pending")} (${countOffersByStatus("pending")})`}>
            {loading ? (
              <CustomSpinner/>
            ) : (
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="pt-0 pb-5">
                  <Table hover className="user-table align-items-center">
                    <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">{t("Offer Date")}</th>
                      <th className="border-bottom">{t("Offer Number")}</th>
                      <th className="border-bottom">{t("customer name")}</th>
                      <th className="border-bottom">{t("status")}</th>
                      <th className="border-bottom">{t("price")}</th>
                      <th className="border-bottom">{t("action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers.filter(offer => offer.status === "pending").map((offer, index) => (
                      <OfferTable
                        key={index}
                        index={index}
                        {...offer}
                        setMessage={setMessage}
                        setPaginationData={setPaginationData}
                        setLoading={setLoading}
                      />
                    ))}
                    </tbody>
                  </Table>
                  <OldPagination
                    action={OffersActions}
                    items={offers}
                    paginationData={paginationData}
                    getPaginated={getPaginatedOffers}
                  />
                </Card.Body>
              </Card>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
