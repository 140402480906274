import {
  GET_RESELLER_USER_BY_ID_FAILED,
  GET_RESELLER_USER_BY_ID_START,
  GET_RESELLER_USER_BY_ID_SUCCESS,

} from "../actions/actionTypes";

const initialState = {
  reseller: {},
  success: false,
  isLoading: false,
  error: "",
};

const reseller = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESELLER_USER_BY_ID_START:
      return {
        ...initialState,
        isLoading: true,
      };

    case GET_RESELLER_USER_BY_ID_SUCCESS:
      return {
        ...initialState,
        reseller: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_RESELLER_USER_BY_ID_FAILED:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reseller;
  