import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {faMoneyCheck, faSearch, faSignOutAlt, faUserShield,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container, Dropdown, Nav, Navbar} from "@themesberg/react-bootstrap";
import React, {useEffect} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Select, {components} from "react-select";
import netherlandflag from "../../../assets/img/flags/netherlandsflag.svg";
import unitedkingdomflag from "../../../assets/img/flags/united_kingdom.svg";
import Auth from "../../../auth/libs/Auth";
import {config} from "../../../libs/config";
import {selectUser, selectUserType} from "../../../store/reducers/userCompany";

export default ({search, setSearch}) => {
  const userType = useSelector(selectUserType);
  const user = useSelector(selectUser);
  const lang = localStorage.getItem("i18nextLng");
  const {t} = useTranslation();

  const logout = () => {
    Auth.logout()
      .then((response) => {
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  const options = [
    {
      label: "nl",
      lng: "NL",
      value: lang === "nl",
      image: netherlandflag,
    },
    {
      label: "en",
      lng: "EN",
      value: lang === "en",
      image: unitedkingdomflag,
    },
  ];

  const {SingleValue, Option} = components;

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <img src={props.data.image} className="flag-image" alt="flag"/>
      {props.data.lng}
    </SingleValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      <img src={props.data.image} className="flag-image" alt="flag"/>
      {props.data.lng}
    </Option>
  );
  // setting up default lang to local storage
  useEffect(() => {
    if (!localStorage.getItem("i18nextLng")) {
      localStorage.setItem("i18nextLng", "en");
    }
  }, []);
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}/>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder={t("Search")}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
            <Select
              value={
                options &&
                options.filter((value) => {
                  return value.label === lang;
                })
              }
              onChange={(e) => {
                localStorage.setItem("i18nextLng", e.label);
                window.location.reload();
              }}
              components={{SingleValue: IconSingleValue, Option: IconOption}}
              defaultValue="en"
              options={options}
              className="lang-btn"
            />
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* <Image src={Profile3} className="user-avatar md-avatar rounded-circle" /> */}
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="text-dark user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {user && user.last_name}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item
                  href={
                    userType === "reseller" ? "/reseller/profile" : "/profile"
                  }
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faUserCircle} className="me-2"/>
                  {t("My Profile")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={
                    userType === "reseller"
                      ? "/reseller/subscription-details"
                      : "/company-subscription"
                  }
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faMoneyCheck} className="me-2"/>
                  {t("Subscription")}
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold"
                  href={config.support_url}
                >
                  <FontAwesomeIcon icon={faUserShield} className="me-2"/>
                  {t("Support")}
                </Dropdown.Item>

                <Dropdown.Divider/>

                <Dropdown.Item className="fw-bold" onClick={(e) => logout()}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />
                  {t("Logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
