import {Field, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {firstInvoiceStep2Schema} from "../../validation";
import AutoCompleteCompanyField from "../../components/inputs/AutoCompleteCompanyField";

function CreateFirstInvoiceStep2({setStep, handleCallbackStepTwo}) {
  const {t} = useTranslation();

  let persistedData = localStorage.getItem("FormData")
    ? JSON.parse(localStorage.getItem("FormData"))
    : {};

  const saveStepTwoData = (values) => {
    let formData = {
      customerCompany: values.customerCompany,
      customerNumber: values.customerNumber,
      customerStreet: values.customerStreet,
      customerHouseNumber: values.customerHouseNumber,
      customerSuffix: values.customerSuffix,
      customerPostcode: values.customerPostcode,
      customerCity: values.customerCity,
      customerCountry: values.customerCountry,
      customerVAT: values.customerVAT,
    };
    handleCallbackStepTwo(formData);
    localStorage.setItem(
      "FormData",
      JSON.stringify({...persistedData, ...formData})
    );
    setStep(3);
  };

  return (
    <div className="col-md-12 card o-hidden border-0 shadow-lg">
      <div className="card-body p-0">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="p-5">
              <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">
                  {t("The customer information for your first invoice")}
                </h1>
              </div>
              <Formik
                initialValues={{
                  customerCompany: persistedData.customerCompany,
                  customerVAT: persistedData.customerVAT,
                  customerStreet: persistedData.customerStreet,
                  customerHouseNumber: persistedData.customerHouseNumber,
                  customerPostcode: persistedData.customerPostcode,
                  customerCountry: persistedData.customerCountry || "NL",
                  customerCity: persistedData.customerCity,
                  customerNumber: persistedData.customerNumber,
                }}
                validationSchema={firstInvoiceStep2Schema}
                onSubmit={(values, {setSubmitting}) => {
                  saveStepTwoData(values);
                  setSubmitting(false)
                }}
              >
                {({
                    setFieldValue,
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    isValid
                  }) => (
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group row mb-sm-3">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label>{t("Customer Name")}</label>
                        <AutoCompleteCompanyField
                          value={values.customerCompany}
                          withData={true}
                          onChange={(company) => {
                            setFieldValue("customerCompany", company);

                            if (company.phone) {
                              setFieldValue("customerNumber", company.phone);
                            }

                            if (company.street) {
                              setFieldValue("customerStreet", company.street);
                            }

                            if (company.btwnumber) {
                              setFieldValue("customerVAT", company.btwnumber);
                            }

                            if (company.kvk_number) {
                              setFieldValue("customerCoC", company.kvk_number);
                            }

                            if (company.street_number) {
                              setFieldValue("customerHouseNumber", company.street_number);
                            }

                            if (company.street_number_suffix) {
                              setFieldValue("customerSuffix", company.street_number_suffix);
                            }

                            if (company.postcode) {
                              setFieldValue("customerPostcode", company.postcode);
                            }

                            if (company.city) {
                              setFieldValue("customerCity", company.city);
                            }
                          }}
                        />
                        {errors.customerCompany &&
                        touched.customerCompany ? (
                          <span className="error-message">
                            {t(errors.customerCompany)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-sm-6 ">
                        <label>{t("VAT Number")}</label>
                        <Field
                          name="customerVAT"
                          type="text"
                          value={values.customerVAT}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerVAT", e.target.value);
                          }}
                        />
                        {errors.customerVAT && touched.customerVAT ? (
                          <span className="error-message">
                            {t(errors.customerVAT)}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row mb-sm-3">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label>{t("Street")}</label>
                        <Field
                          type="text"
                          name="customerStreet"
                          value={values.customerStreet}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerStreet", e.target.value);
                          }}
                        />
                        {errors.customerStreet && touched.customerStreet ? (
                          <span className="error-message">
                            {t(errors.customerStreet)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-sm-3 mb-3 mb-sm-0">
                        <label>{t("House Number")}</label>
                        <Field
                          type="text"
                          name="customerHouseNumber"
                          value={values.customerHouseNumber}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerHouseNumber", e.target.value);
                          }}
                        />
                        {errors.customerHouseNumber &&
                        touched.customerHouseNumber ? (
                          <span className="error-message">
                            {t(errors.customerHouseNumber)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-sm-3 ">
                        <label>{t("Suffix")}</label>
                        <Field
                          name="customerSuffix"
                          type="text"
                          value={values.customerSuffix}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerSuffix", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-sm-3">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label>{t("Postcode")}</label>
                        <Field
                          type="text"
                          value={values.customerPostcode}
                          name="customerPostcode"
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerPostcode", e.target.value);
                          }}
                        />
                        {errors.customerPostcode &&
                        touched.customerPostcode ? (
                          <span className="error-message">
                            {t(errors.customerPostcode)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-sm-6 ">
                        <label>{t("City")}</label>
                        <Field
                          type="text"
                          name="customerCity"
                          value={values.customerCity}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerCity", e.target.value);
                          }}
                        />
                        {errors.customerCity && touched.customerCity ? (
                          <span className="error-message">
                            {t(errors.customerCity)}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row mb-sm-3">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label>{t("Country")}</label>
                        <select
                          className="form-control user-select"
                          value={values.customerCountry}
                          onChange={(e) => setFieldValue("customerCountry".e.target.value)}
                        >
                          <option value="NL">
                            {t("Netherlands")}
                          </option>
                        </select>
                      </div>
                      <div className="col-sm-6 ">
                        <label>{t("Phone Number")}</label>
                        <Field
                          type="text"
                          name="customerNumber"
                          value={values.customerNumber}
                          className="form-control form-control-user"
                          onChange={(e) => {
                            setFieldValue("customerNumber", e.target.value);
                          }}
                        />
                        {errors.customerNumber && touched.customerNumber ? (
                          <span className="error-message">
                            {t(errors.customerNumber)}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group justify-content-center row mb-sm-3 mt-3 mt-md-0">
                      <div className="col-sm-3">
                        <button
                          type="button"
                          onClick={() => setStep(1)}
                          className="btn btn-outline-primary btn-user btn-block p-1 py-2 previous-button"
                        >
                          {t("Previous")}
                        </button>
                      </div>
                      <div className="col-sm-3 ml-auto mt-2 mt-md-0">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary btn-user btn-block p-1 py-2 next-button"
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFirstInvoiceStep2;
